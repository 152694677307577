import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { BASE_RECIPE, EXTENDED_RECIPE } from '@shared/graphql-fragments';

export const CREATE_RECIPE: DocumentNode = gql`
  mutation CreateRecipe($input: CreateRecipeInput!) {
    createRecipe(input: $input) {
      id
    }
  }
`;

export const GET_RECIPE: DocumentNode = gql`
  ${EXTENDED_RECIPE}
  query GetRecipe($id: ID!) {
    recipe(id: $id) {
      ...ExtendedRecipe
    }
  }
`;

export const GET_RECIPES: DocumentNode = gql`
  ${BASE_RECIPE}
  query GetRecipes($options: RecipeListOptions) {
    recipes(options: $options) {
      items {
        ...BaseRecipe
      }
      totalItems
    }
  }
`;

export const UPDATE_RECIPE: DocumentNode = gql`
  ${EXTENDED_RECIPE}
  mutation UpdateRecipe($input: UpdateRecipeInput!) {
    updateRecipe(input: $input) {
      ...ExtendedRecipe
    }
  }
`;

export const DELETE_RECIPE: DocumentNode = gql`
  mutation DeleteRecipe($id: String!) {
    deleteRecipe(id: $id) {
      result
    }
  }
`;

export const ASSIGN_RECIPE_TO_CHANNEL: DocumentNode = gql`
  ${EXTENDED_RECIPE}
  mutation AssignRecipeToChannel($input: AssignRecipeToChannelInput!) {
    assignRecipeToChannel(input: $input) {
      ...ExtendedRecipe
    }
  }
`;

export const REMOVE_RECIPE_FROM_CHANNEL: DocumentNode = gql`
  ${EXTENDED_RECIPE}
  mutation RemoveRecipeFromChannel($input: RemoveRecipeFromChannelInput!) {
    removeRecipeFromChannel(input: $input) {
      ...ExtendedRecipe
    }
  }
`;
