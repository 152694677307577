import { Logger } from '@services';

/**
 * Interface for route params
 */
export interface RouteParams {
  [key: string]: number | string;
}

/**
 * Basic options for all urls
 */
interface UrlOptions {
  /** Whether a slash should be added to the beginning of the url */
  leadingSlash?: boolean;
  /** Whether a slash should be added to the end of the url */
  trailingSlash?: boolean;
}

/**
 * Additional options for urls with param placeholders
 */
interface OptionsForUrlsWithParams<T extends RouteParams> {
  /** Params that will replace the param placeholder in url */
  params: T;
}

/**
 * All options for urls with param placeholders
 * @augments UrlOptions
 * @augments OptionsForUrlsWithParams
 */
interface UrlOptionsWithParams<T extends RouteParams> extends UrlOptions, OptionsForUrlsWithParams<T> {}

/**
 * Url for routing.
 * It is capable of automatically replacing param placeholders
 * (e.g. `:companyId` for required param or `?:companyName` for optional param) in the url with given values.
 */
export class RouteUrl<T extends RouteParams | null = null> {
  /** Segments of the url. Only available if url contains no parameters! */
  public readonly staticUrlSegments: (string | number)[] | null = null;
  /** Relative url as string. Only available if url contains no parameters! */
  public readonly staticUrlRelative: string | null = null;
  /** Absolute url as string. Only available if url contains no parameters! */
  public readonly staticUrlAbsolute: string | null = null;

  /** Segments of routing url */
  private readonly urlSegments: (string | number)[] = [];

  constructor(urlSegments: string | (string | number)[]) {
    if (Array.isArray(urlSegments)) {
      this.urlSegments = urlSegments;
    } else {
      this.urlSegments = urlSegments.split('/');
    }

    // If url contains variable parameters, do not create static versions of url
    if (this.urlSegments.some((item) => `${item}`.startsWith(':') || `${item}`.startsWith('?:'))) {
      return;
    }

    // Set static urls for easier usage in templates
    this.staticUrlSegments = this.urlSegments;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.staticUrlRelative = this.getJoinedUrl(...([{ leadingSlash: false, trailingSlash: false }] as any));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.staticUrlAbsolute = this.getJoinedUrl(...([{ leadingSlash: true, trailingSlash: false }] as any));
  }

  /**
   * Get n elements of url from the right
   * @param segments Number of segments
   * @returns Joined url elements
   */
  public getLastSegment(segments: number = 1): string {
    return this.urlSegments.slice(this.urlSegments.length - segments, this.urlSegments.length).join('/');
  }

  /**
   * Returns complete url, joined to string
   * @param options Options for the url
   * @param options.leadingSlash Whether a slash should be added to url at the beginning
   * @param options.trailingSlash Whether a slash should be added to url at the end
   * @param [options.params] Url params that should replace the param placeholders in the url. Only available if type T is defined!
   * @returns Url as string with replaced parameters
   */
  public getJoinedUrl(
    // Expects `params` value in options if `T` is not null and not undefined.
    // Otherwise, `options` is optional and includes all values but `params`.
    // Unfortunately there is currently no other, more pleasant possibility to define parameters as optional if generic type is not defined.
    ...options: T extends RouteParams ? [UrlOptionsWithParams<T>] : [UrlOptions?]
  ): string {
    // Get options from the arguments array
    const urlOptions: UrlOptionsWithParams<RouteParams> | UrlOptions | undefined = options?.[0];

    const defaultOptions: UrlOptions = {
      leadingSlash: false,
      trailingSlash: false,
    };

    // Add default options as fallback. `options` will overwrite the default options if set
    const mergedOptions: UrlOptionsWithParams<RouteParams> | UrlOptions = {
      ...defaultOptions,
      ...urlOptions,
    };

    return (
      (mergedOptions.leadingSlash ? '/' : '') +
      // Parameter has to be an array and must be casted to `any` to avoid typescript errors.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.getUrlSegments(...([mergedOptions] as any)).join('/') +
      (mergedOptions.trailingSlash ? '/' : '')
    );
  }

  /**
   * Returns url with replaced parameters
   * @param options Options for the url
   * @param [options.params] Url params that should replace the param placeholders in the url. Only available if type T is defined!
   * @returns Url in segments/array
   */
  public getUrlSegments(
    // Expects `params` value in options if `T` is not null and not undefined.
    // Otherwise no parameter is expected.
    // Unfortunately there is currently no other, more pleasant possibility to define parameters as optional if generic type is not defined.
    ...options: T extends RouteParams ? [OptionsForUrlsWithParams<T>] : []
  ): (string | number)[] {
    // Get params from the arguments array
    const params: RouteParams | undefined = options?.[0]?.params;

    return (
      this.urlSegments
        .map((segment: string | number) => {
          // Transforms to string
          const segmentAsString: string = `${segment}`;

          // If segment is placeholder for param
          if (segmentAsString.startsWith(':')) {
            // Get param name by removing ':'
            const param: string = segmentAsString.substring(1);

            // Check if param is included in `params`
            if (!params || params[param] === undefined || params[param] === null) {
              Logger.error(`RouteUrl: Missing param '${param}' in route: ${this.urlSegments.join('/')}`);
              Logger.error(`Given params: ${JSON.stringify(params)}`);
              return segment;
            }

            // Return the value that should replace the placeholder
            return params[param];
          }

          // If segment is placeholder for an OPTIONAL param
          if (segmentAsString.startsWith('?:')) {
            // Get param name by removing '?:'
            const param: string = segmentAsString.substring(2);
            // Check if param is included in `params`
            if (!params || params[param] === undefined || params[param] === null) {
              // Return null so that this optional url part can be filtered out afterwards
              return null;
            }

            // Return the value that should replace the placeholder
            return params[param];
          }

          // Return the url segment if no placeholder
          return segment;
        })

        // Filter out optional segments that should not be included because parameters were not specified
        .filter((segment) => segment !== null) as (string | number)[]
    );
  }
}
