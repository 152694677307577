import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '@vendure/admin-ui/core';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AUTOCOMPLETE_ADDRESS } from '@shared/components/address-select/address.graphql';
import { RecursivePartial } from '@shared/models/util';
import { Address, AddressAutoCompletion, AutoCompleteAddress } from '@shared/types/generated-ui-types';

interface IAutoCompletionResult extends AddressAutoCompletion {
  disabled?: boolean;
}

export interface IAddressChangedData {
  valid: boolean;
  isInArea?: boolean;
  address: RecursivePartial<Address>;
}

interface IFormValues {
  isExpertMode: boolean;
  isInArea: boolean;
  fullName: string;
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  province: string;
  countryCode: string;
  latitude: string;
  longitude: string;
  placeId: string;
}

@Component({
  selector: 'app-address-select',
  templateUrl: './address-select.component.html',
  styleUrls: ['./address-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSelectComponent implements OnInit, OnDestroy {
  private readonly destroyed: Subject<void> = new Subject<void>();

  @Input()
  public readonly: boolean = false;

  @Input()
  public allowSubmit: boolean;

  @Input()
  public allowCanceling: boolean;

  @Output()
  public addressChanged: EventEmitter<IAddressChangedData | null> = new EventEmitter<IAddressChangedData | null>();

  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onSave: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public ignoreIsInArea: boolean = true;

  public showExpertModeIndicator: boolean = false;

  public searchTerm$: Subject<string> = new Subject<string>();
  public searchTerm: string;
  public areAddressesLoading: boolean = false;
  public addressSuggestions$: Observable<IAutoCompletionResult[]>;

  public addressForm: FormGroup = new FormGroup({
    isInArea: new FormControl(false),
    isExpertMode: new FormControl(false),
    fullName: new FormControl(''),
    street: new FormControl('', (control) => Validators.required(control)),
    streetNumber: new FormControl('', (control) => Validators.required(control)),
    postalCode: new FormControl('', (control) => Validators.required(control)),
    city: new FormControl('', (control) => Validators.required(control)),
    province: new FormControl('', (control) => Validators.required(control)),
    countryCode: new FormControl('', (control) => Validators.required(control)),
    latitude: new FormControl('', (control) => Validators.required(control)),
    longitude: new FormControl('', (control) => Validators.required(control)),
    placeId: new FormControl(''),
  });

  constructor(private readonly dataService: DataService) {}

  public ngOnInit(): void {
    this.addressForm.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((changes: IFormValues) => {
      this.addressChanged.emit({
        valid: this.addressForm.valid,
        isInArea: this.ignoreIsInArea ? undefined : changes.isInArea,
        address: {
          fullName: changes.fullName,
          city: changes.city,
          country: {
            code: changes.countryCode,
          },
          postalCode: changes.postalCode,
          province: changes.province,
          streetLine1: `${changes.street} ${changes.streetNumber}`,
          customFields: {
            latitude: +changes.latitude,
            longitude: +changes.longitude,
            placeId: changes.placeId || null,
          },
        },
      });
    });

    this.addressSuggestions$ = this.searchTerm$.pipe(
      map((term) => (term || '').trim()),
      distinctUntilChanged(),
      // To keep the search term in the search after selecting an address,
      // we need to store the search term in a variable and set the search value when closing the select.
      tap(() => {
        this.areAddressesLoading = true;
      }),
      debounceTime(300),
      switchMap((term: string) => {
        if (term.length < 3) {
          this.areAddressesLoading = false;
          return of([]);
        }

        return this.dataService
          .query<AutoCompleteAddress.Query, AutoCompleteAddress.Variables>(AUTOCOMPLETE_ADDRESS, {
            input: term,
          })
          .mapSingle((data) => data.autoComplete as AddressAutoCompletion[])
          .pipe(
            map((addressAutocompletionResults) =>
              addressAutocompletionResults.map((autoCompletedAddress) => ({
                ...autoCompletedAddress,
                disabled: !this.ignoreIsInArea && !autoCompletedAddress.isInArea,
              }))
            ),
            tap(() => {
              this.areAddressesLoading = false;
            })
          );
      })
    );
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public handleChange(autoCompletionResult: IAutoCompletionResult): void {
    this.addressForm.patchValue({
      ...(autoCompletionResult.address as IFormValues),
      isInArea: autoCompletionResult.isInArea,
    });
    this.addressForm.markAsDirty();
  }

  public handleClickOnInput(): void {
    if ((this.addressForm.value as IFormValues).isExpertMode) {
      return;
    }
    this.showExpertModeIndicator = true;
  }

  public onSubmit(): void {
    this.onSave.emit();
  }

  public cancelEditingAddress(): void {
    this.addressForm.reset();
    this.addressChanged.emit(null);
    this.cancel.emit();
  }
}
