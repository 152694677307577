import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const GET_WALLET_DETAILS: DocumentNode = gql`
  query walletDetails($customerId: ID!) {
    walletByCustomerId(customerId: $customerId) {
      id
      customerId
      payoutTotal
      nonPayoutTotal
      walletItems {
        id
        createdAt
        total
        description
        internalComment
        activity
      }
    }
  }
`;

export const DEDUCT_WALLET: DocumentNode = gql`
  mutation deductWallet($input: DeductWalletInput!) {
    deductWallet(input: $input) {
      id
      customerId
      payoutTotal
      nonPayoutTotal
    }
  }
`;

export const UPDATE_WALLET_ITEM: DocumentNode = gql`
  mutation updateWalletItem($input: UpdateWalletItemInput!) {
    updateWalletItem(input: $input) {
      id
    }
  }
`;
