import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomFieldConfigType, CustomFieldControl } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IntCustomFieldConfig, Order } from '@shared/types/generated-ui-types';

@Component({
  selector: 'app-order-invoice-paid',
  templateUrl: './order-invoice-paid.component.html',
})
export class OrderInvoicePaidComponent implements CustomFieldControl, OnInit {
  public readonly: boolean;
  // Retrieving configs from backend
  public config: CustomFieldConfigType & IntCustomFieldConfig;
  public formControl: FormControl;

  public displayCheckbox: boolean = false;

  constructor(private readonly route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.route.data.pipe(switchMap((data) => data.entity as Observable<Order>)).subscribe((order: Order) => {
      this.displayCheckbox = (order?.payments && !!order?.payments.find((p) => p.method === 'invoice')) ?? false;
    });
  }
}
