import { NgModule } from '@angular/core';
import { AppComponent, AppComponentModule, CoreModule } from '@vendure/admin-ui/core';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';

import { Logger } from '@services';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app.routing';
import { PluginsModule } from './plugins/plugins.module';

@NgModule({
  declarations: [],
  imports: [AppComponentModule, SocialLoginModule, AppRoutingModule, CoreModule, PluginsModule],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleLoginClientId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    Logger.setEnableLogs(true);
  }
}
