<div class="card">
  <div class="card-img">
    <div class="featured-asset">
      <img
        *ngIf="featuredAsset; else assetPlaceholderTpl"
        [src]="$any(featuredAsset) | assetPreview: 'small'"
        (click)="previewAsset($any(featuredAsset))"
      />
      <ng-template #assetPlaceholderTpl>
        <div class="placeholder" (click)="selectAsset()">
          <clr-icon shape="image" size="128"></clr-icon>
          <div>{{ 'catalog.no-featured-asset' | translate }}</div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="card-footer flex flex-column">
    <ng-container *ngIf="featuredAsset; else noFeatureAssetTpl">
      <button class="btn" (click)="selectAsset()">
        <clr-icon shape="pencil"></clr-icon>
        {{ 'asset.select-asset' | translate }}
      </button>
      <button class="btn btn-outline-danger" (click)="removeAsset()">
        <clr-icon shape="trash"></clr-icon>
        {{ 'asset.remove-asset' | translate }}
      </button>
    </ng-container>
    <ng-template #noFeatureAssetTpl>
      <button class="btn" (click)="selectAsset()">
        <clr-icon shape="attachment"></clr-icon>
        {{ 'asset.add-asset' | translate }}
      </button>
    </ng-template>
  </div>
</div>
