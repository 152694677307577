import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VarRefDirective } from './varRef.directive';

const DIRECTIVES = [VarRefDirective];

@NgModule({
  imports: [CommonModule],
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES],
})
export class DirectivesModule {}
