<clr-accordion>
  <clr-accordion-panel>
    <clr-accordion-title>{{ 'invoicePlugin.settings' | translate }}</clr-accordion-title>
    <clr-accordion-content *clrIfExpanded>
      <section class="form-block">
        <form class="form" [formGroup]="form">
          <vdr-form-field [label]="'invoicePlugin.invoiceOn' | translate" for="enabled">
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox formControlName="enabled" />
            </clr-checkbox-wrapper>
          </vdr-form-field>
          <vdr-form-field [label]="'invoicePlugin.template' | translate" for="templateString">
            <textarea
              id="templateString"
              type="text"
              formControlName="templateString"
              style="width: 100%; height: 300px"
            ></textarea>
          </vdr-form-field>
          <button class="btn btn-primary" (click)="save()" [disabled]="form.invalid || form.pristine">
            {{ 'common.update' | translate }}
          </button>
          <button class="btn btn-secondary" (click)="testDownload()">{{ 'invoicePlugin.preview' | translate }}</button>
          <vdr-help-tooltip [content]="'invoicePlugin.previewInfo' | translate"></vdr-help-tooltip>
        </form>
      </section>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<hr />
<section>
  <h2>{{ 'invoicePlugin.invoices' | translate }}</h2>
  <div class="search-form mb4 mt4" style="width: 100%">
    <input
      type="text"
      name="searchTerm"
      [formControl]="searchControl"
      [placeholder]="'invoicePlugin.search-by-invoice-filters' | translate"
      class="search-input"
      style="width: 100%"
    />
  </div>

  <button class="btn btn-primary" (click)="downloadSelected()" [disabled]="selectedInvoices?.length === 0">
    {{ 'invoicePlugin.download' | translate }}
  </button>
  <vdr-data-table
    *varRef="items$ | async as invoices"
    [items]="invoices"
    [itemsPerPage]="itemsPerPage$ | async"
    [totalItems]="totalItems$ | async"
    [currentPage]="currentPage$ | async"
    [allSelected]="areAllSelected(invoices)"
    [isRowSelectedFn]="isSelected"
    (pageChange)="setPageNumber($event)"
    (itemsPerPageChange)="setItemsPerPage($event)"
    (rowSelectChange)="toggleSelect($event)"
    (allSelectChange)="toggleSelectAll(invoices)"
  >
    <vdr-dt-column>{{ 'invoicePlugin.invoiceNumber' | translate }}</vdr-dt-column>
    <vdr-dt-column>{{ 'invoicePlugin.createdAt' | translate }}</vdr-dt-column>
    <vdr-dt-column>{{ 'invoicePlugin.customer' | translate }}</vdr-dt-column>
    <vdr-dt-column>{{ 'invoicePlugin.order' | translate }}</vdr-dt-column>
    <vdr-dt-column>{{ 'invoicePlugin.download' | translate }}</vdr-dt-column>
    <ng-template let-invoice="item">
      <td class="left align-middle">{{ invoice.invoiceNumber }}</td>
      <td class="left align-middle">
        {{ invoice.createdAt | date }}
      </td>
      <td class="left align-middle">{{ invoice.customerEmail }}</td>
      <td class="left align-middle">
        <a [routerLink]="['/orders', invoice.orderId]">
          {{ invoice.orderCode }}
        </a>
      </td>
      <td class="left align-middle">
        <a [href]="invoice.downloadUrl" target="_blank">
          <clr-icon shape="download"></clr-icon>
        </a>
      </td>
    </ng-template>
  </vdr-data-table>
</section>
