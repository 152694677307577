import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ModalService } from '@vendure/admin-ui/core';
import { DEFAULT_CHANNEL_CODE } from '@vendure/common/lib/shared-constants';
import { ID } from '@vendure/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Channel } from '@shared/types/generated-ui-types';
import { ChannelDialogAssignmentComponent } from './channel-dialog-assignment/channel-dialog-assignment.component';

/**
 * Channel selection that displays all the given selectedChannels name as chips.
 * The already selected channels can be removed in the chip itself.
 * A new channel can be assigned via a new modal.
 */
@Component({
  selector: 'app-channel-select',
  templateUrl: './channel-select.component.html',
  styleUrls: ['./channel-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ChannelSelectComponent {
  @Input()
  public selectedChannels$: Observable<Channel[]>;

  @Output()
  private readonly onSelect: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  private readonly onRemove: EventEmitter<string> = new EventEmitter<string>();

  constructor(private readonly modalService: ModalService) {}

  public isDefaultChannel(channelCode: string): boolean {
    return channelCode === DEFAULT_CHANNEL_CODE;
  }

  /**
   * Emits the onRemove event if the dialog is confirmed to remove the channel
   * @param channelId Id of channel to remove
   */
  public removeFromChannel(channelId: ID): void {
    this.modalService
      .dialog({
        title: _('catalog.remove-from-channel'),
        buttons: [
          { type: 'secondary', label: _('common.cancel') },
          {
            type: 'danger',
            label: _('catalog.remove-from-channel'),
            returnValue: true,
          },
        ],
      })
      .subscribe((response) => {
        if (response) {
          this.onRemove.emit(channelId.toString());
        }
      });
  }

  /**
   * Opens a modal to assign the entity to a channel.
   * If a channel was selected in the modal the channel is emitted via onSelect.
   */
  public assignToChannel(): void {
    this.selectedChannels$
      .pipe(
        take(1),
        switchMap((channels) => {
          return this.modalService.fromComponent(ChannelDialogAssignmentComponent, {
            size: 'lg',
            locals: {
              currentChannelIds: channels?.map((c) => c.id.toString()),
            },
          });
        })
      )
      .subscribe((channelId) => {
        if (channelId) {
          this.onSelect.emit(channelId);
        }
      });
  }
}
