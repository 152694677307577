import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DataService, ModalService, NotificationService } from '@vendure/admin-ui/core';

import { RedeemGiftCoupon } from '@shared/types/generated-ui-types';
import { REDEEM_GIFT_COUPON } from '../../gift-coupon.graphql';

interface IFormValues {
  code: string;
  comment: string;
}

@Component({
  selector: 'app-redeem-gift-coupon',
  templateUrl: './redeem-gift-coupon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedeemGiftCouponComponent {
  public giftCouponForm: FormGroup = new FormGroup({
    code: new FormControl('', [(control) => Validators.required(control), Validators.min(1)]),
    comment: new FormControl('', (control) => Validators.required(control)),
  });

  constructor(
    private readonly dataService: DataService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly notificationService: NotificationService,
    private readonly modalService: ModalService
  ) {}

  public async redeemCode(): Promise<void> {
    if (this.giftCouponForm.invalid) {
      this.giftCouponForm.markAllAsTouched();
      return;
    }

    const { code, comment }: IFormValues = this.giftCouponForm.value as IFormValues;

    const confirmation: boolean =
      (await this.modalService
        .dialog({
          title: _('giftCouponPlugin.redeemGiftCouponCode'),
          body: _('giftCouponPlugin.redeemGiftCouponCodeQuestion'),
          translationVars: {
            code,
          },
          buttons: [
            { type: 'secondary', label: _('common.cancel') },
            { type: 'danger', label: _('giftCouponPlugin.redeem'), returnValue: true },
          ],
        })
        .toPromise()) ?? false;

    if (!confirmation) {
      return;
    }

    this.dataService
      .mutate<RedeemGiftCoupon.Mutation, RedeemGiftCoupon.Variables>(REDEEM_GIFT_COUPON, {
        input: {
          comment,
          couponNumber: +code,
        },
      })
      .subscribe(
        () => {
          this.notificationService.success('giftCouponPlugin.redeemGiftCoupon.success');
          this.giftCouponForm.reset();
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.notificationService.error('giftCouponPlugin.error.redemptionFailed');
        }
      );
  }
}
