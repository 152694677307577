<ng-container
  *varRef="
    ((isNew$ | async) && (createProducerPermissions | hasPermission)) ||
    (updateProducerPermissions | hasPermission) as hasEditPermissions
  "
>
  <form class="form" [formGroup]="detailForm">
    <vdr-action-bar>
      <vdr-ab-left>
        <div class="flex align-center">
          <vdr-entity-info class="mr3" [entity]="$any(entity$ | async)"></vdr-entity-info>
          <clr-toggle-wrapper *vdrIfPermissions="updateProducerPermissions">
            <input type="checkbox" clrToggle name="enabled" formControlName="enabled" />
            <label>{{ 'common.enabled' | translate }}</label>
          </clr-toggle-wrapper>
        </div>
      </vdr-ab-left>
      <vdr-ab-right>
        <button
          *ngIf="isNew$ | async; else updateButton"
          class="btn btn-primary"
          [disabled]="
            detailForm.invalid || detailForm.pristine || (!!newAddress && !newAddress?.valid) || !hasEditPermissions
          "
          (click)="create()"
        >
          {{ 'common.create' | translate }}
        </button>
        <ng-template #updateButton>
          <button
            *vdrIfPermissions="updateProducerPermissions"
            class="btn btn-primary"
            [disabled]="
              (detailForm.invalid || (detailForm.pristine && !newAddress) || (!!newAddress && !newAddress?.valid)) &&
              !assetsChanged()
            "
            (click)="save()"
          >
            {{ 'common.update' | translate }}
          </button>
        </ng-template>
      </vdr-ab-right>
    </vdr-action-bar>

    <div class="clr-row">
      <div class="clr-col">
        <!-- Channel selection -->
        <section class="form-block">
          <app-channel-select
            *ngIf="hasEditPermissions; else staticChannelTpl"
            [selectedChannels$]="selectedChannels$"
            (onSelect)="assignToChannel($event)"
            (onRemove)="removeFromChannel($event)"
          ></app-channel-select>

          <!-- Readonly channels if user has no edit permissions -->
          <ng-template #staticChannelTpl>
            <vdr-form-item [label]="'common.channels' | translate">
              <div class="flex channel-list">
                <ng-container *ngFor="let channel of selectedChannels$ | async; let last = last">
                  <ng-container *ngIf="channel.code !== DEFAULT_CHANNEL_CODE">
                    {{ channel.code | channelCodeToLabel }}
                  </ng-container>
                  <ng-container *ngIf="!last">, </ng-container>
                </ng-container>
              </div>
            </vdr-form-item>
          </ng-template>
        </section>

        <!-- Name -->
        <section class="form-block">
          <vdr-form-field for="name" [label]="'common.name' | translate">
            <input id="name" type="text" formControlName="name" [readonly]="!hasEditPermissions" />
          </vdr-form-field>
        </section>

        <label class="clr-control-label">{{ 'addressSelect.address' | translate }}</label>
        <div *varRef="address$ | async as address" class="card mt2 mb4">
          <div class="card-block">
            <app-address-display *ngIf="address" [address]="address"></app-address-display>
          </div>

          <div class="card-block">
            <button
              *ngIf="address && !editAddress && hasEditPermissions"
              class="btn btn-primary"
              (click)="editAddress = true"
            >
              {{ 'producerPlugin.address.edit' | translate }}
            </button>

            <app-address-select
              *ngIf="(!address || editAddress) && hasEditPermissions"
              class="mt2"
              [allowCanceling]="!!address"
              (addressChanged)="handleAddressChanged($event)"
              (cancel)="editAddress = false"
            ></app-address-select>
          </div>
        </div>

        <section class="form-block">
          <vdr-form-field
            for="name"
            [label]="'producerPlugin.hasDetailsPage.label' | translate"
            [tooltip]="'producerPlugin.hasDetailsPage.hint' | translate"
          >
            <clr-toggle-wrapper *vdrIfPermissions="updateProducerPermissions" class="has-details-toggle">
              <input type="checkbox" clrToggle name="hasDetailsPage" formControlName="hasDetailsPage" />
            </clr-toggle-wrapper>
          </vdr-form-field>
        </section>

        <!-- Description -->
        <section class="form-block">
          <vdr-rich-text-editor
            *ngIf="detailForm.value.hasDetailsPage; else noDescriptionAvailableTpl"
            formControlName="description"
            [label]="'common.description' | translate"
            [readonly]="!hasEditPermissions"
          ></vdr-rich-text-editor>
          <ng-template #noDescriptionAvailableTpl>
            <div class="no-description-available">
              {{
                'producerPlugin.noDescriptionAvailable'
                  | translate: { option: 'producerPlugin.hasDetailsPage.label' | translate }
              }}
            </div>
          </ng-template>
        </section>
      </div>

      <!-- Asset selection -->
      <div class="clr-col-md-auto">
        <vdr-assets
          *ngIf="detailForm.value.hasDetailsPage; else noAssetsAvailableTpl"
          [assets]="assetChanges.assets || $any(assets$ | async)"
          [featuredAsset]="assetChanges.featuredAsset || $any(featuredAsset$ | async)"
          [updatePermissions]="updateProducerPermissions"
          (change)="assetChanges = $event"
        >
        </vdr-assets>
        <ng-template #noAssetsAvailableTpl>
          <div class="no-assets-available">
            {{
              'producerPlugin.noAssetsAvailable'
                | translate: { option: 'producerPlugin.hasDetailsPage.label' | translate }
            }}
          </div>
        </ng-template>
      </div>
    </div>
  </form>
</ng-container>
