<ng-template vdrDialogTitle>{{ 'giftCouponPlugin.giftCouponCreated' | translate }}</ng-template>

<b>{{ 'giftCouponPlugin.copyCodeInstruction1' | translate }}</b>
<br />
{{ 'giftCouponPlugin.copyCodeInstruction2' | translate }}

<div class="mt3 input-container" (click)="copyGiftCouponCode()">
  <input #codeInput readonly class="input" [value]="giftCouponCode" (click)="codeInput.select()" />
  <div class="input-suffix">
    <clr-icon shape="copy-to-clipboard"></clr-icon>
  </div>

  <div *ngIf="copyingStatus" [ngSwitch]="copyingStatus" class="copy-status copy-status--{{ copyingStatus }}">
    <div *ngSwitchCase="'success'">
      <clr-icon shape="check"></clr-icon>
      {{ 'giftCouponPlugin.codeCopySuccess' | translate }}
    </div>
    <ng-container *ngSwitchCase="'error'">
      <clr-icon shape="times"></clr-icon>
      {{ 'giftCouponPlugin.codeCopyError' | translate }}
    </ng-container>
  </div>
</div>

<ng-template vdrDialogButtons>
  <button type="button" class="btn btn-primary" (click)="codeCopied ? confirm() : copyGiftCouponCode()">
    {{ (codeCopied ? 'common.close' : 'giftCouponPlugin.copyCode') | translate }}
  </button>
</ng-template>
