import { Injectable } from '@angular/core';
import { LocalStorageService } from '@vendure/admin-ui/core';

@Injectable({
  providedIn: 'root',
})
export class RestAPIService {
  constructor(private readonly localStorageService: LocalStorageService) {}

  public getHeaders(): Record<string, string> {
    const headers: Record<string, string> = {};
    const channelToken: string | null = this.localStorageService.get('activeChannelToken');
    if (channelToken) {
      headers['vendure-token'] = channelToken;
    }
    const authToken: string | null = this.localStorageService.get('authToken');
    if (authToken) {
      headers.authorization = `Bearer ${authToken}`;
    }
    return headers;
  }

  public downloadBlob(blob: Blob, fileName: string, openInNewTab: boolean = false): void {
    const blobUrl: string = window.URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('hidden', 'true');
    a.href = blobUrl;
    if (!openInNewTab) {
      a.download = fileName;
    }
    a.setAttribute('target', '_blank');
    a.click();
  }
}
