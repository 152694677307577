import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { loadAppConfig } from '@vendure/admin-ui/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

loadAppConfig()
  .then(async () => platformBrowserDynamic().bootstrapModule(AppModule))
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .catch((err: any) => {
    // eslint-disable-next-line no-console
    console.log(err);
  });
