<address *ngIf="address; else noAddressTpl" class="address">
  <ng-container *ngIf="address?.fullName">{{ address?.fullName }}<br /></ng-container>
  {{ address?.streetLine1 }}<br />
  {{ address?.postalCode }} {{ address?.city }}<br />
  {{ address?.province }}, {{ address?.country?.name }}
</address>

<ng-template #noAddressTpl>
  <div class="address address--no-data">
    {{ 'addressDisplay.noAddress' | translate }}
  </div>
</ng-template>
