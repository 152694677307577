import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DataService } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';

import { ShippingMethod, ShippingMethodsQuery } from '@shared/types/generated-ui-types';
import { GET_SHIPPING_METHODS } from '../../capacity.graphql';

@Component({
  selector: 'app-capacity-tabs',
  templateUrl: './capacity-tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CapacityTabsComponent implements OnInit {
  public shippingMethods$: Observable<ShippingMethod[]>;

  public firstShippingMethod: ShippingMethod;

  constructor(private readonly dataService: DataService) {}

  public ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.shippingMethods$ = this.dataService.query<ShippingMethodsQuery>(GET_SHIPPING_METHODS).mapSingle((data) => {
      this.firstShippingMethod = (data.shippingMethods.items as ShippingMethod[])?.[0];

      return data.shippingMethods.items as ShippingMethod[];
    });
  }
}
