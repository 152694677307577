import { NgModule } from '@angular/core';
import { SharedModule } from '@vendure/admin-ui/core';

import { TimeFrameModalContentComponent } from './time-frame-modal-content.component';

@NgModule({
  declarations: [TimeFrameModalContentComponent],
  imports: [SharedModule],
  exports: [TimeFrameModalContentComponent],
})
export class TimeFrameModalContentModule {}
