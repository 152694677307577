import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomFieldConfigType, CustomFieldControl, DataService } from '@vendure/admin-ui/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { GetProducers, Producer } from '@shared/types/generated-ui-types';
import { GET_PRODUCERS } from '../../../producer/producer.graphql';

@Component({
  selector: 'app-product-producer-relation',
  templateUrl: './product-producer-relation.component.html',
})
export class ProductProducerRelationComponent implements CustomFieldControl, OnInit {
  public readonly: boolean;
  // Retrieving configs from backend
  public config: CustomFieldConfigType;
  public formControl: FormControl;

  public producers$: Observable<Partial<Producer>[]>;

  public searchTerm$: Subject<string> = new Subject<string>();

  constructor(private readonly dataService: DataService) {}

  public ngOnInit(): void {
    this.producers$ = this.searchTerm$.pipe(
      debounceTime(200),
      switchMap((term: string) =>
        this.dataService
          .query<GetProducers.Query, GetProducers.Variables>(GET_PRODUCERS, {
            options: {
              take: 10,
              skip: 0,
              filter: term
                ? {
                    name: {
                      contains: term,
                    },
                  }
                : null,
            },
          })
          .mapSingle((data) => data.producers.items as Partial<Producer>[])
      )
    );
  }
}
