<ng-container
  *varRef="
    ((isNew$ | async) && (createRecipePermissions | hasPermission)) ||
    (updateRecipePermissions | hasPermission) as hasEditPermissions
  "
>
  <form class="form" [formGroup]="detailForm">
    <vdr-action-bar>
      <vdr-ab-left>
        <div class="flex align-center">
          <vdr-entity-info class="mr3" [entity]="$any(entity$ | async)"></vdr-entity-info>
          <clr-toggle-wrapper *vdrIfPermissions="updateRecipePermissions">
            <input type="checkbox" clrToggle name="enabled" formControlName="enabled" />
            <label>{{ 'common.enabled' | translate }}</label>
          </clr-toggle-wrapper>
        </div>
      </vdr-ab-left>
      <vdr-ab-right>
        <button
          *ngIf="isNew$ | async; else updateButton"
          class="btn btn-primary"
          [disabled]="detailForm.invalid || detailForm.pristine || !(createRecipePermissions | hasPermission)"
          (click)="create()"
        >
          {{ 'common.create' | translate }}
        </button>
        <ng-template #updateButton>
          <button
            *vdrIfPermissions="updateRecipePermissions"
            class="btn btn-primary"
            [disabled]="(detailForm.invalid || detailForm.pristine) && !assetsChanged() && !productVariantsChanged()"
            (click)="save()"
          >
            {{ 'common.update' | translate }}
          </button>
        </ng-template>
      </vdr-ab-right>
    </vdr-action-bar>

    <div class="clr-row">
      <div class="clr-col">
        <!-- Channel selection -->
        <section class="form-block">
          <app-channel-select
            *ngIf="hasEditPermissions; else staticChannelTpl"
            [selectedChannels$]="selectedChannels$"
            (onSelect)="assignToChannel($event)"
            (onRemove)="removeFromChannel($event)"
          ></app-channel-select>

          <!-- Readonly channels if user has no edit permissions -->
          <ng-template #staticChannelTpl>
            <vdr-form-item [label]="'common.channels' | translate">
              <div class="flex channel-list">
                <ng-container *ngFor="let channel of selectedChannels$ | async; let last = last">
                  <ng-container *ngIf="channel.code !== DEFAULT_CHANNEL_CODE">
                    {{ channel.code | channelCodeToLabel }}
                  </ng-container>
                  <ng-container *ngIf="!last">, </ng-container>
                </ng-container>
              </div>
            </vdr-form-item>
          </ng-template>
        </section>

        <!-- Name -->
        <section class="form-block">
          <vdr-form-field for="name" [label]="'common.name' | translate">
            <input id="name" type="text" formControlName="name" [readonly]="!hasEditPermissions" />
          </vdr-form-field>
        </section>

        <!-- Ingredients -->
        <section class="form-block">
          <vdr-rich-text-editor
            formControlName="ingredients"
            [label]="'recipePlugin.ingredients' | translate"
            [readonly]="!hasEditPermissions"
          ></vdr-rich-text-editor>
        </section>

        <!-- Description -->
        <section class="form-block">
          <vdr-rich-text-editor
            formControlName="description"
            [label]="'common.description' | translate"
            [readonly]="!hasEditPermissions"
          ></vdr-rich-text-editor>
        </section>

        <!-- Cook time -->
        <section class="form-block">
          <vdr-form-field
            for="cook-time"
            class="form-field--cook-time"
            [label]="'recipePlugin.cookTime' | translate"
            [errors]="{ min: 'recipePlugin.error.enterPositiveNumber' | translate }"
          >
            <vdr-affixed-input [suffix]="'recipePlugin.minutesShort' | translate">
              <input
                id="cook-time"
                class="form-input--cook-time"
                type="number"
                min="1"
                step="1"
                formControlName="cookTime"
                [readonly]="!hasEditPermissions"
              />
            </vdr-affixed-input>
          </vdr-form-field>
        </section>

        <!-- Cook level -->
        <section class="form-block">
          <vdr-form-field for="cook-level" [label]="'recipePlugin.cookLevel.label' | translate">
            <select id="cook-level" formControlName="cookLevel">
              <option *ngFor="let cookLevel of cookLevels" [value]="cookLevel">
                {{ 'recipePlugin.cookLevel.' + cookLevel | translate }}
              </option>
            </select>
          </vdr-form-field>
        </section>
      </div>

      <!-- Asset selection -->
      <div class="clr-col-md-auto">
        <vdr-assets
          [assets]="assetChanges.assets || $any(assets$ | async)"
          [featuredAsset]="assetChanges.featuredAsset || $any(featuredAsset$ | async)"
          [updatePermissions]="updateRecipePermissions"
          (change)="assetChanges = $event"
        >
        </vdr-assets>
      </div>

      <!-- Linked product variants section -->
      <div class="clr-col-12">
        <app-product-variant-select
          [selectedProductVariants]="linkedProductVariants$ | async"
          (selectedProductVariantsChange)="handleSelectedProductVariantChanged($event)"
        ></app-product-variant-select>
      </div>
    </div>
  </form>
</ng-container>
