import { NgModule } from '@angular/core';
import { registerFormInputComponent, SharedModule } from '@vendure/admin-ui/core';

import { KsSharedModule } from '@shared/shared.module';
import { DeliveryDateCardComponent } from './components/delivery-date-card/delivery-date-card.component';
import { ShippingDeliveryAreaSelectionCardComponent } from './components/shipping-delivery-area-selection/shipping-delivery-area-selection.component';

@NgModule({
  imports: [KsSharedModule, SharedModule],
  declarations: [DeliveryDateCardComponent, ShippingDeliveryAreaSelectionCardComponent],
  providers: [
    registerFormInputComponent('app-delivery-date-card', DeliveryDateCardComponent),
    registerFormInputComponent('app-shipping-delivery-area-selection', ShippingDeliveryAreaSelectionCardComponent),
  ],
})
export class ShippingMethodExtensionModule {}
