import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const LOGIN_WITH_GOOGLE: DocumentNode = gql`
  mutation Authenticate($token: String!) {
    authenticate(input: { google: { token: $token } }) {
      ... on CurrentUser {
        id
        identifier
      }
    }
  }
`;
