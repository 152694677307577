/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DataService } from '@vendure/admin-ui/core';
import { Facet, FacetValue, ID } from '@vendure/core';

export interface FacetValueSeletorItem {
  name: string;
  facetName: string;
  id: string;
  value: FacetValue;
}

/**
 * @description
 * Copy of vdr-facet-value-selector and use for facet-value-filter of collections
 */
@Component({
  selector: 'app-facet-value-selector',
  templateUrl: './facet-value-selector.component.html',
  styleUrls: ['./facet-value-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomFacetValueSelectorComponent,
      multi: true,
    },
  ],
})
export class CustomFacetValueSelectorComponent implements OnChanges, ControlValueAccessor {
  @Output() public selectedValuesChange: EventEmitter<FacetValue[]> = new EventEmitter<FacetValue[]>();
  @Input() public facets: Facet[];
  @Input() public readonly: boolean = false;
  @Input() public transformControlValueAccessorValue: (value: FacetValueSeletorItem[]) => any[] = (value) => value;

  @ViewChild(NgSelectComponent) private readonly ngSelect: NgSelectComponent;

  public facetValues: FacetValueSeletorItem[] = [];
  public onChangeFn: (val: any) => void;
  public onTouchFn: () => void;
  public disabled: boolean = false;
  public value: (ID | FacetValue)[];

  constructor(private readonly dataService: DataService) {}

  public ngOnChanges(): void {
    if (this.facets) {
      this.facetValues = this.facets
        .reduce((flattened, facet) => flattened.concat(facet.values), [] as FacetValue[])
        .map(this.toSelectorItem);
    }
  }

  public onChange(selected: FacetValueSeletorItem[]): void {
    if (this.readonly) {
      return;
    }
    this.selectedValuesChange.emit(selected.map((s) => s.value));
    if (this.onChangeFn) {
      const transformedValue = this.transformControlValueAccessorValue(selected);
      this.onChangeFn(transformedValue);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public focus(): void {
    this.ngSelect.focus();
  }

  public writeValue(obj: string | FacetValue[] | ID[] | null): void {
    if (typeof obj === 'string') {
      const facetIds: string[] = JSON.parse(obj);
      this.value = facetIds;
    } else if (Array.isArray(obj)) {
      const isIdArray = (input: unknown[]): input is ID[] =>
        input.every((i) => typeof i === 'number' || typeof i === 'string');
      if (isIdArray(obj)) {
        this.value = obj.map((fv) => fv.toString());
      } else {
        this.value = obj.map((fv) => fv.id);
      }
    }
  }

  private readonly toSelectorItem = (facetValue: FacetValue): FacetValueSeletorItem => {
    return {
      name: facetValue.name,
      facetName: facetValue.facet.name,
      id: facetValue.id as string,
      value: facetValue,
    };
  };
}
