/**
 * The rich text editor returns `<p></p>` if empty. This makes it hard to check, if real content is present.
 * To fix this, this function checks, if the content contains more than the p-element and whitespace.
 * If not, null is returned, to make empty checks easy.
 * The rich text editor returns `<p></p>` when it is empty. This makes it hard to check if there is "real" content.
 * To make this easier, this function checks if the content contains more than the p element and any whitespace.
 * If this is not the case, null is returned.
 * @param content Content that should be trimmed
 */
export function trimRichTextEditorContent(content: string | undefined): string | null {
  if (typeof content !== 'string') {
    return null;
  }
  // If the content is only a <p> element with whitespace/new lines, return null instead of an empty <p> element
  if (content.trim().match(/^<p>[\s|\n]*<\/p>$/gm)) {
    return null;
  }
  return content;
}
