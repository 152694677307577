<ng-template *ngIf="dialogTitle" vdrDialogTitle>{{ dialogTitle | translate }}</ng-template>

<div *ngIf="dialogInfo">{{ dialogInfo | translate }}</div>

<br />

<div>
  <form [formGroup]="timeFrameForm">
    <section class="form-block">
      <vdr-form-field for="from" [label]="'timeFrameModal.from' | translate">
        <vdr-datetime-picker formControlName="from"></vdr-datetime-picker>
      </vdr-form-field>
    </section>

    <section class="form-block">
      <vdr-form-field for="to" [label]="'timeFrameModal.to' | translate">
        <vdr-datetime-picker formControlName="to"></vdr-datetime-picker>
      </vdr-form-field>
    </section>

    <section *ngIf="selectionOptions && selectionOptions?.length" class="form-block">
      <vdr-form-field for="selectedOption" [label]="'timeFrameModal.selectedOption' | translate">
        <clr-select-container>
          <select clrSelect formControlName="selectedOption">
            <option *ngFor="let option of selectionOptions" [value]="option.id">
              {{ option.name }}
            </option>
          </select>
        </clr-select-container>
      </vdr-form-field>
    </section>
  </form>
</div>

<ng-template vdrDialogButtons>
  <button type="button" class="btn" (click)="cancel()">
    {{ 'common.cancel' | translate }}
  </button>
  <button type="button" class="btn btn-primary" [disabled]="timeFrameForm.invalid" (click)="export()">
    {{ 'timeFrameModal.export' | translate }}
  </button>
</ng-template>
