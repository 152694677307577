import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Permission } from '@shared/types/generated-ui-types';

@Component({
  selector: 'app-gift-coupon',
  templateUrl: './gift-coupon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftCouponComponent {
  public readonly createPermissions: Permission[] = [Permission.CreateGiftCoupon];
  public readonly updatePermissions: Permission[] = [Permission.UpdateGiftCoupon];
}
