<form *ngIf="form" [formGroup]="form">
  <div class="mt4" style="display: inline-flex">
    <div>
      <div *ngFor="let hour of matrixData.hours" class="header-item">
        <span [ngClass]="{ hide: !hour }"> {{ hour }} </span>
      </div>
    </div>

    <div>
      <div *ngFor="let day of matrixData.days" style="display: inline-flex" class="header-item">
        <span>
          {{ 'capacityPlugin.days.' + day | translate }}
        </span>
      </div>

      <div *ngFor="let item of matrixData.capacityData">
        <div *ngFor="let child of item" class="table-item">
          <input
            class="table-item__input"
            [ngClass]="{
              'table-item__input--disabled': form.controls[child].value === '-',
              'table-item__input--invalid':
                form.controls[child].invalid && form.controls[child].touched && form.controls[child].dirty,
              'table-item__input--updated':
                form.controls[child].valid && form.controls[child].touched && form.controls[child].dirty
            }"
            type="number"
            [formControlName]="child"
          />
        </div>
      </div>
    </div>
  </div>
</form>

<button class="btn btn-primary mt4" [disabled]="form.invalid || isLoading" (click)="save()">
  {{ 'capacityPlugin.save' | translate }}
</button>
