import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseEntityResolver } from '@vendure/admin-ui/core';

import { GetProducer } from '@shared/types/generated-ui-types';
import { ProducerService } from './producer.service';

@Injectable()
export class ProducerDetailResolver extends BaseEntityResolver<GetProducer.Producer> {
  constructor(protected override router: Router, private readonly producerService: ProducerService) {
    super(
      router,
      {
        id: '',
        status: '',
        name: '',
        description: '',
      },
      (id) => this.producerService.getProducerById(id)
    );
  }
}
