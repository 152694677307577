import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SELECTION_MODEL_FACTORY } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataService, SearchProducts, SingleSearchSelectionModelFactory } from '@vendure/admin-ui/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-product-search-input',
  templateUrl: './product-search-input.component.html',
  styleUrls: ['./product-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: SELECTION_MODEL_FACTORY,
      useValue: SingleSearchSelectionModelFactory,
    },
  ],
})
export class ProductSearchInputComponent implements OnInit {
  @Input()
  public excludedProductIds: string[];
  @Input()
  public excludedProductVariantIds: string[];

  @Output()
  public selectChange: EventEmitter<SearchProducts.Items> = new EventEmitter<SearchProducts.Items>();

  public readonly searchTerm$: Subject<string> = new Subject<string>();
  public foundProducts$: Observable<SearchProducts.Items[]>;
  public areProductsLoading: boolean = false;

  constructor(private readonly dataService: DataService) {}

  public ngOnInit(): void {
    this.foundProducts$ = this.searchTerm$.pipe(
      map((term) => (term || '').trim()),
      distinctUntilChanged(),
      tap(() => (this.areProductsLoading = true)),
      debounceTime(200),
      switchMap((term) => {
        if (term.length > 0) {
          return this.dataService.product
            .searchProducts(term, 50)
            .mapSingle((data) => data.search.items)
            .pipe(
              catchError(() => of([])),
              tap(() => (this.areProductsLoading = false))
            );
        } else {
          this.areProductsLoading = false;
          return of([]);
        }
      }),
      map((items) =>
        items.filter(
          (item) =>
            !(this.excludedProductIds || []).includes(item.productId) &&
            !(this.excludedProductVariantIds || []).includes(item.productVariantId) &&
            item.enabled
        )
      ),
      untilDestroyed(this)
    );
  }

  public onSelectChange(item: SearchProducts.Items): void {
    if (!item) {
      return;
    }
    this.selectChange.emit(item);
  }
}
