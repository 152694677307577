<div class="card">
  <div class="card-header">
    <div class="title">{{ heading | translate }}</div>
  </div>

  <div class="card-block">
    {{ info | translate }}
    <br />
    <br />
    <button class="btn btn-primary" (click)="export()">
      {{ buttonLabel | translate }}
    </button>
  </div>
</div>
