import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const CREATE_DELIVERY_AREA: DocumentNode = gql`
  mutation CreateDeliveryArea($input: DeliveryAreaCreateInput!) {
    createDeliveryArea(input: $input) {
      id
    }
  }
`;

export const GET_DELIVERY_AREA: DocumentNode = gql`
  query GetDeliveryArea($id: ID!) {
    deliveryArea(id: $id) {
      id
      name
    }
  }
`;

export const GET_DELIVERY_AREAS: DocumentNode = gql`
  query GetDeliveryAreas($options: DeliveryAreaListOptions) {
    deliveryAreas(options: $options) {
      items {
        id
        name
        postalCodes
      }
      totalItems
    }
  }
`;

export const GET_AVAILABLE_DELIVERY_AREAS: DocumentNode = gql`
  query GetAvailableDeliveryAreas {
    availableDeliveryAreas {
      items {
        id
        name
        postalCodes
      }
      totalItems
    }
  }
`;

export const UPDATE_DELIVERY_AREA: DocumentNode = gql`
  mutation UpdateDeliveryArea($input: DeliveryAreaUpdateInput!) {
    updateDeliveryArea(input: $input) {
      id
    }
  }
`;

export const DELETE_DELIVERY_AREA: DocumentNode = gql`
  mutation DeleteDeliveryArea($id: ID!) {
    deleteDeliveryArea(id: $id) {
      result
    }
  }
`;
