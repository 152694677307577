<ng-select
  [items]="facetValues"
  [addTag]="false"
  [hideSelected]="true"
  bindValue="id"
  multiple="true"
  appendTo="body"
  bindLabel="name"
  [disabled]="disabled || readonly"
  [ngModel]="value"
  (change)="onChange($event)"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <vdr-facet-value-chip
      *ngIf="item.value; else facetNotFound"
      [facetValue]="item.value"
      [removable]="!readonly"
      (remove)="clear(item)"
    ></vdr-facet-value-chip>
    <ng-template #facetNotFound>
      <vdr-chip colorType="error" icon="times" (iconClick)="clear(item)">{{
        'catalog.facet-value-not-available' | translate: { id: item.id }
      }}</vdr-chip>
    </ng-template>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <vdr-facet-value-chip [facetValue]="item.value" [removable]="false"></vdr-facet-value-chip>
  </ng-template>
</ng-select>
