import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CustomDetailComponent, DataService, NotificationService } from '@vendure/admin-ui/core';
import { DEFAULT_CHANNEL_CODE } from '@vendure/common/lib/shared-constants';
import { Observable } from 'rxjs';

import {
  Channel,
  CreateDeliveryArea,
  DeliveryArea,
  GetDeliveryAreasQuery,
  GetDeliveryAreasQueryVariables,
  Permission,
} from '@shared/types/generated-ui-types';
import { CREATE_DELIVERY_AREA, GET_DELIVERY_AREAS } from '../../delivery-area.graphql';

@Component({
  selector: 'app-delivery-area-list',
  styleUrls: ['./delivery-area-list.component.scss'],
  templateUrl: './delivery-area-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryAreaListComponent implements CustomDetailComponent, OnInit {
  // These two properties are provided by Vendure and will vary
  // depending on the particular detail page you are embedding this
  // component into.
  public entity$: Observable<Channel>;
  public detailForm: FormGroup;

  public deliveryAreas$: Observable<DeliveryArea[]>;

  public formControl: FormControl = new FormControl('', [(control) => Validators.required(control)]);

  public readonly updatePermissions: Permission[] = [Permission.UpdateChannel];

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dataService: DataService,
    private readonly notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.catchData();
  }

  public createDeliveryArea(channelId: string): void {
    if (this.formControl.invalid) {
      return;
    }

    this.dataService
      .mutate<CreateDeliveryArea.Mutation, CreateDeliveryArea.Variables>(CREATE_DELIVERY_AREA, {
        input: { name: String(this.formControl.value), channelId },
      })
      .subscribe(
        () => {
          this.notificationService.success(_('common.notify-create-success'), {
            entity: _('deliveryAreaPlugin.deliveryArea.1'),
          });
          this.formControl.patchValue('');
          this.formControl.markAsUntouched();

          this.catchData();
        },
        () => {
          this.notificationService.error(_('common.notify-create-error'), {
            entity: _('deliveryAreaPlugin.deliveryArea.1'),
          });
        }
      );
  }

  public catchData(): void {
    this.entity$.subscribe((channel) => {
      this.deliveryAreas$ = this.dataService
        .query<GetDeliveryAreasQuery, GetDeliveryAreasQueryVariables>(GET_DELIVERY_AREAS, {
          options: { filter: { channelId: { eq: channel.id } } },
        })
        .mapSingle((data) => data.deliveryAreas.items as DeliveryArea[]);
      this.changeDetector.markForCheck();
    });
  }

  public isDefaultChannel(channelCode?: string): boolean {
    if (!channelCode) {
      return true;
    }
    return channelCode === DEFAULT_CHANNEL_CODE;
  }
}
