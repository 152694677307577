<div class="card delivery-aera-item__container">
  <div class="card-block">
    <h3 class="delivery-aera-item__name">{{ deliveryArea?.name }}</h3>

    <div *vdrIfPermissions="updatePermissions" class="delivery-aera-item__create">
      <div>
        <input
          [placeholder]="'channelPlugin.deliveryArea.postalCode.enterPostalCode' | translate"
          [formControl]="formControl"
        />
        <div [class.clr-error]="formControl.touched && formControl.invalid">
          <span class="clr-subtext">{{ 'channelPlugin.deliveryArea.postalCode.enterPostalCodeInfo' | translate }}</span>
        </div>
      </div>
      <button class="btn btn-primary-outline delivery-aera-item__button" (click)="addPostalCode()">
        {{ 'channelPlugin.deliveryArea.postalCode.createPostalCode' | translate }}
      </button>
    </div>

    <div class="delivery-aera-item__postal-code-container">
      <span *ngFor="let code of deliveryArea.postalCodes" class="delivery-aera-item__postal-code">
        <button
          *vdrIfPermissions="updatePermissions"
          class="btn btn-sm btn-icon btn-danger-outline delivery-aera-item__postal-code-delete"
          (click)="removePostalCode(code)"
        >
          <clr-icon shape="trash"> </clr-icon>
        </button>
        {{ code }}
      </span>
    </div>

    <button
      *vdrIfPermissions="updatePermissions"
      class="btn btn-danger delivery-aera-item__delete"
      (click)="deleteDeliveryArea()"
    >
      {{ 'channelPlugin.deliveryArea.delete' | translate }}
    </button>
  </div>
</div>
