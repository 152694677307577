import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService, Dialog, GetChannels } from '@vendure/admin-ui/core';

/**
 * Modal to display a list of all channels and select one to assign to an entity.
 * Resolves with the selected channel
 */
@Component({
  selector: 'app-channel-dialog-assignment',
  templateUrl: './channel-dialog-assignment.component.html',
  styleUrls: ['./channel-dialog-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelDialogAssignmentComponent implements OnInit, Dialog<string> {
  public selectedChannel: GetChannels.Channels | null | undefined;
  public availableChannels: GetChannels.Channels[];

  public resolveWith: (result?: string) => void;

  public selectedChannelIdControl: FormControl = new FormControl();

  // Assigned by ModalService.fromComponent() call
  public currentChannelIds: string[];

  constructor(private readonly dataService: DataService) {}

  public ngOnInit(): void {
    this.dataService.settings
      .getChannels()
      .mapSingle((data) => data.channels)
      .subscribe((channels) => {
        this.availableChannels = channels;
      });

    this.selectedChannelIdControl.valueChanges.subscribe((ids) => {
      this.selectChannel(ids);
    });
  }

  public assign(): void {
    this.resolveWith(this.selectedChannel?.id);
  }

  public cancel(): void {
    this.resolveWith();
  }

  private selectChannel(channelIds: string[]): void {
    this.selectedChannel = this.availableChannels.find((c) => c.id === channelIds[0]);
  }
}
