import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const GET_ORDERS_BY_DATE: DocumentNode = gql`
  query OrdersByDate($input: OrderByDateInput!) {
    ordersByDate(input: $input) {
      items {
        id
        code
        state
        total
        totalWithTax
        currencyCode
        shippingAddress {
          streetLine1
          postalCode
          city
        }
        customer {
          id
          firstName
          lastName
        }
        customFields {
          earliestDeliveryTime
          latestDeliveryTime
          canDropOrder
          doNotRing
        }
      }
      totalItems
    }
  }
`;
