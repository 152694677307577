<vdr-form-item *vdrIfDefaultChannelActive [label]="'common.channels' | translate">
  <div class="flex channel-select__container">
    <!-- Channel tags -->
    <ng-container *ngFor="let channel of selectedChannels$ | async">
      <vdr-chip *ngIf="!isDefaultChannel(channel.code)" icon="times-circle" (iconClick)="removeFromChannel(channel.id)">
        <vdr-channel-badge [channelCode]="channel.code"></vdr-channel-badge>
        {{ channel.code | channelCodeToLabel }}
      </vdr-chip>
    </ng-container>

    <!-- Assign new channel -->
    <button class="btn btn-sm" (click)="assignToChannel()">
      <clr-icon shape="layers"></clr-icon>
      {{ 'catalog.assign-to-channel' | translate }}
    </button>
  </div>
</vdr-form-item>
