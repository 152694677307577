import { NgModule } from '@angular/core';
import { registerFormInputComponent, SharedModule } from '@vendure/admin-ui/core';

import { ProductOrderScoreComponent } from './components/product-order-score/product-order-score.component';
import { ProductProducerRelationComponent } from './components/product-producer-relation/product-producer-relation.component';
import { ProductVariantDiscountComponent } from './components/product-variant-discount/product-variant-discount.component';

@NgModule({
  imports: [SharedModule],
  declarations: [ProductOrderScoreComponent, ProductProducerRelationComponent, ProductVariantDiscountComponent],
  providers: [
    registerFormInputComponent('app-product-order-score', ProductOrderScoreComponent),
    registerFormInputComponent('app-product-producer-relation', ProductProducerRelationComponent),
    registerFormInputComponent('app-product-variant-discount', ProductVariantDiscountComponent),
  ],
})
export class ProductExtensionModule {}
