<ng-container
  *varRef="
    ((isNew$ | async) && (createSpecialPermissions | hasPermission)) ||
    (updateSpecialPermissions | hasPermission) as hasEditPermissions
  "
>
  <form class="form" [formGroup]="detailForm">
    <vdr-action-bar>
      <vdr-ab-left>
        <div class="flex align-center">
          <vdr-entity-info class="mr3" [entity]="$any(entity$ | async)"></vdr-entity-info>
          <clr-toggle-wrapper *vdrIfPermissions="updateSpecialPermissions">
            <input type="checkbox" clrToggle name="enabled" formControlName="enabled" />
            <label>{{ 'common.enabled' | translate }}</label>
          </clr-toggle-wrapper>
        </div>
      </vdr-ab-left>
      <vdr-ab-right>
        <button
          *ngIf="isNew$ | async; else updateButton"
          class="btn btn-primary"
          [disabled]="detailForm.invalid || detailForm.pristine || !(createSpecialPermissions | hasPermission)"
          (click)="create()"
        >
          {{ 'common.create' | translate }}
        </button>
        <ng-template #updateButton>
          <button
            *vdrIfPermissions="updateSpecialPermissions"
            class="btn btn-primary"
            [disabled]="(detailForm.invalid || detailForm.pristine) && !assetChanged() && !productVariantsChanged()"
            (click)="save()"
          >
            {{ 'common.update' | translate }}
          </button>
        </ng-template>
      </vdr-ab-right>
    </vdr-action-bar>

    <div class="clr-row">
      <div class="clr-col">
        <!-- Channel selection -->
        <section class="form-block">
          <app-channel-select
            *ngIf="hasEditPermissions; else staticChannelTpl"
            [selectedChannels$]="selectedChannels$"
            (onSelect)="assignToChannel($event)"
            (onRemove)="removeFromChannel($event)"
          ></app-channel-select>

          <!-- Readonly channels if user has no edit permissions -->
          <ng-template #staticChannelTpl>
            <vdr-form-item [label]="'common.channels' | translate">
              <div class="flex channel-list">
                <ng-container *ngFor="let channel of selectedChannels$ | async; let last = last">
                  <ng-container *ngIf="channel.code !== DEFAULT_CHANNEL_CODE">
                    {{ channel.code | channelCodeToLabel }}
                  </ng-container>
                  <ng-container *ngIf="!last">, </ng-container>
                </ng-container>
              </div>
            </vdr-form-item>
          </ng-template>
        </section>

        <!-- Name -->
        <section class="form-block">
          <vdr-form-field for="title" [label]="'specialPlugin.title' | translate">
            <input id="title" type="text" formControlName="title" [readonly]="!hasEditPermissions" />
          </vdr-form-field>
        </section>

        <!-- Category -->
        <section class="form-block">
          <vdr-form-field
            for="category"
            [label]="'specialPlugin.category.label' | translate"
            [tooltip]="'specialPlugin.category.tooltip' | translate"
          >
            <input id="category" type="text" formControlName="category" [readonly]="!hasEditPermissions" />
          </vdr-form-field>
        </section>

        <!-- Description -->
        <section class="form-block">
          <vdr-rich-text-editor
            formControlName="description"
            [label]="'common.description' | translate"
            [readonly]="!hasEditPermissions"
          ></vdr-rich-text-editor>
        </section>

        <section class="form-block">
          <vdr-form-field for="title" [label]="'specialPlugin.sortOrder' | translate">
            <ng-container *ngIf="hasEditPermissions">
              <input
                class="sort-order-value-slider"
                type="range"
                formControlName="sortOrder"
                min="1"
                max="200"
                step="1"
                [value]="detailForm.value.sortOrder"
              />
              <input
                class="sort-order-value-input"
                type="number"
                formControlName="sortOrder"
                min="1"
                max="200"
                step="1"
                [value]="detailForm.value.sortOrder"
              />
            </ng-container>
            <span *ngIf="!hasEditPermissions" class="sort-order-value">{{ detailForm.value.sortOrder ?? 'N/A' }}</span>
          </vdr-form-field>
        </section>

        <!-- Link -->
        <section class="form-block">
          <vdr-form-field for="link" [label]="'specialPlugin.link' | translate">
            <div class="link-container">
              <clr-toggle-wrapper *ngIf="hasEditPermissions">
                <input type="checkbox" clrToggle name="linkLeadsToSpecial" formControlName="linkLeadsToSpecial" />
                <label>{{ 'specialPlugin.linkLeadsToSpecial' | translate }}</label>
              </clr-toggle-wrapper>
              <ng-container *ngIf="!detailForm.value.linkLeadsToSpecial">
                <input
                  id="link"
                  class="mt3"
                  type="text"
                  formControlName="link"
                  [placeholder]="
                    detailForm.value.isLinkExternal
                      ? 'https://www.kleinstark.de'
                      : '/producer/4825e1ec-4c0d-4f00-9f6e-d65d7b96bcb8'
                  "
                  [readonly]="!hasEditPermissions"
                />
                <clr-toggle-wrapper *ngIf="hasEditPermissions">
                  <input type="checkbox" clrToggle name="isLinkExternal" formControlName="isLinkExternal" />
                  <label>{{ 'specialPlugin.isLinkExternal' | translate }}</label>
                </clr-toggle-wrapper>
              </ng-container>
            </div>
          </vdr-form-field>
        </section>
      </div>

      <!-- Asset selection -->
      <div class="clr-col-md-auto">
        <app-feature-asset
          [featuredAsset]="assetChanges?.featureAsset || $any(featuredAsset$ | async)"
          (featuredAssetChange)="handleFeatureAssetChanged($any($event))"
        ></app-feature-asset>
      </div>

      <!-- Linked product variants section (only available if no custom link is set) -->
      <div *ngIf="detailForm.value.linkLeadsToSpecial" class="clr-col-12">
        <app-product-variant-select
          [selectedProductVariants]="linkedProductVariants$ | async"
          (selectedProductVariantsChange)="handleSelectedProductVariantChanged($event)"
        ></app-product-variant-select>
      </div>
    </div>
  </form>
</ng-container>
