import { NgModule } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NavMenuItem, SharedModule } from '@vendure/admin-ui/core';

import { PAGE_URLS } from '@navUrls';
import { Permission } from '@shared/types/generated-ui-types';
import { CapacityRoutingModule } from './capacity.routing';
import { CapacityMatrixComponent } from './components/capacity-matrix/capacity-matrix.component';
import { CapacityTabsComponent } from './components/capacity-tabs/capacity-tabs.component';

export const CAPACITY_NAV_ITEM: NavMenuItem = {
  id: 'capacity',
  label: _('capacityPlugin.navItem'),
  routerLink: PAGE_URLS.capacity.getUrlSegments(),
  icon: 'table',
  requiresPermission: Permission.AllowDeliveryDateCapacity,
};

@NgModule({
  imports: [CapacityRoutingModule, SharedModule],
  declarations: [CapacityMatrixComponent, CapacityTabsComponent],
})
export class CapacityModule {}
