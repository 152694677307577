<div class="card">
  <div class="card-header">
    <div class="title">{{ 'giftCouponPlugin.createGiftCouponCode' | translate }}</div>
  </div>
  <div class="card-block">
    <form [formGroup]="giftCouponForm" (ngSubmit)="createGiftCoupon()">
      <section class="form-block">
        <vdr-form-field for="initialValue" [label]="'giftCouponPlugin.value' | translate">
          <div style="display: flex; flex-direction: column">
            <vdr-currency-input formControlName="initialValue" [currencyCode]="currencyCode"></vdr-currency-input>
            <div
              *ngIf="giftCouponForm.controls.initialValue.touched && giftCouponForm.controls.initialValue.errors?.min"
              class="clr-error"
            >
              <div class="clr-subtext">
                {{ 'giftCouponPlugin.error.minimumValue' | translate: { amount: getMinimumValue() } }}
              </div>
            </div>
          </div>
        </vdr-form-field>
      </section>

      <section class="form-block">
        <vdr-form-field for="comment" [label]="'giftCouponPlugin.comment' | translate">
          <input id="comment" type="text" formControlName="comment" />
        </vdr-form-field>
      </section>

      <button class="btn btn-primary">{{ 'giftCouponPlugin.createGiftCoupon' | translate }}</button>
    </form>
  </div>
</div>
