import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CustomFieldConfigType, CustomFieldControl, DataService, NotificationService } from '@vendure/admin-ui/core';
import { LanguageCode } from '@vendure/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { CREATE_ADDRESS, UPDATE_ADDRESS } from '@shared/components/address-select/address.graphql';
import { IAddressChangedData } from '@shared/components/address-select/address-select.component';
import { RecursivePartial } from '@shared/models/util';
import {
  Address,
  Channel,
  CreateAddressInput,
  CreateAddressMutation,
  CreateAddressMutationVariables,
  Permission,
  UpdateAddressInput,
  UpdateAddressMutation,
  UpdateAddressMutationVariables,
} from '@shared/types/generated-ui-types';

interface Params {
  id: string;
}

@Component({
  selector: 'app-channel-address',
  templateUrl: './channel-address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelAddressComponent implements CustomFieldControl, OnInit {
  public readonly: boolean;
  public formControl: FormControl;
  public config: CustomFieldConfigType;

  public isNew$: Observable<boolean>;
  public channelId$: Observable<string>;

  public updatePermission: Permission[] = [Permission.UpdateChannel];
  public editAddress: boolean = false;

  public address: Address;
  public newAddress: IAddressChangedData | null = null;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetector: ChangeDetectorRef, //
    private readonly dataService: DataService,
    private readonly notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.channelId$ = this.activatedRoute.params.pipe(map((params) => (params as Params)?.id));
    this.isNew$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value as Channel),
      map((channel: Channel) => !channel?.id)
    );

    this.address = this.formControl.value as Address;
  }

  public handleAddressChanged(addressChangedData: IAddressChangedData | null): void {
    this.newAddress = addressChangedData;
  }

  public saveAddress(): void {
    if (!this.newAddress?.address) {
      return;
    }

    const createValues: RecursivePartial<Address> = { ...this.newAddress?.address };
    delete createValues.country;
    if (this.address) {
      this.dataService
        .mutate<UpdateAddressMutation, UpdateAddressMutationVariables>(UPDATE_ADDRESS, {
          input: {
            ...createValues,
            id: this.address.id,
            countryCode: this.newAddress?.address.country?.code as LanguageCode,
          } as UpdateAddressInput,
        })
        .subscribe((result) => {
          if (result.updateAddress.id) {
            this.address = this.newAddress?.address as Address;
            this.editAddress = false;
            this.changeDetector.markForCheck();
            this.notificationService.success('common.notify-update-success', {
              entity: _('channelPlugin.address'),
            });
          } else {
            this.notificationService.error('common.notify-update-error', {
              entity: _('channelPlugin.address'),
            });
          }
        });
    } else {
      this.dataService
        .mutate<CreateAddressMutation, CreateAddressMutationVariables>(CREATE_ADDRESS, {
          input: {
            ...createValues,
            countryCode: this.newAddress?.address.country?.code as LanguageCode,
          } as CreateAddressInput,
        })
        .subscribe((result) => {
          this.formControl.setValue({ id: result.createAddress.id });
          this.formControl.markAsDirty();
          this.address = this.newAddress?.address as Address;
          this.editAddress = false;
          this.changeDetector.markForCheck();
        });
    }
  }
}
