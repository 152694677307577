/** Whether logs are enabled */
export let enableLogs: boolean = false;

/**
 * Logger that logs only in development builds
 */
export class Logger {
  // Noop function that replaces the console and alert functions if logs are disabled
  public static noopFn: () => null = () => null;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public static log: (...args: any[]) => void = Logger.noopFn;
  public static error: (...args: any[]) => void = Logger.noopFn;
  public static warn: (...args: any[]) => void = Logger.noopFn;
  public static alert: (...args: any[]) => void = Logger.noopFn;
  public static table: (...args: any[]) => void = Logger.noopFn;
  public static group: (...args: any[]) => void = Logger.noopFn;
  public static groupCollapsed: (...args: any[]) => void = Logger.noopFn;
  public static groupEnd: (...args: any[]) => void = Logger.noopFn;
  /* eslint-enable @typescript-eslint/no-explicit-any */

  /**
   * Set if logs are enabled
   * @param enable Whether logs are now enabled or disabled
   */
  public static setEnableLogs(enable: boolean): void {
    enableLogs = enable;

    if (enable) {
      /* eslint-disable no-console */
      Logger.log = console.log;
      Logger.error = console.error;
      Logger.warn = console.warn;
      Logger.alert = alert;
      Logger.table = console.table;
      Logger.group = console.group;
      Logger.groupCollapsed = console.groupCollapsed;
      Logger.groupEnd = console.groupEnd;
      /* eslint-enable no-console */
    } else {
      Logger.log = Logger.noopFn;
      Logger.error = Logger.noopFn;
      Logger.warn = Logger.noopFn;
      Logger.alert = Logger.noopFn;
      Logger.table = Logger.noopFn;
      Logger.group = Logger.noopFn;
      Logger.groupCollapsed = Logger.noopFn;
      Logger.groupEnd = Logger.noopFn;
    }
  }
}
