import { NgModule } from '@angular/core';
import { registerCustomDetailComponent, registerFormInputComponent, SharedModule } from '@vendure/admin-ui/core';

import { DirectivesModule } from '@shared/directives/directives.module';
import { KsSharedModule } from '@shared/shared.module';
import { ChannelAddressComponent } from './components/channel-address/channel-address.component';
import { DeliveryAreaItemComponent } from './components/delivery-area-item/delivery-area-item.component';
import { DeliveryAreaListComponent } from './components/delivery-area-list/delivery-area-list.component';

@NgModule({
  imports: [DirectivesModule, KsSharedModule, SharedModule],
  declarations: [ChannelAddressComponent, DeliveryAreaItemComponent, DeliveryAreaListComponent],
  providers: [
    registerFormInputComponent('app-channel-address', ChannelAddressComponent),
    registerCustomDetailComponent({ component: DeliveryAreaListComponent, locationId: 'channel-detail' }),
  ],
})
export class ChannelExtensionModule {}
