import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const BASE_ASSET: DocumentNode = gql`
  fragment BaseAsset on Asset {
    id
    source
    preview
  }
`;
