import { Component, Input } from '@angular/core';
import { getServerLocation, ModalService, NotificationService } from '@vendure/admin-ui/core';
import { DateTime } from 'luxon';

import { RestAPIService } from '@services';
import {
  TimeFrame,
  TimeFrameModalContentComponent,
} from '@shared/components/time-frame-modal-content/time-frame-modal-content.component';

export interface CsvExportSelectionType {
  name: string;
  id: string;
}

@Component({
  selector: 'app-csv-export',
  templateUrl: './csv-export.component.html',
})
export class CsvExportComponent<T extends CsvExportSelectionType> {
  @Input()
  public displayDialog: boolean = true;
  @Input()
  public displaySelection: boolean = false;
  @Input()
  public selectionOptions: T[] = [];
  @Input()
  public dialogHeader: string;
  @Input()
  public dialogInfo: string;
  @Input()
  public heading: string;
  @Input()
  public info: string;
  @Input()
  public errorMessage: string;
  @Input()
  public buttonLabel: string;
  @Input()
  public downloadUrlPart: string;
  @Input()
  public selectedOptionName: string;
  @Input()
  public fileName: string;

  private requestedDates: TimeFrame;

  private readonly serverPath: string;

  constructor(
    private readonly modalService: ModalService,
    private readonly notificationService: NotificationService,
    private readonly restAPIService: RestAPIService
  ) {
    this.serverPath = getServerLocation();
  }

  public export(): void {
    if (this.displayDialog) {
      this.modalService
        .fromComponent(TimeFrameModalContentComponent, {
          size: 'md',
          locals: {
            dialogTitle: this.dialogHeader,
            dialogInfo: this.dialogInfo,
            selectionOptions: this.selectionOptions,
          },
        })
        .subscribe((result) => {
          if (!result?.from ?? !result?.to) {
            return;
          }
          this.fetchCsv(result);
        });
    } else {
      this.fetchCsv();
    }
  }

  private fetchCsv(dates?: TimeFrame): void {
    let url: string = `${this.serverPath}/csvexport/${this.downloadUrlPart}`;

    if (dates?.from && dates?.to) {
      url += `?fromDate=${dates.from}&toDate=${dates.to}`;
      this.requestedDates = dates;
    }
    if (dates?.selectedOption && this.selectedOptionName) {
      url += `&${this.selectedOptionName}=${dates.selectedOption}`;
    }

    void fetch(url, {
      credentials: 'include',
      headers: this.restAPIService.getHeaders(),
    }).then((res) => {
      void this.handleResponse(res);
    });
  }

  private async handleResponse(res: Response): Promise<void> {
    if (!res.ok) {
      this.notificationService.error(this.errorMessage);
      return;
    }
    const blob: Blob = await res.blob();
    let downloadFileName: string = this.fileName;

    if (this.requestedDates) {
      downloadFileName += `_(von_${DateTime.fromISO(this.requestedDates.from).toFormat(
        'dd.LL.yyyy'
      )}_bis_${DateTime.fromISO(this.requestedDates.to).toFormat('dd.LL.yyyy')})`;
    }

    this.restAPIService.downloadBlob(blob, `${DateTime.now().toFormat('dd.LL.yyyy')}_${downloadFileName}.csv`);
  }
}
