<h5>{{ 'walletPlugin.deduct' | translate }}</h5>

<form [formGroup]="walletForm" (ngSubmit)="deductWallet()">
  <vdr-form-field for="total" [label]="'walletPlugin.amount' | translate">
    <vdr-currency-input formControlName="total" [currencyCode]="currencyCode"></vdr-currency-input>
  </vdr-form-field>
  <vdr-form-field for="description" [label]="'walletPlugin.description' | translate">
    <textarea formControlName="description"></textarea>
  </vdr-form-field>
  <vdr-form-field for="internalComment" [label]="'walletPlugin.internalComment' | translate">
    <textarea formControlName="internalComment"></textarea>
  </vdr-form-field>

  <button class="btn btn-primary" [disabled]="walletForm.invalid">
    {{ 'walletPlugin.deduct' | translate }}
  </button>
</form>
