<div>
  <vdr-currency-input
    [readonly]="readonly"
    [formControl]="formControl"
    [currencyCode]="currencyCode$ | async"
  ></vdr-currency-input>

  <vdr-help-tooltip
    class="product-variant-discount__description"
    [content]="'productExtensionsPlugin.discountDescription' | translate"
  ></vdr-help-tooltip>
</div>
<div class="product-variant-discount__discount">
  {{
    (discount ? 'productExtensionsPlugin.discountedBy' : 'productExtensionsPlugin.noDiscount')
      | translate: { discount: discount }
  }}
</div>

<div *ngIf="!readonly" class="product-variant-discount__spacing">
  <span class="clr-control-label">{{ 'productExtensionsPlugin.discountBy' | translate }}:</span>
  <div>
    <button *ngFor="let option of reductionOptions" class="btn btn-primary" (click)="setDiscount(option)">
      {{ option }}%
    </button>
  </div>

  <button class="btn btn-primary-outline product-variant-discount__spacing" (click)="setDiscount(0)">
    {{ 'productExtensionsPlugin.removeDiscount' | translate }}
  </button>
</div>
