import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseListComponent, DataService, getServerLocation, NotificationService } from '@vendure/admin-ui/core';
import { merge, Observable } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { RestAPIService } from '@services';
import {
  AllInvoices,
  Invoice,
  InvoiceConfigQuery,
  LogicalOperator,
  UpsertInvoiceConfigMutation,
  UpsertInvoiceConfigMutationVariables,
} from '@shared/types/generated-ui-types';
import { GET_ALL_INVOICES, GET_INVOICE_CONFIG, UPSERT_CONFIG_MUTATION } from '../invoice.graphql';

interface InvoiceSettingsForm {
  enabled: boolean;
  templateString: string;
}

@Component({
  selector: 'app-invoice-component',
  templateUrl: './invoice.component.html',
})
export class InvoiceComponent
  extends BaseListComponent<AllInvoices.Query, AllInvoices.Items, AllInvoices.Variables>
  implements OnInit
{
  public form: FormGroup = new FormGroup({
    enabled: new FormControl(true),
    templateString: new FormControl(''),
  });
  public selectedInvoices: Invoice[] = [];
  public searchControl: FormControl = new FormControl('');

  private readonly serverPath: string;

  constructor(
    protected override route: ActivatedRoute,
    protected override router: Router,
    private readonly dataService: DataService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly notificationService: NotificationService,
    private readonly restAPIService: RestAPIService
  ) {
    super(router, route);

    super.setQueryFn(
      (...[take, skip]: number[]) => {
        return this.dataService
          .query<AllInvoices.Query>(GET_ALL_INVOICES, { take: take ?? 10, skip: skip ?? 0 })
          .refetchOnChannelChange();
      },
      (data) => data.allInvoices,
      (skip, take) => {
        return {
          options: {
            skip,
            take,
            filter: {
              ...(this.searchControl.value
                ? {
                    orderCode: {
                      contains: this.searchControl.value as string,
                    },
                    customerEmail: {
                      contains: this.searchControl.value as string,
                    },
                  }
                : {}),

              ...(this.searchControl.value && !isNaN(+this.searchControl.value)
                ? {
                    invoiceNumber: {
                      eq: +this.searchControl.value,
                    },
                  }
                : {}),
            },
            filterOperator: LogicalOperator.Or,
          },
        };
      }
    );

    this.serverPath = getServerLocation();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    const searchTerms$: Observable<string> = merge(this.searchControl.valueChanges).pipe(
      filter((value: string) => 2 < value.length || value.length === 0),
      debounceTime(250)
    );

    merge(searchTerms$, this.route.queryParamMap)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.refresh();
      });

    this.dataService
      .query<InvoiceConfigQuery>(GET_INVOICE_CONFIG)
      .mapStream((d) => d.invoiceConfig)
      .subscribe((config) => {
        this.form.controls.enabled.setValue(config?.enabled);
        this.form.controls.templateString.setValue(config?.templateString);
      });
  }

  public isSelected: (row: Invoice) => boolean = (row: Invoice): boolean => {
    // eslint-disable-next-line no-invalid-this
    return !!this.selectedInvoices?.find((selected) => selected.id === row.id);
  };

  public toggleSelect(event: { item: Invoice }): void {
    if (this.isSelected(event.item)) {
      this.selectedInvoices = this.selectedInvoices.filter((s) => s.id !== event.item.id);
    } else {
      this.selectedInvoices.push(event.item);
    }
  }

  public toggleSelectAll(allItems: Invoice[]): void {
    if (this.areAllSelected(allItems)) {
      this.selectedInvoices = [];
    } else {
      this.selectedInvoices = allItems || [];
    }
  }

  public areAllSelected(allItems: Invoice[]): boolean {
    return this.selectedInvoices.length === allItems?.length;
  }

  public async testDownload(): Promise<void> {
    try {
      const template: string = (this.form.value as InvoiceSettingsForm).templateString;
      const res: Response = await fetch(`${this.serverPath}/invoices/preview`, {
        credentials: 'include',
        headers: {
          ...this.restAPIService.getHeaders(),
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ template }),
      });
      if (!res.ok) {
        const json: { message: string } = (await res.json()) as { message: string };
        throw Error(json?.message);
      }
      const blob: Blob = await res.blob();
      this.restAPIService.downloadBlob(blob, 'test-invoice.pdf', true);
    } catch (err) {
      this.notificationService.error((err as { message: string }).message);
    }
  }

  public save(): void {
    if (this.form.dirty) {
      const formValue: InvoiceSettingsForm = this.form.value as InvoiceSettingsForm;
      this.dataService
        .mutate<UpsertInvoiceConfigMutation, UpsertInvoiceConfigMutationVariables>(UPSERT_CONFIG_MUTATION, {
          input: {
            enabled: formValue.enabled,
            templateString: formValue.templateString,
          },
        })
        .subscribe(
          ({ upsertInvoiceConfig }) => {
            this.form.controls.enabled.setValue(upsertInvoiceConfig.enabled);
            this.form.controls.templateString.setValue(upsertInvoiceConfig.templateString);
          },
          () => {
            this.notificationService.error('common.notify-update-error', {
              entity: 'InvoiceConfig',
            });
          }
        );
    }
    this.form.markAsPristine();
    this.changeDetector.markForCheck();
    this.notificationService.success('common.notify-update-success', {
      entity: 'InvoiceConfig',
    });
  }

  public async downloadSelected(): Promise<void> {
    try {
      const nrs: string = this.selectedInvoices.map((i) => i.invoiceNumber).join(',');
      const res: Response = await fetch(`${this.serverPath}/invoices/download?nrs=${nrs}`, {
        credentials: 'include',
        headers: this.restAPIService.getHeaders(),
      });
      if (!res.ok) {
        const json: { message: string } = (await res.json()) as { message: string };
        throw Error(json?.message);
      }
      const blob: Blob = await res.blob();
      this.restAPIService.downloadBlob(blob, 'invoices.zip');
    } catch (err) {
      this.notificationService.error((err as { message: string }).message);
    }
  }
}
