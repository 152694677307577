import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DataService, NotificationService } from '@vendure/admin-ui/core';

import {
  CurrencyCode,
  UpdateWalletItemMutation,
  UpdateWalletItemMutationVariables,
  WalletItem,
} from '@shared/types/generated-ui-types';
import { WalletActivity } from '@shared/types/wallet';
import { UPDATE_WALLET_ITEM } from '../../wallet.graphql';

interface WalletItemForm {
  description?: string;
  comment?: string;
}

@Component({
  selector: 'app-customer-wallet-item',
  styleUrls: ['./customer-wallet-item.component.scss'],
  templateUrl: './customer-wallet-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerWalletItemComponent implements OnChanges {
  @Input()
  public walletItem: WalletItem;
  @Input()
  public currencyCode: CurrencyCode;

  public form: FormGroup = new FormGroup({
    description: new FormControl('', (control) => Validators.required(control)),
    comment: new FormControl(''),
  });

  // REFERENCES
  public WalletActivity: typeof WalletActivity = WalletActivity;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dataService: DataService,
    private readonly notificationService: NotificationService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.walletItem || !changes.walletItem) {
      return;
    }

    this.form.setValue({ description: this.walletItem.description, comment: this.walletItem.internalComment });
  }

  public updateWalletItem(): void {
    if (this.form.invalid) {
      return;
    }

    this.dataService
      .mutate<UpdateWalletItemMutation, UpdateWalletItemMutationVariables>(UPDATE_WALLET_ITEM, {
        input: {
          id: this.walletItem.id,
          internalComment: (this.form.value as WalletItemForm).comment,
          description: (this.form.value as WalletItemForm).description,
        },
      })
      .subscribe((result) => {
        if (result.updateWalletItem?.id) {
          this.form.markAsUntouched();
          this.form.markAsPristine();

          this.notificationService.success(_('common.notify-update-success'), { entity: _('walletPlugin.item') });

          this.changeDetectorRef.markForCheck();
        } else {
          this.notificationService.error(_('common.notify-update-error'), { entity: _('walletPlugin.item') });
        }
      });
  }
}
