import { NgModule } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NavMenuItem, SharedModule } from '@vendure/admin-ui/core';

import { PAGE_URLS } from '@navUrls';
import { DirectivesModule } from '@shared/directives/directives.module';
import { InvoiceComponent } from './components/invoice.component';
import { InvoiceRoutingModule } from './invoice.routing';

export const INVOICE_NAV_ITEM: NavMenuItem = {
  id: 'invoices',
  label: _('invoicePlugin.navItem'),
  routerLink: PAGE_URLS.invoices.getUrlSegments(),
  icon: 'file-group',
  requiresPermission: 'AllowInvoicesPermission',
};

@NgModule({
  imports: [SharedModule, InvoiceRoutingModule, DirectivesModule],
  providers: [],
  declarations: [InvoiceComponent],
})
export class InvoicesModule {}
