import { NgModule } from '@angular/core';
import { SharedModule } from '@vendure/admin-ui/core';

import { CustomerWalletTopUpComponent } from './customer-wallet-top-up.component';

const COMPONENTS = [CustomerWalletTopUpComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class CustomerWalletTopUptModule {}
