import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Address } from '@shared/types/generated-ui-types';

@Component({
  selector: 'app-address-display',
  templateUrl: './address-display.component.html',
  styleUrls: ['./address-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressDisplayComponent {
  @Input()
  public address: Address;
}
