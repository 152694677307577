import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataService, Dialog } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';

import {
  CurrencyCode,
  Wallet,
  WalletDetailsQuery,
  WalletDetailsQueryVariables,
} from '@shared/types/generated-ui-types';
import { GET_WALLET_DETAILS } from '../../wallet.graphql';

@Component({
  selector: 'app-customer-wallet-details',
  templateUrl: './customer-wallet-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerWalletDetailsComponent implements Dialog, OnInit {
  public customerId: string;
  public currencyCode: CurrencyCode;

  public resolveWith: () => void;

  public wallet$: Observable<Wallet>;

  public form: FormGroup = new FormGroup({ description: new FormControl(''), comment: new FormControl('') });

  constructor(private readonly dataService: DataService) {}

  public ngOnInit(): void {
    if (!this.customerId) {
      return;
    }
    this.wallet$ = this.dataService
      .query<WalletDetailsQuery, WalletDetailsQueryVariables>(GET_WALLET_DETAILS, { customerId: this.customerId })
      .mapSingle((result) => {
        return result.walletByCustomerId as Wallet;
      });
  }
}
