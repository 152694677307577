import { Injectable } from '@angular/core';
import { DataService } from '@vendure/admin-ui/core';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public readonly permissionsHaveChanged$: Observable<void>;
  private currentPermissions: string[];

  constructor(private readonly dataService: DataService) {
    this.permissionsHaveChanged$ = this.dataService.client
      .userStatus()
      .mapStream((data) => data.userStatus.permissions)
      .pipe(
        filter((permissions) => permissions?.length > 0),
        tap((permissions) => (this.currentPermissions = permissions)),
        switchMap(() => of(void 0))
      );
  }

  public checkPermissions(requiredPermissions: string[]): boolean {
    for (const perm of requiredPermissions) {
      if (this.currentPermissions.includes(perm)) {
        return true;
      }
    }
    return false;
  }
}
