import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent, DataService, ModalService, NotificationService } from '@vendure/admin-ui/core';
import { EMPTY } from 'rxjs';
import { debounceTime, delay, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { PAGE_URLS, SPECIAL_URLS } from '@navUrls';
import { Status } from '@shared/types/general.types';
import { DeleteSpecial, GetSpecials, Permission } from '@shared/types/generated-ui-types';
import { DELETE_SPECIAL, GET_SPECIALS } from '../../special.graphql';

interface IFormValues {
  searchTerm: string;
  status: string[];
}

@Component({
  selector: 'app-special-list',
  styleUrls: ['special-list.component.scss'],
  templateUrl: './special-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialListComponent
  extends BaseListComponent<GetSpecials.Query, GetSpecials.Items, GetSpecials.Variables>
  implements OnInit
{
  public statuses: { value: string; label: string }[] = [
    { value: Status.ACTIVE, label: '' },
    { value: Status.INACTIVE, label: '' },
  ];
  public filterFormGroup: FormGroup = new FormGroup({
    searchTerm: new FormControl(''),
    status: new FormControl([]),
  });

  public createSpecialPermissions: Permission[] = [Permission.CreateSpecial];
  public editSpecialPermissions: Permission[] = [Permission.UpdateSpecial];
  public deleteSpecialPermissions: Permission[] = [Permission.DeleteSpecial];

  // REFERENCES
  public PAGE_URLS: typeof PAGE_URLS = PAGE_URLS;
  public SPECIAL_URLS: typeof SPECIAL_URLS = SPECIAL_URLS;
  public Status: typeof Status = Status;

  constructor(
    protected override route: ActivatedRoute,
    protected override router: Router,
    private readonly dataService: DataService,
    private readonly modalService: ModalService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService
  ) {
    super(router, route);

    super.setQueryFn(
      (...[take, skip]: number[]) => {
        return this.dataService
          .query<GetSpecials.Query>(GET_SPECIALS, { take: take ?? 10, skip: skip ?? 0 })
          .refetchOnChannelChange();
      },
      (data) => data.specials,
      (skip, take) => {
        const formValues: IFormValues = this.filterFormGroup.value as IFormValues;

        return {
          options: {
            skip,
            take,
            filter: {
              ...(formValues?.status?.length > 0
                ? {
                    status: {
                      in: formValues?.status,
                    },
                  }
                : {}),
              ...(formValues?.searchTerm
                ? {
                    title: {
                      contains: formValues?.searchTerm || null,
                    },
                  }
                : {}),
            },
          },
        };
      }
    );
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.translateService.onLangChange.pipe(startWith('INIT'), takeUntil(this.destroy$)).subscribe(() => {
      this.statuses = this.statuses.map((status) => ({
        value: status.value,
        label: this.translateService.instant(
          'common.' + (status.value === Status.ACTIVE ? 'enabled' : 'disabled')
        ) as string,
      }));
    });

    this.filterFormGroup.valueChanges.pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe(() => this.refresh());
  }

  public delete(id: string): void {
    this.modalService
      .dialog({
        title: _('specialPlugin.confirmDelete'),
        buttons: [
          { type: 'secondary', label: _('common.cancel') },
          { type: 'danger', label: _('common.delete'), returnValue: true },
        ],
      })
      .pipe(
        switchMap((response) =>
          response
            ? this.dataService.mutate<DeleteSpecial.Mutation, DeleteSpecial.Variables>(DELETE_SPECIAL, {
                id,
              })
            : EMPTY
        ),
        // Short delay allowing the special to be removed from the search index before refreshing.
        delay(500)
      )
      .subscribe(
        () => {
          this.notificationService.success(_('common.notify-delete-success'), {
            entity: _('specialPlugin.special.1'),
          });
          this.refresh();
        },
        () => {
          this.notificationService.error(_('common.notify-delete-error'), {
            entity: _('specialPlugin.special.1'),
          });
        }
      );
  }
}
