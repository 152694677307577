import { RouteUrl } from './routeUrl';

export const PAGE_URLS = {
  products: new RouteUrl(['catalog', 'products']),
  productImporter: new RouteUrl(['extensions', 'productImporter']),
  facets: new RouteUrl(['catalog', 'facets']),
  collections: new RouteUrl(['catalog', 'collections']),
  assets: new RouteUrl(['catalog', 'assets']),
  recipes: new RouteUrl(['extensions', 'recipes']),
  producers: new RouteUrl(['extensions', 'producers']),

  orders: new RouteUrl(['orders']),
  giftCoupons: new RouteUrl(['extensions', 'gift-coupons']),
  invoices: new RouteUrl(['extensions', 'invoices']),
  exportCentral: new RouteUrl(['extensions', 'export-central']),

  customers: new RouteUrl(['customer', 'customers']),
  customerGroups: new RouteUrl(['customer', 'groups']),

  promotions: new RouteUrl(['marketing', 'promotions']),
  specials: new RouteUrl(['extensions', 'specials']),

  capacity: new RouteUrl(['extensions', 'capacity']),
};
