import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AssetChange } from '@vendure/admin-ui/catalog';
import { BaseDetailComponent, DataService, NotificationService, ServerConfigService } from '@vendure/admin-ui/core';
import { DEFAULT_CHANNEL_CODE } from '@vendure/common/lib/shared-constants';
import { Observable, of } from 'rxjs';
import { map, mapTo, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { PRODUCER_URLS } from '@navUrls';
import { CREATE_ADDRESS, UPDATE_ADDRESS } from '@shared/components/address-select/address.graphql';
import { IAddressChangedData } from '@shared/components/address-select/address-select.component';
import { RecursivePartial } from '@shared/models/util';
import { Status } from '@shared/types/general.types';
import {
  Address,
  Asset,
  AssignProducerToChannel,
  Channel,
  CreateAddress,
  CreateAddressInput,
  CreateProducer,
  CreateProducerInput,
  InputMaybe,
  Permission,
  Producer,
  RemoveProducerFromChannel,
  UpdateAddress,
  UpdateAddressInput,
  UpdateProducer,
  UpdateProducerInput,
} from '@shared/types/generated-ui-types';
import { trimRichTextEditorContent } from '@shared/utils';
import {
  ASSIGN_PRODUCER_TO_CHANNEL,
  CREATE_PRODUCER,
  REMOVE_PRODUCER_FROM_CHANNEL,
  UPDATE_PRODUCER,
} from '../../producer.graphql';

interface IFormValues {
  enabled: boolean;
  name: string;
  description: string;
  hasDetailsPage: boolean;
}

@Component({
  selector: 'app-producer-detail',
  styleUrls: ['./producer-detail.component.scss'],
  templateUrl: './producer-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProducerDetailComponent extends BaseDetailComponent<Producer> implements OnInit, OnDestroy {
  public selectedChannels$: Observable<Channel[] | undefined | null>;
  public assets$: Observable<Asset[] | undefined | null>;
  public featuredAsset$: Observable<Asset>;
  public assetChanges: Partial<AssetChange> = {};
  public address$: Observable<Address | undefined>;

  public editAddress: boolean = false;

  public newAddress: IAddressChangedData | null = null;
  private addressId: string;

  public detailForm: FormGroup = new FormGroup({
    enabled: new FormControl(false),
    name: new FormControl('', (control) => Validators.required(control)),
    description: new FormControl(''),
    hasDetailsPage: new FormControl(false),
  });

  public createProducerPermissions: Permission[] = [Permission.CreateProducer];
  public updateProducerPermissions: Permission[] = [Permission.UpdateProducer];

  public DEFAULT_CHANNEL_CODE: typeof DEFAULT_CHANNEL_CODE = DEFAULT_CHANNEL_CODE;

  constructor(
    protected override route: ActivatedRoute,
    protected override router: Router,
    protected override serverConfigService: ServerConfigService,
    protected override dataService: DataService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly notificationService: NotificationService
  ) {
    super(route, router, serverConfigService, dataService);
  }

  public ngOnInit(): void {
    this.init();

    this.selectedChannels$ = this.entity$?.pipe(map((producer) => producer.channels));

    this.assets$ = this.entity$?.pipe(map((producer) => producer.assets));

    this.featuredAsset$ = this.entity$?.pipe(map((producer) => producer.featuredAsset as Asset));

    this.address$ = this.entity$?.pipe(
      map((producer) => producer.address as Address),
      tap((address) => (this.addressId = address?.id))
    );

    this.isNew$.pipe(takeUntil(this.destroy$)).subscribe((isNew) => {
      const enabledFormField: AbstractControl = this.detailForm.controls.enabled;
      if (isNew) {
        enabledFormField.disable();
      } else {
        enabledFormField.enable();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy();
  }

  public assignToChannel(channelId: string): void {
    if (!channelId) {
      return;
    }

    this.dataService
      .mutate<AssignProducerToChannel.Mutation, AssignProducerToChannel.Variables>(ASSIGN_PRODUCER_TO_CHANNEL, {
        input: { producerId: this.id, channelId },
      })
      .subscribe(
        () => {
          this.notificationService.success('common.notify-update-success', {
            entity: _('producerPlugin.producer.1'),
          });
          this.detailForm.markAsPristine();
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.notificationService.error('common.notify-update-error', {
            entity: _('producerPlugin.producer.1'),
          });
        }
      );
  }

  public removeFromChannel(channelId: string): void {
    if (!channelId) {
      return;
    }

    this.dataService
      .mutate<RemoveProducerFromChannel.Mutation, RemoveProducerFromChannel.Variables>(REMOVE_PRODUCER_FROM_CHANNEL, {
        input: { producerId: this.id, channelId },
      })
      .subscribe(
        () => {
          this.notificationService.success('common.notify-update-success', {
            entity: _('producerPlugin.producer.1'),
          });
          this.detailForm.markAsPristine();
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.notificationService.error('common.notify-update-error', {
            entity: _('producerPlugin.producer.1'),
          });
        }
      );
  }

  public create(): void {
    if (!this.detailForm?.dirty) {
      return;
    }

    const formValue: IFormValues = this.detailForm.value as IFormValues;
    const producer: CreateProducerInput = {
      name: formValue.name,
      description: formValue.hasDetailsPage ? formValue.description : null,
      hasDetailsPage: formValue.hasDetailsPage,
    };

    if (this.assetsChanged()) {
      producer.assetIds = formValue.hasDetailsPage ? this.assetChanges.assets?.map((a) => a.id) : [];
      producer.featuredAssetId = formValue.hasDetailsPage ? (this.assetChanges.featuredAsset?.id as string) : null;
    }

    this.dataService
      .mutate<CreateProducer.Mutation, CreateProducer.Variables>(CREATE_PRODUCER, {
        input: producer,
      })
      .pipe(
        map((data) => data.createProducer.id),
        switchMap((producerId) => this.updateAddress(producerId).pipe(map(() => producerId)))
      )
      .subscribe(
        (producerId) => {
          this.notificationService.success('common.notify-create-success', {
            entity: _('producerPlugin.producer.1'),
          });
          this.assetChanges = {};
          this.newAddress = null;
          this.detailForm.markAsPristine();
          void this.router.navigate(
            PRODUCER_URLS.details.getUrlSegments({
              params: { id: producerId },
            })
          );

          this.editAddress = false;
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.notificationService.error('common.notify-create-error', {
            entity: _('producerPlugin.producer.1'),
          });
        }
      );
  }

  public save(): void {
    this.updateAddress(this.id)
      .pipe(switchMap(() => this.saveChanges()))
      .subscribe(
        (result) => {
          if (result) {
            this.assetChanges = {};
            this.newAddress = null;
            this.detailForm.markAsPristine();
            this.notificationService.success('common.notify-update-success', {
              entity: _('producerPlugin.producer.1'),
            });
          }

          this.editAddress = false;
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.notificationService.error('common.notify-update-error', {
            entity: _('producerPlugin.producer.1'),
          });
        }
      );
  }

  public override canDeactivate(): boolean {
    return (
      super.canDeactivate() &&
      (!this.assetChanges.assets || this.assetChanges?.assets?.length === 0) &&
      !this.assetChanges.featuredAsset
    );
  }

  public assetsChanged(): boolean {
    return !!Object.values(this.assetChanges).length;
  }

  private saveChanges(): Observable<boolean> {
    const input: UpdateProducerInput = { id: this.id };
    const formValue: IFormValues = this.detailForm.value as IFormValues;
    if (this.detailForm.dirty) {
      input.status = formValue.enabled ? Status.ACTIVE : Status.INACTIVE;
      input.name = formValue.name;
      input.description = formValue.hasDetailsPage ? trimRichTextEditorContent(formValue.description) : null;
      input.hasDetailsPage = formValue.hasDetailsPage;
    }

    if (this.assetsChanged()) {
      input.assetIds = formValue.hasDetailsPage
        ? (this.assetChanges.assets?.map((a) => a.id) as InputMaybe<string[]>)
        : [];
      input.featuredAssetId = formValue.hasDetailsPage
        ? (this.assetChanges.featuredAsset?.id as InputMaybe<string>)
        : null;
    }

    if (this.newAddress) {
      input.addressId = this.addressId;
    }

    if (Object.values(input).length > 1) {
      return this.dataService
        .mutate<UpdateProducer.Mutation, UpdateProducer.Variables>(UPDATE_PRODUCER, {
          input,
        })
        .pipe(mapTo(true));
    } else {
      return of(false);
    }
  }

  private updateAddress(producerId: string): Observable<void> {
    if (!this.newAddress?.valid) {
      return of(void 0);
    }

    const address: RecursivePartial<Address> = this.newAddress.address;

    const addressInput: CreateAddressInput | UpdateAddressInput = {
      fullName: address.fullName,
      city: address.city,
      company: (this.detailForm.value as IFormValues).name,
      countryCode: address.country?.code ?? '',
      customFields: {
        latitude: address.customFields?.latitude,
        longitude: address.customFields?.longitude,
        placeId: address.customFields?.placeId,
      },
      postalCode: address.postalCode,
      province: address.province,
      streetLine1: address.streetLine1 ?? '',
    };

    return this.address$.pipe(
      take(1),
      map((currentAddress) => currentAddress?.id ?? null),
      switchMap((addressId) => {
        if (addressId) {
          return this.dataService
            .mutate<UpdateAddress.Mutation, UpdateAddress.Variables>(UPDATE_ADDRESS, {
              input: {
                id: addressId,
                ...addressInput,
              },
            })
            .pipe(map(() => null));
        }
        return this.dataService
          .mutate<CreateAddress.Mutation, CreateAddress.Variables>(CREATE_ADDRESS, {
            input: {
              ...addressInput,
            },
          })
          .pipe(map((data) => data.createAddress.id));
      }),
      switchMap((addressId: string | null) => {
        if (addressId) {
          return this.dataService
            .mutate<UpdateProducer.Mutation, UpdateProducer.Variables>(UPDATE_PRODUCER, {
              input: {
                id: producerId,
                addressId,
              },
            })
            .pipe(map(() => void 0));
        }
        return of(void 0);
      })
    );
  }

  protected setFormValues(entity: Producer): void {
    this.detailForm.patchValue({
      enabled: entity.status === Status.ACTIVE || false,
      name: entity.name,
      description: entity.description,
      hasDetailsPage: entity.hasDetailsPage,
    });
  }

  public handleAddressChanged(addressChangedData: IAddressChangedData | null): void {
    this.newAddress = addressChangedData;
  }
}
