import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DataService, NotificationService } from '@vendure/admin-ui/core';

import {
  CurrencyCode,
  DeductWalletInput,
  DeductWalletMutation,
  DeductWalletMutationVariables,
  Wallet,
} from '@shared/types/generated-ui-types';
import { DEDUCT_WALLET } from '../../wallet.graphql';

@Component({
  selector: 'app-customer-wallet-deduct',
  templateUrl: './customer-wallet-deduct.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerWalletDeductComponent {
  @Input()
  public wallet: Wallet;
  @Input()
  public currencyCode: CurrencyCode;
  @Output()
  public onSuccess: EventEmitter<Wallet> = new EventEmitter<Wallet>();

  public walletForm: FormGroup = new FormGroup({
    total: new FormControl('', [(control) => Validators.required(control), Validators.min(1)]),
    description: new FormControl('', (control) => Validators.required(control)),
    internalComment: new FormControl(''),
  });

  constructor(private readonly dataService: DataService, private readonly notificationService: NotificationService) {}

  public deductWallet(): void {
    if (this.walletForm.invalid) {
      return;
    }

    this.dataService
      .mutate<DeductWalletMutation, DeductWalletMutationVariables>(DEDUCT_WALLET, {
        input: {
          customerId: this.wallet.customerId,
          walletId: this.wallet.id,
          total: (this.walletForm.value as DeductWalletInput).total,
          description: (this.walletForm.value as DeductWalletInput).description,
          internalComment: (this.walletForm.value as DeductWalletInput).internalComment,
        },
      })
      .subscribe((result) => {
        if (result.deductWallet?.id) {
          this.notificationService.success(_('walletPlugin.success.deduct'));
          this.onSuccess.emit(result.deductWallet as Wallet);
          this.walletForm.reset();
        } else {
          this.notificationService.error(_('walletPlugin.error.deduct'));
        }
      });
  }
}
