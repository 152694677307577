<h1 class="p2">{{ 'productVariantSelect.linkedProductVariants' | translate }}</h1>

<app-product-search-input
  *vdrIfPermissions="updateRecipePermissions"
  [excludedProductVariantIds]="selectedProductVariantIds"
  (selectChange)="addProductVariant($event)"
></app-product-search-input>

<vdr-data-table [items]="selectedProductVariants">
  <vdr-dt-column></vdr-dt-column>
  <vdr-dt-column>{{ 'common.name' | translate }}</vdr-dt-column>
  <vdr-dt-column></vdr-dt-column>

  <ng-template let-variant="item">
    <td class="left align-middle">
      <img
        *ngIf="variant?.preview"
        class="product-variant-select__image"
        [src]="variant.preview"
        [alt]="variant.name"
      />
    </td>
    <td class="left align-middle">{{ variant?.name }}</td>
    <td class="right align-middle">
      <button
        class="btn btn-outline-danger"
        type="button"
        [disabled]="!(updateRecipePermissions | hasPermission)"
        (click)="removeProductVariant(variant.id)"
      >
        <clr-icon shape="trash"></clr-icon>
        {{ 'productVariantSelect.unlink' | translate }}
      </button>
    </td>
  </ng-template>
</vdr-data-table>
