import { NgModule } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { registerDashboardWidget, SharedModule } from '@vendure/admin-ui/core';

import { KsSharedModule } from '@shared/shared.module';
import { Permission } from '@shared/types/generated-ui-types';
import { QuickDepositTopUpComponent } from './quick-deposit-top-up/quick-deposit-top-up.component';
import { TodaysOrdersWidgetComponent } from './todays-orders-widget/todays-orders-widget.component';

@NgModule({
  imports: [KsSharedModule, SharedModule],
  declarations: [TodaysOrdersWidgetComponent, QuickDepositTopUpComponent],
  providers: [
    registerDashboardWidget('todaysOrders', {
      title: _('widgets.todaysOrders.title'),
      supportedWidths: [4, 6, 8, 12],
      requiresPermissions: [Permission.ReadOrder],
      loadComponent: async () =>
        import('./todays-orders-widget/todays-orders-widget.component').then((m) => m.TodaysOrdersWidgetComponent),
    }),
    registerDashboardWidget(_('deposit'), {
      title: _('widgets.depositTopUp.title'),
      supportedWidths: [12],
      requiresPermissions: [Permission.TopUpWallet],
      loadComponent: async () =>
        import('./quick-deposit-top-up/quick-deposit-top-up.component').then((m) => m.QuickDepositTopUpComponent),
    }),
  ],
})
export class WidgetsModule {}
