<ng-template vdrDialogTitle>
  {{ 'catalog.assign-to-channel' | translate }}
</ng-template>

<div class="channel-dialog-assignment__container">
  <clr-input-container>
    <label>{{ 'common.channel' | translate }}</label>
    <vdr-channel-assignment-control
      clrInput
      [multiple]="false"
      [includeDefaultChannel]="false"
      [disableChannelIds]="currentChannelIds"
      [formControl]="selectedChannelIdControl"
    ></vdr-channel-assignment-control>
  </clr-input-container>
</div>

<ng-template vdrDialogButtons>
  <button type="button" class="btn" (click)="cancel()">
    {{ 'common.cancel' | translate }}
  </button>
  <button class="btn btn-primary" type="submit" [disabled]="!selectedChannel" (click)="assign()">
    <ng-template [ngIf]="selectedChannel" [ngIfElse]="noSelection">
      {{ 'catalog.assign-to-named-channel' | translate: { channelCode: selectedChannel?.code } }}
    </ng-template>
    <ng-template #noSelection>
      {{ 'catalog.no-channel-selected' | translate }}
    </ng-template>
  </button>
</ng-template>
