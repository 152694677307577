<div class="login-wrapper">
  <div class="login-wrapper-inner">
    <div class="login-wrapper-image">
      <div class="login-wrapper-image-content">
        <div class="login-wrapper-image-title">
          {{ 'common.login-image-title' | translate }}
        </div>
      </div>
      <img src="https://storage.googleapis.com/kleinstark-media-bucket-prod/emailAssets/kleinstark_team.jpg" />
    </div>
    <div class="login-wrapper-form">
      <p class="login-title">
        {{ 'common.login-title' | translate }}
      </p>
      <form class="login-form">
        <app-login-email></app-login-email>
        <!-- <app-login-google></app-login-google> -->
      </form>
    </div>
    <img class="login-wrapper-logo" src="assets/logo-300px.png" alt="Kleinstark Logo" width="150" height="150" />
  </div>
</div>
