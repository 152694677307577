<form [formGroup]="filterFormGroup">
  <vdr-action-bar>
    <vdr-ab-left>
      <div class="search-bar">
        <input
          class="clr-input search-input"
          type="text"
          name="searchTerm"
          formControlName="searchTerm"
          [placeholder]="'catalog.filter-by-name' | translate"
        />

        <ng-select
          bindValue="value"
          formControlName="status"
          [placeholder]="'recipePlugin.moreFilters' | translate"
          [items]="statuses"
          [multiple]="true"
          [closeOnSelect]="false"
        >
          <ng-template ng-option-tmp let-item="item">
            {{ item.label | translate }}
          </ng-template>
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label">{{ item.label | translate }}</span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </ng-template>
        </ng-select>
      </div>
    </vdr-ab-left>
    <vdr-ab-right>
      <vdr-action-bar-items locationId="recipe-list"></vdr-action-bar-items>
      <a
        *vdrIfPermissions="createRecipePermissions"
        class="btn btn-primary"
        [routerLink]="RECIPE_URLS.create.staticUrlAbsolute"
      >
        <clr-icon shape="plus"></clr-icon>
        {{ 'recipePlugin.newRecipe' | translate }}
      </a>
    </vdr-ab-right>
  </vdr-action-bar>
</form>

<vdr-data-table
  *varRef="editRecipePermissions | hasPermission as hasEditPermissions"
  [items]="items$ | async"
  [itemsPerPage]="itemsPerPage$ | async"
  [totalItems]="totalItems$ | async"
  [currentPage]="currentPage$ | async"
  (pageChange)="setPageNumber($event)"
  (itemsPerPageChange)="setItemsPerPage($event)"
>
  <vdr-dt-column>{{ 'common.name' | translate }}</vdr-dt-column>
  <vdr-dt-column></vdr-dt-column>
  <vdr-dt-column></vdr-dt-column>

  <ng-template let-recipe="item">
    <td class="left align-middle">{{ recipe.name }}</td>

    <td class="right align-middle">
      <vdr-table-row-action
        [iconShape]="hasEditPermissions ? 'edit' : 'eye'"
        [label]="(hasEditPermissions ? 'common.edit' : 'common.view') | translate"
        [linkTo]="[PAGE_URLS.recipes.staticUrlAbsolute, recipe.id]"
      ></vdr-table-row-action>
    </td>

    <td class="right align-middle">
      <vdr-dropdown>
        <button vdrDropdownTrigger class="btn btn-link btn-sm" type="button">
          {{ 'common.actions' | translate }}
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <vdr-dropdown-menu vdrPosition="bottom-right">
          <button
            vdrDropdownItem
            class="delete-button"
            type="button"
            [disabled]="!(deleteRecipePermissions | hasPermission)"
            (click)="delete(recipe.id)"
          >
            <clr-icon shape="trash" class="is-danger"></clr-icon>
            {{ 'common.delete' | translate }}
          </button>
        </vdr-dropdown-menu>
      </vdr-dropdown>
    </td>
  </ng-template>
</vdr-data-table>
