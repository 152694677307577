<h5>{{ 'walletPlugin.topUp' | translate }}</h5>

<form [formGroup]="walletForm" (ngSubmit)="topUpWallet()">
  <vdr-form-field for="total" [label]="'walletPlugin.amount' | translate">
    <vdr-currency-input formControlName="total" [currencyCode]="currencyCode"></vdr-currency-input>
  </vdr-form-field>
  <vdr-form-field for="isPayoutPossible" [label]="'walletPlugin.payoutPossibleToggle' | translate">
    <clr-toggle-wrapper>
      <input type="checkbox" clrToggle name="isPayoutPossible" formControlName="isPayoutPossible" />
      <label>{{ (walletForm.value.isPayoutPossible ? 'walletPlugin.yes' : 'walletPlugin.no') | translate }}</label>
    </clr-toggle-wrapper>
  </vdr-form-field>
  <vdr-form-field for="activity" [label]="'walletPlugin.activity' | translate">
    <select id="cook-level" formControlName="activity">
      <option *ngFor="let activity of availableWalletActivities" [value]="activity">
        {{ 'walletPlugin.activityValue.' + activity | translate }}
      </option>
    </select>
  </vdr-form-field>
  <vdr-form-field
    *ngIf="walletForm.controls['activity'].value !== WalletActivity.ORDER_GOODWILL"
    for="description"
    [label]="'walletPlugin.description' | translate"
  >
    <textarea formControlName="description"></textarea>
  </vdr-form-field>
  <section
    *ngIf="walletForm.controls['activity'].value === WalletActivity.ORDER_GOODWILL"
    class="form-block"
    style="margin-right: 20px"
  >
    <form [formGroup]="descriptionForm">
      <vdr-form-field [label]="'walletPlugin.orderCode' | translate">
        <input formControlName="orderCode" type="text" />
      </vdr-form-field>
      <div formArrayName="products" class="flex" style="flex-direction: column">
        <ng-container *ngFor="let productForm of productForms.controls; let i = index">
          <div class="flex" [formGroup]="productForm">
            <vdr-form-field [label]="'walletPlugin.quantityProduct' | translate">
              <input formControlName="quantity" type="number" style="width: 40px" />
            </vdr-form-field>
            <div style="padding: 5px 10px 0 10px">x</div>
            <vdr-form-field>
              <input formControlName="product" type="text" />
            </vdr-form-field>
            <button
              *ngIf="productForms.controls.length > 1"
              class="btn btn-danger-outline"
              style="margin-left: 16px"
              type="button"
              (click)="removeProduct(i)"
            >
              <clr-icon shape="trash"></clr-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </form>

    <button class="btn btn-icon btn-primary-outline" type="button" (click)="addProduct()">
      <clr-icon shape="plus"></clr-icon>
      {{ 'walletPlugin.addProduct' | translate }}
    </button>
  </section>

  <vdr-form-field for="internalComment" [label]="'walletPlugin.internalComment' | translate">
    <textarea formControlName="internalComment"></textarea>
  </vdr-form-field>

  <button
    class="btn btn-primary"
    [disabled]="
      walletForm.invalid ||
      (walletForm.controls['activity'].value === WalletActivity.ORDER_GOODWILL && descriptionForm.invalid)
    "
  >
    {{ 'walletPlugin.topUp' | translate }}
  </button>
</form>
