import { NgModule } from '@angular/core';
import { SharedModule } from '@vendure/admin-ui/core';

import { AlertModule } from '@shared/components/alert/alert.module';
import { LoginEmailComponent } from './components/login-email/login-email.component';
import { LoginGoogleComponent } from './components/login-google/login-google.component';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login.routing';

@NgModule({
  imports: [SharedModule, LoginRoutingModule, AlertModule],
  exports: [],
  declarations: [LoginComponent, LoginEmailComponent, LoginGoogleComponent],
})
export class LoginModule {}
