<ng-template vdrDialogTitle>{{ 'productImporterPlugin.importProducts' | translate }}</ng-template>

<b>{{ 'productImporterPlugin.instruction' | translate }}</b>
<br />

<div class="product-importer__body">
  <label for="file">{{ 'productImporterPlugin.selectFile' | translate }}:</label>
  <input type="file" accept=".csv" id="file" [disabled]="isLoading || isCompleted" (change)="handleFileInput($event)" />
</div>

<div *ngIf="isLoading" class="product-importer__loading">
  <span class="flex product-importer__loading-text">
    <clr-icon size="24" shape="exclamation-circle" class="mr2"></clr-icon>
    {{ 'productImporterPlugin.loading' | translate }}
  </span>

  <div class="progress loop"><progress></progress></div>
</div>

<div *ngIf="isCompleted && !isLoading" class="product-importer__complete">
  <span class="flex">
    <clr-icon size="24" shape="success-standard" class="mr2"></clr-icon>
    {{ 'productImporterPlugin.done' | translate }}
  </span>

  <div *ngIf="result">
    <div *ngIf="result && result.alreadyExistingProducts.length > 0" class="product-importer__result">
      <span> {{ 'productImporterPlugin.alreadyExistingProducts' | translate }}:</span>
      <span *ngFor="let item of result.alreadyExistingProducts">
        {{ item.name }}
      </span>
    </div>

    <div *ngIf="result && result.productsWithMissingAssets.length > 0" class="product-importer__result">
      <span> {{ 'productImporterPlugin.productsWithMissingAssets' | translate }}:</span>
      <span *ngFor="let item of result.productsWithMissingAssets">
        {{ item.name }}
      </span>
    </div>

    <div *ngIf="result && result.productsWithUnknownError.length > 0" class="product-importer__result">
      <span> {{ 'productImporterPlugin.productsWithUnknownError' | translate }}:</span>
      <span *ngFor="let item of result.productsWithUnknownError">
        {{ item.name }}
      </span>
    </div>
  </div>
</div>

<ng-template vdrDialogButtons>
  <ng-container *ngIf="!isCompleted">
    <button type="button" class="btn btn-danger" [disabled]="isLoading" (click)="cancel()">
      {{ 'common.cancel' | translate }}
    </button>

    <button type="button" class="btn btn-primary" [disabled]="isLoading || !fileToUpload" (click)="startImport()">
      {{ 'productImporterPlugin.startImport' | translate }}
    </button>
  </ng-container>

  <button
    *ngIf="isCompleted && !isLoading"
    type="button"
    class="btn btn-primary"
    [disabled]="isLoading"
    (click)="confirm()"
  >
    {{ 'productImporterPlugin.done' | translate }}
  </button>
</ng-template>
