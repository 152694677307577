import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AuthService } from '@vendure/admin-ui/core';

import { LoginService } from '../../login.service';

@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginEmailComponent {
  public username: string = '';
  public password: string = '';
  public rememberMe: boolean = false;
  public errorMessage: string | undefined;

  constructor(
    private readonly authService: AuthService,
    private readonly loginService: LoginService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  public logInWithEmail(): void {
    this.errorMessage = undefined;

    this.authService.logIn(this.username, this.password, this.rememberMe).subscribe((result) => {
      switch (result.__typename) {
        case 'CurrentUser':
          this.loginService.navigateAfterLogin();
          break;
        case 'InvalidCredentialsError':
          this.errorMessage = 'Benutzername und/oder Passwort sind falsch';
          break;
        case 'NativeAuthStrategyError':
          this.errorMessage = result.message;
          break;
        default:
          break;
      }

      this.changeDetectorRef.markForCheck();
    });
  }
}
