import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseEntityResolver, DataService } from '@vendure/admin-ui/core';

import { GetRecipe } from '@shared/types/generated-ui-types';
import { GET_RECIPE } from './recipe.graphql';

@Injectable()
export class RecipeDetailResolver extends BaseEntityResolver<GetRecipe.Recipe> {
  constructor(protected override router: Router, private readonly dataService: DataService) {
    super(
      router,
      {
        id: '',
        status: '',
        name: '',
        description: '',
        featuredAsset: null,
      },
      (id) =>
        this.dataService
          .query<GetRecipe.Query, GetRecipe.Variables>(GET_RECIPE, { id })
          .mapStream((data) => data.recipe)
    );
  }
}
