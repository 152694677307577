import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * Directive to store a variable in a another variable without loosing type safety.
 * Useful for e.g. async values or nested values:
 * @example
 * Recommended:
 * <div *varRef="someObservable$ | async as someVariable">
 *   {{ someVariable }}
 * </div>
 *
 * <div *varRef="someObject.address as address">
 *   {{ address.street }} {{ address.city }}
 * </div>
 *
 * Alternatives:
 * <div *varRef="someObservable$ | async; let someVariable">
 *   {{ someVariable }}
 * </div>
 *
 * <div *varRef="someObservable$ | async; let someVariable = varRef">
 *   {{ someVariable }}
 * </div>
 *
 * Inspired by the *ngIf directive:
 * @see https://github.com/angular/angular/blob/master/packages/common/src/directives/ng_if.ts
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[varRef]',
})
export class VarRefDirective<T> {
  /** Variable that should be available via `... as XYZ` */
  @Input()
  set varRef(variable: T) {
    this.context.$implicit = this.context.varRef = variable;
    this.updateView();
  }

  private context: VarRefContext<T> = new VarRefContext<T>();
  private viewRef: EmbeddedViewRef<VarRefContext<T>> | null = null;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<VarRefContext<T>>
  ) {}

  private updateView(): void {
    if (!this.viewContainerRef || this.viewRef) {
      return;
    }

    this.viewContainerRef.clear();
    if (this.templateRef) {
      this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
    }
  }
}

export class VarRefContext<T = unknown> {
  public $implicit: T | null = null;
  public varRef: T | null = null;
}
