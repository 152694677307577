import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { PAGE_URLS } from '@navUrls';
import { KsRoute } from '@shared/models/KsRoutes';
import { CapacityTabsComponent } from './components/capacity-tabs/capacity-tabs.component';

const routes: KsRoute[] = [
  {
    path: 'capacity',
    pathMatch: 'full',
    component: CapacityTabsComponent,
    data: {
      breadcrumb: [
        {
          label: _('capacityPlugin.navItem'),
          link: PAGE_URLS.capacity.getUrlSegments(),
        },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CapacityRoutingModule {}
