<ng-container *ngIf="isSuccess$ | async; else content">
  <div class="mb4">
    <h3>{{ 'widgets.depositTopUp.success' | translate }}</h3>
  </div>
  <button class="btn btn-primary" (click)="restart()">
    {{ 'widgets.depositTopUp.newDeposit' | translate }}
  </button>
</ng-container>
<ng-template #content>
  <div class="mb4">
    <h5>{{ 'widgets.depositTopUp.chooseCustomer' | translate }}</h5>
  </div>
  <ng-select
    [items]="customers$ | async"
    [typeahead]="searchTerm$"
    [placeholder]="'customer.search-customers-by-email-last-name-postal-code' | translate"
    [formControl]="customerControl"
  >
    <ng-template ng-label-tmp ng-option-tmp let-item="item"
      >{{ item.firstName }} {{ item.lastName }} - {{ item.emailAddress }}</ng-template
    >
  </ng-select>

  <app-customer-wallet-top-up
    *ngIf="wallet$ | async as wallet"
    [preSelectedActivity]="WalletActivity.DEPOSIT_REFUND"
    [wallet]="wallet"
    [currencyCode]="currencyCode"
    (onSuccess)="isSuccess$.next(true)"
  ></app-customer-wallet-top-up>
</ng-template>
