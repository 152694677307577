import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const GET_ORDER: DocumentNode = gql`
  query getOrder($id: ID!) {
    order(id: $id) {
      id
      payments {
        method
      }
    }
  }
`;

export const GET_INVOICE_OF_ORDER: DocumentNode = gql`
  query getInvoiceOfOrder($orderId: ID!) {
    invoiceOfOrder(orderId: $orderId) {
      id
      downloadUrl
    }
  }
`;
