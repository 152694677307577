<div class="btn-group btn-outline-primary btn-sm" *ngIf="selection$ | async as selection">
  <button
    class="btn"
    [class.btn-primary]="selection.timeFrame === 'today'"
    (click)="selection$.next({ timeFrame: 'today', date: today })"
  >
    {{ 'dashboard.today' | translate }}
  </button>
  <button
    class="btn"
    [class.btn-primary]="selection.timeFrame === 'tomorrow'"
    (click)="selection$.next({ timeFrame: 'tomorrow', date: tomorrow })"
  >
    {{ 'widgets.todaysOrders.tomorrow' | translate }}
  </button>
</div>
<vdr-data-table [items]="todaysOrders$ | async">
  <ng-template let-order="item">
    <td class="left align-middle p4" style="grid-area: time">
      {{ order.customFields.earliestDeliveryTime | date: 'shortTime' }} -
      {{ order.customFields.latestDeliveryTime | date: 'shortTime' }}
      <vdr-order-state-label [state]="order.state"></vdr-order-state-label>
    </td>

    <td class="left align-middle" style="grid-area: customer">
      <vdr-customer-label [customer]="order.customer"></vdr-customer-label>
    </td>

    <td class="left align-middle" style="grid-area: address">
      {{ order.shippingAddress.streetLine1 }}, {{ order.shippingAddress.postalCode }} {{ order.shippingAddress.city }}
    </td>

    <td class="left align-middle icon-container" style="grid-area: ring">
      <clr-icon *ngIf="order.customFields.doNotRing" shape="times" size="36" class="overlay-icon is-danger"></clr-icon>

      <clr-icon
        shape="bell"
        size="20"
        [ngClass]="{ 'is-success': !order.customFields.doNotRing, 'is-solid': !order.customFields.doNotRing }"
      >
      </clr-icon>
    </td>
    <td class="left align-middle icon-container" style="grid-area: drop">
      <clr-icon
        *ngIf="!order.customFields.canDropOrder"
        shape="times"
        size="36"
        class="overlay-icon is-danger"
      ></clr-icon>

      <clr-icon
        shape="bundle"
        size="20"
        class="is-solid"
        [ngClass]="{ 'is-success': order.customFields.canDropOrder }"
      ></clr-icon>
    </td>
    <td class="left align-middle" style="grid-area: total">{{ order.total | localeCurrency: order.currencyCode }}</td>
    <td class="right align-middle" style="grid-area: details">
      <vdr-table-row-action
        iconShape="shopping-cart"
        [label]="'common.open' | translate"
        [linkTo]="['/orders/', order.id]"
      ></vdr-table-row-action>
    </td>
  </ng-template>
</vdr-data-table>
