import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const CREATE_GIFT_COUPON: DocumentNode = gql`
  mutation CreateGiftCoupon($input: CreateGiftCouponInput!) {
    createGiftCoupon(input: $input) {
      code
    }
  }
`;

export const ACTIVATE_GIFT_COUPONS: DocumentNode = gql`
  mutation ActivateGiftCoupons($input: ActivateGiftCouponsInput!) {
    activateGiftCoupons(input: $input)
  }
`;

export const REDEEM_GIFT_COUPON: DocumentNode = gql`
  mutation RedeemGiftCoupon($input: RedeemGiftCouponInput!) {
    redeemGiftCoupon(input: $input)
  }
`;
