import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetPickerDialogComponent, AssetPreviewDialogComponent, ModalService } from '@vendure/admin-ui/core';
import { AssetFragment, GetAssetList } from '@vendure/admin-ui/core/common/generated-types';
import { map } from 'rxjs/operators';

import { Asset } from '@shared/types/generated-ui-types';

/**
 * Modified version of Vendure's product asset selector
 * @see https://github.com/vendure-ecommerce/vendure/blob/master/packages/admin-ui/src/lib/catalog/src/components/product-assets/product-assets.component.ts
 */
@Component({
  selector: 'app-feature-asset',
  templateUrl: './feature-asset.component.html',
  styleUrls: ['./feature-asset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureAssetComponent {
  @Input()
  public featuredAsset: Asset | undefined;
  @Output()
  public featuredAssetChange: EventEmitter<Asset | undefined> = new EventEmitter<Asset | undefined>();

  constructor(private readonly modalService: ModalService, private readonly changeDetector: ChangeDetectorRef) {}

  public selectAsset(): void {
    this.modalService
      .fromComponent(AssetPickerDialogComponent, {
        size: 'xl',
        locals: { multiSelect: false },
      })
      .pipe(map((result) => result?.[0] as Asset))
      .subscribe((asset) => {
        this.featuredAsset = asset;

        this.featuredAssetChange.emit(this.featuredAsset);
        this.changeDetector.markForCheck();
      });
  }

  public removeAsset(): void {
    this.featuredAsset = undefined;

    this.featuredAssetChange.emit(this.featuredAsset);
    this.changeDetector.markForCheck();
  }

  public previewAsset(asset: GetAssetList.Items | AssetFragment | undefined): void {
    this.modalService
      .fromComponent(AssetPreviewDialogComponent, {
        size: 'xl',
        closable: true,
        locals: { asset },
      })
      .subscribe();
  }
}
