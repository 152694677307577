import { Component } from '@angular/core';
import {
  OrderDetailFragment,
  OrderHistoryEntryComponent,
  TimelineDisplayType,
  TimelineHistoryEntry,
} from '@vendure/admin-ui/core';

@Component({
  selector: 'app-order-inactive-history-entry',
  templateUrl: './order-inactive-history-entry.component.html',
})
export class OrderInactiveHistoryEntryComponent implements OrderHistoryEntryComponent {
  public entry: TimelineHistoryEntry;
  public order: OrderDetailFragment;

  public getDisplayType(): TimelineDisplayType {
    return 'default';
  }

  public isFeatured(): boolean {
    return true;
  }
}
