import { NgModule } from '@angular/core';
import { SharedModule } from '@vendure/admin-ui/core';

import { ChannelDialogAssignmentComponent } from './channel-dialog-assignment/channel-dialog-assignment.component';
import { ChannelSelectComponent } from './channel-select.component';

const COMPONENTS = [ChannelSelectComponent, ChannelDialogAssignmentComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class ChannelSelectModule {}
