<ng-container *varRef="entity$ | async as channel">
  <div *ngIf="!isDefaultChannel(channel?.code)">
    <h3>{{ 'channelPlugin.deliveryArea.multiple' | translate }}</h3>

    <div *vdrIfPermissions="updatePermissions" class="delivery-area-list__create">
      <div>
        <input [placeholder]="'channelPlugin.deliveryArea.enterName' | translate" [formControl]="formControl" />
      </div>
      <button class="btn btn-primary-outline delivery-area-list__button" (click)="createDeliveryArea(channel?.id)">
        {{ 'channelPlugin.deliveryArea.createDeliveryArea' | translate }}
      </button>
    </div>

    <app-delivery-area-item
      *ngFor="let deliveryArea of deliveryAreas$ | async"
      [deliveryArea]="deliveryArea"
      (dataHasChanged)="catchData()"
    ></app-delivery-area-item>
  </div>
</ng-container>
