import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { BaseDetailComponent, DataService, NotificationService, ServerConfigService } from '@vendure/admin-ui/core';
import { DEFAULT_CHANNEL_CODE } from '@vendure/common/lib/shared-constants';
import { Observable, of } from 'rxjs';
import { filter, map, mapTo, takeUntil } from 'rxjs/operators';

import { SPECIAL_URLS } from '@navUrls';
import { Status } from '@shared/types/general.types';
import {
  Asset,
  AssignSpecialToChannel,
  Channel,
  CreateSpecial,
  CreateSpecialInput,
  Permission,
  RemoveSpecialFromChannel,
  Special,
  UpdateSpecial,
  UpdateSpecialInput,
} from '@shared/types/generated-ui-types';
import { MinimalistProductVariant } from '@shared/types/minimalist-product-variant';
import { trimRichTextEditorContent } from '@shared/utils';
import {
  ASSIGN_SPECIAL_TO_CHANNEL,
  CREATE_SPECIAL,
  REMOVE_SPECIAL_FROM_CHANNEL,
  UPDATE_SPECIAL,
} from '../../special.graphql';

interface IFormValues {
  enabled: boolean;
  title: string;
  category: string;
  description: string;
  sortOrder: number;
  link: string;
  linkLeadsToSpecial: boolean;
  isLinkExternal: boolean;
}

const SPECIAL_DETAILS_LINK: string = '/special/';

@Component({
  selector: 'app-special-detail',
  styleUrls: ['./special-detail.component.scss'],
  templateUrl: './special-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialDetailComponent extends BaseDetailComponent<Special> implements OnInit, OnDestroy {
  public selectedChannels$: Observable<Channel[] | undefined | null>;

  public featuredAsset$: Observable<Asset>;
  public assetChanges: { featureAsset: Asset } | null;

  public linkedProductVariants$: Observable<MinimalistProductVariant[] | undefined>;
  public productVariantChanges: MinimalistProductVariant[] | null = null;

  public detailForm: FormGroup = new FormGroup({
    enabled: new FormControl(false),
    title: new FormControl(''),
    category: new FormControl(''),
    description: new FormControl(''),
    sortOrder: new FormControl(''),
    link: new FormControl(''),
    linkLeadsToSpecial: new FormControl(true),
    isLinkExternal: new FormControl(false),
  });

  public createSpecialPermissions: Permission[] = [Permission.CreateSpecial];
  public updateSpecialPermissions: Permission[] = [Permission.UpdateSpecial];

  public DEFAULT_CHANNEL_CODE: typeof DEFAULT_CHANNEL_CODE = DEFAULT_CHANNEL_CODE;

  constructor(
    protected override route: ActivatedRoute,
    protected override router: Router,
    protected override serverConfigService: ServerConfigService,
    protected override dataService: DataService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly notificationService: NotificationService
  ) {
    super(route, router, serverConfigService, dataService);
  }

  public ngOnInit(): void {
    this.init();

    this.selectedChannels$ = this.entity$?.pipe(map((special) => special.channels));

    this.featuredAsset$ = this.entity$?.pipe(map((special) => special.featuredAsset as Asset));

    this.linkedProductVariants$ = this.entity$?.pipe(
      map(
        (special) =>
          special.productVariants?.map((productVariant) => ({
            preview: productVariant.featuredAsset?.preview ?? productVariant.product?.featuredAsset?.preview,
            id: productVariant.id,
            name: productVariant.name || productVariant.product?.name,
          })) as MinimalistProductVariant[]
      )
    );

    this.isNew$.pipe(takeUntil(this.destroy$)).subscribe((isNew) => {
      const enabledFormField: AbstractControl = this.detailForm.controls.enabled;
      if (isNew) {
        enabledFormField.disable();
      } else {
        enabledFormField.enable();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy();
  }

  public assignToChannel(channelId: string): void {
    if (!channelId) {
      return;
    }

    this.dataService
      .mutate<AssignSpecialToChannel.Mutation, AssignSpecialToChannel.Variables>(ASSIGN_SPECIAL_TO_CHANNEL, {
        input: { specialId: this.id, channelId },
      })
      .subscribe(
        () => {
          this.notificationService.success('common.notify-update-success', {
            entity: _('specialPlugin.special.1'),
          });
          this.detailForm.markAsPristine();
          this.changeDetector.markForCheck();
        },
        () => {
          this.notificationService.error('common.notify-update-error', {
            entity: _('specialPlugin.special.1'),
          });
        }
      );
  }

  public removeFromChannel(channelId: string): void {
    if (!channelId) {
      return;
    }

    this.dataService
      .mutate<RemoveSpecialFromChannel.Mutation, RemoveSpecialFromChannel.Variables>(REMOVE_SPECIAL_FROM_CHANNEL, {
        input: { specialId: this.id, channelId },
      })
      .subscribe(
        () => {
          this.notificationService.success('common.notify-update-success', {
            entity: _('specialPlugin.special.1'),
          });
          this.detailForm.markAsPristine();
          this.changeDetector.markForCheck();
        },
        () => {
          this.notificationService.error('common.notify-update-error', {
            entity: _('specialPlugin.special.1'),
          });
        }
      );
  }

  public create(): void {
    if (!this.detailForm?.dirty) {
      return;
    }

    const formValue: IFormValues = this.detailForm.value as IFormValues;
    const special: CreateSpecialInput = {
      title: formValue.title,
      category: formValue.category,
      description: trimRichTextEditorContent(formValue.description),
      sortOrder: formValue.sortOrder,
      externalLink: this.getExternalLink(formValue),
      internalLink: this.getInternalLink(formValue),
    };

    if (this.assetChanged()) {
      special.featuredAssetId = this.assetChanges?.featureAsset?.id;
    }

    this.dataService
      .mutate<CreateSpecial.Mutation, CreateSpecial.Variables>(CREATE_SPECIAL, {
        input: special,
      })
      .subscribe(
        (data) => {
          this.notificationService.success('common.notify-create-success', {
            entity: _('specialPlugin.special.1'),
          });
          this.assetChanges = null;
          this.detailForm.markAsPristine();
          this.changeDetector.markForCheck();
          void this.router.navigate(
            SPECIAL_URLS.details.getUrlSegments({
              params: { id: data.createSpecial.id },
            })
          );
        },
        () => {
          this.notificationService.error('common.notify-create-error', {
            entity: _('specialPlugin.special.1'),
          });
        }
      );
  }

  public save(): void {
    this.saveChanges()
      .pipe(filter((result) => result))
      .subscribe(
        () => {
          this.assetChanges = null;
          this.productVariantChanges = null;
          this.detailForm.markAsPristine();
          this.changeDetector.markForCheck();
          this.notificationService.success('common.notify-update-success', {
            entity: _('specialPlugin.special.1'),
          });
        },
        () => {
          this.notificationService.error('common.notify-update-error', {
            entity: _('specialPlugin.special.1'),
          });
        }
      );
  }

  public override canDeactivate(): boolean {
    return super.canDeactivate() && !this.assetChanged() && !this.productVariantsChanged();
  }

  public assetChanged(): boolean {
    return !!this.assetChanges;
  }

  public productVariantsChanged(): boolean {
    return this.productVariantChanges !== null;
  }

  private saveChanges(): Observable<boolean> {
    const input: UpdateSpecialInput = { id: this.id };
    if (this.detailForm.dirty) {
      const formValue: IFormValues = this.detailForm.value as IFormValues;
      input.status = formValue.enabled ? Status.ACTIVE : Status.INACTIVE;
      input.title = formValue.title;
      input.category = formValue.category;
      input.description = trimRichTextEditorContent(formValue.description);
      input.sortOrder = formValue.sortOrder;
      input.externalLink = this.getExternalLink(formValue);
      input.internalLink = this.getInternalLink(formValue);
    }

    if (this.assetChanged()) {
      input.featuredAssetId = this.assetChanges?.featureAsset?.id ?? null;
    }

    if (this.productVariantsChanged()) {
      input.productVariantIds = this.productVariantChanges?.map((variant) => variant.id);
    }

    if (Object.values(input).length > 1) {
      return this.dataService
        .mutate<UpdateSpecial.Mutation, UpdateSpecial.Variables>(UPDATE_SPECIAL, {
          input,
        })
        .pipe(mapTo(true));
    } else {
      return of(false);
    }
  }

  protected setFormValues(entity: Special): void {
    this.detailForm.patchValue({
      enabled: entity.status === Status.ACTIVE || false,
      title: entity.title,
      category: entity.category,
      description: entity.description ?? null,
      sortOrder: entity.sortOrder,
      link: entity.internalLink ?? entity.externalLink ?? null,
      linkLeadsToSpecial: entity.internalLink?.startsWith(`${SPECIAL_DETAILS_LINK}${this.id}/`),
      isLinkExternal: !entity.internalLink && !!entity.externalLink,
    });
  }

  public handleSelectedProductVariantChanged(productVariants: MinimalistProductVariant[]): void {
    this.productVariantChanges = productVariants || null;
  }

  public handleFeatureAssetChanged(asset: Asset): void {
    this.assetChanges = { featureAsset: asset };
  }

  private getExternalLink(formValue: IFormValues): string | null {
    if (formValue.isLinkExternal && !formValue.linkLeadsToSpecial) {
      return formValue.link || null;
    }
    return null;
  }

  private getInternalLink(formValue: IFormValues): string | null {
    if (formValue.linkLeadsToSpecial) {
      return `${SPECIAL_DETAILS_LINK}${this.id}/`;
    }
    if (formValue.isLinkExternal) {
      return null;
    }
    return formValue.link || null;
  }
}
