import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@vendure/admin-ui/core';

import { DirectivesModule } from '@shared/directives/directives.module';
import { AddressSelectComponent } from './address-select.component';

@NgModule({
  declarations: [AddressSelectComponent],
  imports: [CommonModule, NgSelectModule, DirectivesModule, SharedModule],
  exports: [AddressSelectComponent],
})
export class AddressSelectModule {}
