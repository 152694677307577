import { NgModule } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CatalogModule } from '@vendure/admin-ui/catalog';
import { NavMenuItem, SharedModule } from '@vendure/admin-ui/core';

import { PAGE_URLS } from '@navUrls';
import { KsSharedModule } from '@shared/shared.module';
import { SpecialDetailComponent } from './components/special-detail/special-detail.component';
import { SpecialListComponent } from './components/special-list/special-list.component';
import { SpecialRoutingModule } from './special.routing';
import { SpecialDetailResolver } from './special-details.resolver';

export const SPECIAL_NAV_ITEM: NavMenuItem = {
  id: 'special',
  label: _('specialPlugin.navItem'),
  routerLink: PAGE_URLS.specials.getUrlSegments(),
  icon: 'view-cards',
};

@NgModule({
  imports: [CatalogModule, KsSharedModule, SharedModule, SpecialRoutingModule],
  declarations: [SpecialDetailComponent, SpecialListComponent],
  providers: [SpecialDetailResolver],
})
export class SpecialModule {}
