import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataService, GetCustomerListQuery } from '@vendure/admin-ui/core';
import { Customer } from '@vendure/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { ChannelService } from '@services';
import { GET_WALLET } from '@shared/components/customer-wallet-top-up/customer-wallet-top-up.graphql';
import { CurrencyCode, Wallet, WalletQuery, WalletQueryVariables } from '@shared/types/generated-ui-types';
import { WalletActivity } from '@shared/types/wallet';

@UntilDestroy()
@Component({
  selector: 'app-quick-deposit-top-up',
  templateUrl: './quick-deposit-top-up.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickDepositTopUpComponent implements OnInit, OnDestroy {
  public searchTerm$: Subject<string> = new Subject<string>();
  public customerControl: FormControl = new FormControl();

  public customers$: Observable<GetCustomerListQuery['customers']['items']>;

  public wallet$: Observable<Wallet | null>;

  public currencyCode: CurrencyCode | undefined;

  public isSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly destroy$: Subject<void> = new Subject<void>();

  public readonly WalletActivity: typeof WalletActivity = WalletActivity;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly channelService: ChannelService,
    private readonly dataService: DataService
  ) {}

  public ngOnInit(): void {
    this.channelService.currentChannel$.pipe(untilDestroyed(this)).subscribe((channel) => {
      this.currencyCode = channel?.currencyCode;
      this.changeDetectorRef.markForCheck();
    });

    this.customers$ = this.searchTerm$.pipe(
      debounceTime(200),
      switchMap((term: string) =>
        this.dataService.customer.getCustomerList(10, 0, term).mapStream((res) => res.customers.items)
      )
    );

    this.customerControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((customer: Customer) => {
      if (!customer) {
        this.wallet$ = of(null);
        return;
      }

      this.wallet$ = this.dataService
        .query<WalletQuery, WalletQueryVariables>(GET_WALLET, {
          customerId: String(customer.id),
        })
        .mapSingle((data) => data.walletByCustomerId);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public restart(): void {
    this.wallet$ = of(null);
    this.customerControl.reset();

    this.isSuccess$.next(false);
  }
}
