<ng-select
  #select
  *ngIf="!readonly"
  [typeahead]="searchTerm$"
  [items]="addressSuggestions$ | async"
  [placeholder]="'addressSelect.searchAddress' | translate"
  [typeToSearchText]="'addressSelect.typeToSearch' | translate"
  [loading]="areAddressesLoading"
  [loadingText]="'addressSelect.loading' | translate"
  [notFoundText]="
    (((searchTerm$ | async) || '').trim().length < 3 ? 'addressSelect.typeToSearch' : 'addressSelect.noAddressFound')
      | translate
  "
  (input)="searchTerm = $any($event.target).value"
  (change)="handleChange($event)"
  (close)="select.searchTerm = searchTerm"
>
  <ng-template ng-option-tmp let-item="item">
    <div class="address-suggestion">
      <ng-container *ngIf="!ignoreIsInArea">
        <clr-icon *ngIf="item.isInArea" class="is-in-area" shape="map-marker"></clr-icon>
        <clr-icon *ngIf="!item.isInArea" class="is-not-in-area" shape="times"></clr-icon>
      </ng-container>
      <div *varRef="item.address as addressSuggestion">
        {{ addressSuggestion.street }} {{ addressSuggestion.streetNumber }}<br />
        {{ addressSuggestion.postalCode }} {{ addressSuggestion.city }}<br />
        {{ addressSuggestion.province }}, {{ addressSuggestion.country }}<br />
      </div>
    </div>
  </ng-template>
</ng-select>
<form *varRef="addressForm.value.isExpertMode as isExpertMode" [formGroup]="addressForm" class="pt0">
  <div class="clr-row">
    <div class="clr-col">
      <section class="form-block flex align-center">
        <vdr-form-field
          for="expertMode"
          class="mt3"
          [label]="('addressSelect.expertMode.label' | translate) + ' ⚠️'"
          [tooltip]="'addressSelect.expertMode.hint' | translate"
        >
          <clr-toggle-wrapper class="expert-mode-toggle">
            <input
              clrToggle
              type="checkbox"
              name="expertMode"
              formControlName="isExpertMode"
              (change)="showExpertModeIndicator = false"
            />
          </clr-toggle-wrapper>
        </vdr-form-field>

        <div *ngIf="showExpertModeIndicator" class="expert-mode-indicator">
          <clr-icon shape="info-standard" class="mr2"></clr-icon>
          {{ 'addressSelect.expertMode.indicator' | translate }}
        </div>
      </section>

      <section class="form-block">
        <div class="form-group">
          <div *ngIf="addressForm.dirty && addressForm.invalid" class="address-form-is-invalid">
            {{ 'addressSelect.completeAddressData' | translate }}
          </div>
        </div>

        <vdr-form-field for="fullName" [label]="'addressSelect.fullName' | translate">
          <input
            id="fullName"
            type="text"
            formControlName="fullName"
            [readonly]="!isExpertMode"
            (click)="handleClickOnInput()"
          />
        </vdr-form-field>

        <vdr-form-field for="street" [label]="'addressSelect.streetAndHouseNumber' | translate">
          <input
            id="street"
            class="input--wide"
            type="text"
            formControlName="street"
            [readonly]="!isExpertMode"
            (click)="handleClickOnInput()"
          />
          <input
            id="streetNumber"
            class="ml2 input--small"
            type="text"
            formControlName="streetNumber"
            [readonly]="!isExpertMode"
            (click)="handleClickOnInput()"
          />
        </vdr-form-field>
        <vdr-form-field for="postalCode" [label]="'addressSelect.postalCodeAndCity' | translate">
          <input
            id="postalCode"
            class="input--small"
            type="text"
            formControlName="postalCode"
            [readonly]="!isExpertMode"
            (click)="handleClickOnInput()"
          />
          <input
            id="city"
            class="ml2 input--wide"
            type="text"
            formControlName="city"
            [readonly]="!isExpertMode"
            (click)="handleClickOnInput()"
          />
        </vdr-form-field>
        <vdr-form-field for="province" [label]="'addressSelect.province' | translate">
          <input
            id="province"
            type="text"
            formControlName="province"
            [readonly]="!isExpertMode"
            (click)="handleClickOnInput()"
          />
        </vdr-form-field>
        <vdr-form-field
          for="countryCode"
          [label]="'addressSelect.countryCode.label' | translate"
          [tooltip]="'addressSelect.countryCode.hint' | translate"
        >
          <input
            id="countryCode"
            class="input--tiny"
            type="text"
            formControlName="countryCode"
            placeholder="de"
            maxlength="2"
            [readonly]="!isExpertMode"
            (click)="handleClickOnInput()"
          />
        </vdr-form-field>
        <vdr-form-field
          for="latitude"
          [label]="'addressSelect.coordinates.label' | translate"
          [tooltip]="'addressSelect.coordinates.hint' | translate"
        >
          <input
            id="latitude"
            type="number"
            formControlName="latitude"
            placeholder="49.979035"
            [readonly]="!isExpertMode"
            (click)="handleClickOnInput()"
          />
          <input
            id="longitude"
            class="ml2"
            type="number"
            formControlName="longitude"
            placeholder="9.1455206"
            [readonly]="!isExpertMode"
            (click)="handleClickOnInput()"
          />
        </vdr-form-field>
      </section>
      <div class="flex space-between">
        <button *ngIf="allowSubmit" class="btn btn-primary" (click)="onSubmit()">
          {{ 'common.confirm' | translate }}
        </button>
        <button *ngIf="allowCanceling" type="button" class="btn btn-secondary" (click)="cancelEditingAddress()">
          {{ 'common.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
