import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DataService, NotificationService } from '@vendure/admin-ui/core';

import {
  CurrencyCode,
  TopUpWalletInput,
  TopUpWalletMutation,
  TopUpWalletMutationVariables,
  Wallet,
} from '@shared/types/generated-ui-types';
import { WalletActivity } from '@shared/types/wallet';
import { TOP_UP_WALLET } from './customer-wallet-top-up.graphql';

interface DescriptionForm {
  orderCode: string;
  products: ProductForm[];
}

interface ProductForm {
  quantity: number;
  product: string;
}

@Component({
  selector: 'app-customer-wallet-top-up',
  templateUrl: './customer-wallet-top-up.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerWalletTopUpComponent implements OnChanges {
  @Input()
  public wallet: Wallet;
  @Input()
  public preSelectedActivity: WalletActivity;
  @Input()
  public currencyCode: CurrencyCode;
  @Output()
  public onSuccess: EventEmitter<Wallet> = new EventEmitter<Wallet>();

  public availableWalletActivities: WalletActivity[] = [
    WalletActivity.SUPPORT_CREDIT,
    WalletActivity.ORDER_GOODWILL,
    WalletActivity.DEPOSIT_REFUND,
  ];

  public walletForm: FormGroup = new FormGroup({
    total: new FormControl('', [(control) => Validators.required(control), Validators.min(1)]),
    activity: new FormControl('', (control) => Validators.required(control)),
    description: new FormControl(''),
    isPayoutPossible: new FormControl(true, (control) => Validators.required(control)),
    internalComment: new FormControl(''),
  });

  public descriptionForm: FormGroup = new FormGroup({
    orderCode: new FormControl('', (control) => Validators.required(control)),
    products: new FormArray([
      new FormGroup({
        quantity: new FormControl('', [(control) => Validators.required(control), Validators.min(1)]),
        product: new FormControl('', (control) => Validators.required(control)),
      }),
    ]),
  });

  get productForms(): FormArray {
    return this.descriptionForm.controls.products as FormArray;
  }

  public WalletActivity: typeof WalletActivity = WalletActivity;

  constructor(private readonly dataService: DataService, private readonly notificationService: NotificationService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.preSelectedActivity && this.preSelectedActivity) {
      this.walletForm.controls.activity.setValue(this.preSelectedActivity);
      this.walletForm.controls.activity.disable();
    }
  }

  public addProduct(): void {
    const productForm: FormGroup = new FormGroup({
      quantity: new FormControl('', [(control) => Validators.required(control), Validators.min(1)]),
      product: new FormControl('', (control) => Validators.required(control)),
    });
    this.productForms.push(productForm);
  }

  public removeProduct(productIndex: number): void {
    this.productForms.removeAt(productIndex);
  }

  public topUpWallet(): void {
    if (
      this.walletForm.invalid ||
      (this.walletForm.controls.activity.value === WalletActivity.ORDER_GOODWILL && this.descriptionForm.invalid)
    ) {
      return;
    }

    let description: string = '';

    switch (this.walletForm.controls.activity.value) {
      case WalletActivity.ORDER_GOODWILL: {
        description += `#${(this.descriptionForm.value as DescriptionForm).orderCode}: `;

        const products: AbstractControl[] = (this.descriptionForm.controls.products as FormArray).controls;

        for (let i: number = 0; i < products.length; i++) {
          description += `${(products[i].value as ProductForm).quantity} x ${
            (products[i].value as ProductForm).product
          }`;
          if (products[i + 1]) {
            description += `, `;
          }
        }
        break;
      }
      default:
        description = String((this.walletForm.value as TopUpWalletInput).description ?? '');
        break;
    }

    this.dataService
      .mutate<TopUpWalletMutation, TopUpWalletMutationVariables>(TOP_UP_WALLET, {
        input: {
          customerId: this.wallet.customerId,
          walletId: this.wallet.id,
          activity: this.preSelectedActivity ?? (this.walletForm.value as TopUpWalletInput).activity,
          total: (this.walletForm.value as TopUpWalletInput).total,
          isPayoutPossible: (this.walletForm.value as TopUpWalletInput).isPayoutPossible,
          description,
          internalComment: (this.walletForm.value as TopUpWalletInput).internalComment,
        },
      })
      .subscribe((result) => {
        if (result.topUpWallet?.id) {
          this.notificationService.success(_('walletPlugin.success.topUp'));
          this.onSuccess.emit(result.topUpWallet as Wallet);
          this.walletForm.reset();
        } else {
          this.notificationService.error(_('walletPlugin.error.topUp'));
        }
      });
  }
}
