import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AppShellComponent, AuthGuard } from '@vendure/admin-ui/core';

import { KsRoute } from '@shared/models/KsRoutes';

const routes: KsRoute[] = [
  {
    path: 'login',
    loadChildren: async () => import('./plugins/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: AppShellComponent,
    data: {
      breadcrumb: _('breadcrumb.dashboard'),
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: async () => import('@vendure/admin-ui/dashboard').then((m) => m.DashboardModule),
      },
      {
        path: 'catalog',
        loadChildren: async () => import('@vendure/admin-ui/catalog').then((m) => m.CatalogModule),
      },
      {
        path: 'customer',
        loadChildren: async () => import('@vendure/admin-ui/customer').then((m) => m.CustomerModule),
      },
      {
        path: 'orders',
        loadChildren: async () => import('@vendure/admin-ui/order').then((m) => m.OrderModule),
      },
      {
        path: 'marketing',
        loadChildren: async () => import('@vendure/admin-ui/marketing').then((m) => m.MarketingModule),
      },
      {
        path: 'settings',
        loadChildren: async () => import('@vendure/admin-ui/settings').then((m) => m.SettingsModule),
      },
      {
        path: 'system',
        loadChildren: async () => import('@vendure/admin-ui/system').then((m) => m.SystemModule),
      },
      {
        path: 'extensions',
        loadChildren: async () => import('./plugins/plugins.module').then((m) => m.PluginsModule),
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
