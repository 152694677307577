import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { BASE_PRODUCER, EXTENDED_PRODUCER } from '@shared/graphql-fragments';

export const CREATE_PRODUCER: DocumentNode = gql`
  mutation CreateProducer($input: CreateProducerInput!) {
    createProducer(input: $input) {
      id
    }
  }
`;

export const GET_PRODUCER: DocumentNode = gql`
  ${EXTENDED_PRODUCER}
  query GetProducer($id: ID!) {
    producer(id: $id) {
      ...ExtendedProducer
    }
  }
`;

export const GET_PRODUCERS: DocumentNode = gql`
  ${BASE_PRODUCER}
  query GetProducers($options: ProducerListOptions) {
    producers(options: $options) {
      items {
        ...BaseProducer
      }
      totalItems
    }
  }
`;

export const UPDATE_PRODUCER: DocumentNode = gql`
  ${EXTENDED_PRODUCER}
  mutation UpdateProducer($input: UpdateProducerInput!) {
    updateProducer(input: $input) {
      ...ExtendedProducer
    }
  }
`;

export const DELETE_PRODUCER: DocumentNode = gql`
  mutation DeleteProducer($id: ID!) {
    deleteProducer(id: $id) {
      result
    }
  }
`;

export const ASSIGN_PRODUCER_TO_CHANNEL: DocumentNode = gql`
  ${EXTENDED_PRODUCER}
  mutation AssignProducerToChannel($input: AssignProducerToChannelInput!) {
    assignProducerToChannel(input: $input) {
      ...ExtendedProducer
    }
  }
`;

export const REMOVE_PRODUCER_FROM_CHANNEL: DocumentNode = gql`
  ${EXTENDED_PRODUCER}
  mutation RemoveProducerFromChannel($input: RemoveProducerFromChannelInput!) {
    removeProducerFromChannel(input: $input) {
      ...ExtendedProducer
    }
  }
`;
