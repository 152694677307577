import { NgModule } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CatalogModule } from '@vendure/admin-ui/catalog';
import { NavMenuItem, SharedModule } from '@vendure/admin-ui/core';

import { PAGE_URLS } from '@navUrls';
import { KsSharedModule } from '@shared/shared.module';
import { ProducerDetailComponent } from './components/producer-detail/producer-detail.component';
import { ProducerListComponent } from './components/producer-list/producer-list.component';
import { ProducerRoutingModule } from './producer.routing';
import { ProducerDetailResolver } from './producer-details.resolver';

export const PRODUCER_NAV_ITEM: NavMenuItem = {
  id: 'producer',
  label: _('producerPlugin.navItem'),
  routerLink: PAGE_URLS.producers.getUrlSegments(),
  icon: 'store',
};

@NgModule({
  imports: [CatalogModule, SharedModule, ProducerRoutingModule, KsSharedModule],
  declarations: [ProducerListComponent, ProducerDetailComponent],
  providers: [ProducerDetailResolver],
})
export class ProducerModule {}
