import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomDetailComponent, DataService, ModalService } from '@vendure/admin-ui/core';
import { Customer } from '@vendure/core';
import { Observable } from 'rxjs';

import { ChannelService } from '@services';
import { GET_WALLET } from '@shared/components/customer-wallet-top-up/customer-wallet-top-up.graphql';
import { CurrencyCode, Permission, Wallet, WalletQuery, WalletQueryVariables } from '@shared/types/generated-ui-types';
import { CustomerWalletDetailsComponent } from '../customer-wallet-details/customer-wallet-details.component';

@UntilDestroy()
@Component({
  selector: 'app-customer-wallet',
  styleUrls: ['./customer-wallet.component.scss'],
  templateUrl: './customer-wallet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerWalletComponent implements CustomDetailComponent, OnInit {
  public entity$: Observable<Customer>;
  public detailForm: FormGroup;

  public wallet: Wallet;

  public currencyCode: CurrencyCode | undefined;

  // REFERENCES
  public Permission: typeof Permission = Permission;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly channelService: ChannelService,
    private readonly dataService: DataService,
    private readonly modalService: ModalService
  ) {}

  public ngOnInit(): void {
    this.entity$.subscribe((customer) => {
      this.dataService
        .query<WalletQuery, WalletQueryVariables>(GET_WALLET, {
          customerId: String(customer.id),
        })
        .mapSingle((data) => data.walletByCustomerId as Wallet)
        .subscribe((wallet) => (this.wallet = wallet));
    });

    this.channelService.currentChannel$.pipe(untilDestroyed(this)).subscribe((channel) => {
      this.currencyCode = channel?.currencyCode;
      this.changeDetectorRef.markForCheck();
    });
  }

  public setWallet(updatedWallet: Wallet): void {
    this.wallet = updatedWallet;
  }

  public openDetails(): void {
    this.modalService
      .fromComponent(CustomerWalletDetailsComponent, {
        closable: true,
        size: 'md',
        locals: {
          customerId: this.wallet.customerId,
          currencyCode: this.currencyCode,
        },
      })
      .subscribe();
  }
}
