import { NgModule } from '@angular/core';
import { registerCustomDetailComponent, SharedModule } from '@vendure/admin-ui/core';

import { KsSharedModule } from '@shared/shared.module';
import { CustomerWalletComponent } from './components/customer-wallet/customer-wallet.component';
import { CustomerWalletDeductComponent } from './components/customer-wallet-deduct/customer-wallet-deduct.component';
import { CustomerWalletDetailsComponent } from './components/customer-wallet-details/customer-wallet-details.component';
import { CustomerWalletItemComponent } from './components/customer-wallet-item/customer-wallet-item.component';

@NgModule({
  imports: [KsSharedModule, SharedModule],
  declarations: [
    CustomerWalletComponent,
    CustomerWalletDeductComponent,
    CustomerWalletDetailsComponent,
    CustomerWalletItemComponent,
  ],
  providers: [registerCustomDetailComponent({ component: CustomerWalletComponent, locationId: 'customer-detail' })],
})
export class WalletModule {}
