<form [formGroup]="filterFormGroup">
  <vdr-action-bar>
    <vdr-ab-left>
      <div class="search-bar">
        <input
          class="clr-input search-input"
          type="text"
          name="searchTerm"
          formControlName="searchTerm"
          [placeholder]="'catalog.filter-by-name' | translate"
        />

        <ng-select
          bindLabel="label"
          bindValue="value"
          formControlName="status"
          [placeholder]="'specialPlugin.moreFilters' | translate"
          [items]="statuses"
          [multiple]="true"
          [closeOnSelect]="false"
        >
        </ng-select>
      </div>
    </vdr-ab-left>
    <vdr-ab-right>
      <vdr-action-bar-items locationId="special-list"></vdr-action-bar-items>
      <a
        *vdrIfPermissions="createSpecialPermissions"
        class="btn btn-primary"
        [routerLink]="SPECIAL_URLS.create.staticUrlAbsolute"
      >
        <clr-icon shape="plus"></clr-icon>
        {{ 'specialPlugin.newSpecial' | translate }}
      </a>
    </vdr-ab-right>
  </vdr-action-bar>
</form>

<vdr-data-table
  *varRef="editSpecialPermissions | hasPermission as hasEditPermissions"
  [items]="items$ | async"
  [itemsPerPage]="itemsPerPage$ | async"
  [totalItems]="totalItems$ | async"
  [currentPage]="currentPage$ | async"
  (pageChange)="setPageNumber($event)"
  (itemsPerPageChange)="setItemsPerPage($event)"
>
  <vdr-dt-column>{{ 'specialPlugin.title' | translate }}</vdr-dt-column>
  <vdr-dt-column>{{ 'specialPlugin.sortOrder' | translate }}</vdr-dt-column>
  <vdr-dt-column>{{ 'specialPlugin.status' | translate }}</vdr-dt-column>
  <vdr-dt-column></vdr-dt-column>
  <vdr-dt-column></vdr-dt-column>

  <ng-template let-special="item">
    <td class="left align-middle">{{ special.title }}</td>
    <td class="left align-middle">{{ special.sortOrder }}</td>
    <td class="left align-middle">
      {{ 'common.' + (special.status === Status.ACTIVE ? 'enabled' : 'disabled') | translate }}
    </td>

    <td class="right align-middle">
      <vdr-table-row-action
        [iconShape]="hasEditPermissions ? 'edit' : 'eye'"
        [label]="(hasEditPermissions ? 'common.edit' : 'common.view') | translate"
        [linkTo]="[PAGE_URLS.specials.staticUrlAbsolute, special.id]"
      ></vdr-table-row-action>
    </td>

    <td class="right align-middle">
      <vdr-dropdown>
        <button vdrDropdownTrigger class="btn btn-link btn-sm" type="button">
          {{ 'common.actions' | translate }}
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <vdr-dropdown-menu vdrPosition="bottom-right">
          <button
            vdrDropdownItem
            class="delete-button"
            type="button"
            [disabled]="!(deleteSpecialPermissions | hasPermission)"
            (click)="delete(special.id)"
          >
            <clr-icon shape="trash" class="is-danger"></clr-icon>
            {{ 'common.delete' | translate }}
          </button>
        </vdr-dropdown-menu>
      </vdr-dropdown>
    </td>
  </ng-template>
</vdr-data-table>
