<div *ngIf="(shippingMethodId$ | async) !== 'create'">
  <div *varRef="isNew$ | async as isNew" class="card delivery-date-card" [formGroup]="deliveryDateForm">
    <section>
      <span>{{ 'shippingMethodPlugin.deliveryWeekday' | translate }}</span>
      <clr-select-container>
        <select clrSelect class="delivery-date-card__element" id="deliveryWeekday" formControlName="deliveryWeekday">
          <option *ngFor="let weekday of deliveryWeekdays" [value]="weekday">
            {{ weekday }}
          </option>
        </select>
      </clr-select-container>
    </section>

    <section>
      <span>
        {{ 'shippingMethodPlugin.earliestDeliveryTime' | translate }}
      </span>
      <input
        class="delivery-date-card__element"
        id="earliestDeliveryTime"
        type="time"
        formControlName="earliestDeliveryTime"
        [readonly]="!(updatePermission | hasPermission)"
      />
    </section>

    <section>
      <span>{{ 'shippingMethodPlugin.latestDeliveryTime' | translate }}</span>
      <input
        class="delivery-date-card__element"
        id="latestDeliveryTime"
        type="time"
        formControlName="latestDeliveryTime"
        [readonly]="!(updatePermission | hasPermission)"
      />
    </section>

    <section>
      <span>{{ 'shippingMethodPlugin.orderByDay' | translate }}</span>
      <clr-select-container>
        <select clrSelect class="delivery-date-card__element" id="orderByDay" formControlName="orderByDay">
          <option *ngFor="let weekday of deliveryWeekdays" [value]="weekday">
            {{ weekday }}
          </option>
        </select>
      </clr-select-container>
    </section>

    <section>
      <span>{{ 'shippingMethodPlugin.orderByTime' | translate }}</span>
      <input
        class="delivery-date-card__element"
        id="orderByTime"
        type="time"
        formControlName="orderByTime"
        [readonly]="!(updatePermission | hasPermission)"
      />
    </section>

    <section>
      <span>{{ 'shippingMethodPlugin.maxCapacity' | translate }}</span>
      <input
        class="delivery-date-card__element"
        id="maxCapacity"
        type="number"
        step="1"
        formControlName="maxCapacity"
        [readonly]="!(updatePermission | hasPermission)"
      />
    </section>

    <button
      *vdrIfPermissions="updatePermission"
      class="btn btn-primary delivery-date-card__button"
      [disabled]="deliveryDateForm.invalid || deliveryDateForm.pristine"
      (click)="saveDeliveryDate()"
    >
      {{ (isNew ? 'common.create' : 'common.update') | translate }}
    </button>
  </div>
</div>
