<ng-container *varRef="invoice$ | async as invoice">
  <ng-container *ngIf="invoice">
    <ng-container *vdrIfPermissions="downloadPermissions">
      <h4>{{ 'orderExtensionPlugin.invoice' | translate }}</h4>

      <a [href]="invoice.downloadUrl" target="_blank">
        <button class="btn mt4">
          <clr-icon class="mr2" shape="download"></clr-icon>{{ 'orderExtensionPlugin.downloadInvoice' | translate }}
        </button>
      </a>
    </ng-container>
  </ng-container>
</ng-container>
