<div class="wallet-detail-item">
  <div [ngSwitch]="walletItem.activity" class="wallet-detail-item__activity">
    <h3 *ngSwitchCase="WalletActivity.GIFT_COUPON_CREDIT" class="wallet-detail-item--positive">
      {{ 'walletPlugin.activityValue.giftCouponTopUp' | translate }}
    </h3>
    <h3 *ngSwitchCase="WalletActivity.ORDER_PAYMENT" class="wallet-detail-item--negative">
      {{ 'walletPlugin.activityValue.orderPayment' | translate }}
    </h3>
    <h3 *ngSwitchCase="WalletActivity.SUPPORT_CREDIT" class="wallet-detail-item--positive">
      {{ 'walletPlugin.activityValue.supportCredit' | translate }}
    </h3>
    <h3 *ngSwitchCase="WalletActivity.DEPOSIT_REFUND" class="wallet-detail-item--positive">
      {{ 'walletPlugin.activityValue.depositRefund' | translate }}
    </h3>
    <h3 *ngSwitchCase="WalletActivity.ORDER_GOODWILL" class="wallet-detail-item--positive">
      {{ 'walletPlugin.activityValue.orderGoodwill' | translate }}
    </h3>
    <h3 *ngSwitchCase="WalletActivity.SUPPORT_DEBIT" class="wallet-detail-item--negative">
      {{ 'walletPlugin.activityValue.supportDebit' | translate }}
    </h3>
  </div>
  <p>{{ 'walletPlugin.createdAt' | translate }} {{ walletItem.createdAt | date }}</p>
  <form [formGroup]="form" (ngSubmit)="updateWalletItem()">
    <vdr-form-field
      for="description"
      [label]="'walletPlugin.description' | translate"
      [readOnlyToggle]="walletItem.activity === WalletActivity.SUPPORT_DEBIT || WalletActivity.SUPPORT_CREDIT"
    >
      <textarea name="description" formControlName="description"></textarea>
    </vdr-form-field>

    <vdr-form-field for="comment" [label]="'walletPlugin.internalComment' | translate" [readOnlyToggle]="true">
      <textarea name="comment" formControlName="comment"></textarea>
    </vdr-form-field>

    <div class="wallet-detail-item__container">
      <p
        *ngIf="
          walletItem.activity === WalletActivity.GIFT_COUPON_CREDIT ||
          walletItem.activity === WalletActivity.SUPPORT_CREDIT ||
          walletItem.activity === WalletActivity.DEPOSIT_REFUND ||
          walletItem.activity === WalletActivity.ORDER_GOODWILL
        "
        class="wallet-detail-item__total wallet-detail-item--positive"
      >
        {{ walletItem.total | localeCurrency: currencyCode }}
      </p>
      <p
        *ngIf="
          walletItem.activity === WalletActivity.ORDER_PAYMENT || walletItem.activity === WalletActivity.SUPPORT_DEBIT
        "
        class="wallet-detail-item__total wallet-detail-item--negative"
      >
        - {{ walletItem.total | localeCurrency: currencyCode }}
      </p>

      <button class="btn btn-primary" [disabled]="form.invalid || form.untouched || form.pristine">
        {{ 'common.update' | translate }}
      </button>
    </div>
  </form>
</div>
