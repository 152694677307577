import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  DataService,
  FormInputComponent,
  getDefaultUiLanguage,
  InputComponentConfig,
  LocalStorageService,
  NotificationService,
} from '@vendure/admin-ui/core';
import { Info } from 'luxon';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, switchMap, take, withLatestFrom } from 'rxjs/operators';

import {
  CreateDeliveryDate,
  CreateDeliveryDateInput,
  DeliveryDate,
  Permission,
  UpdateDeliveryDate,
  UpdateDeliveryDateInput,
} from '@shared/types/generated-ui-types';
import { CREATE_DELIVERY_DATE, UPDATE_DELIVERY_DATE } from '../../shipping-method-extension.graphql';

interface Params {
  id: string;
}

@UntilDestroy()
@Component({
  selector: 'app-delivery-date-card',
  templateUrl: './delivery-date-card.component.html',
  styleUrls: ['./delivery-date-card.component.scss'],
})
export class DeliveryDateCardComponent implements FormInputComponent, OnInit {
  public readonly: boolean;
  public formControl: FormControl;
  public config: InputComponentConfig;

  public isNew$: Observable<boolean>;

  public shippingMethodId$: Observable<string>;

  public deliveryDateForm: FormGroup = new FormGroup({
    deliveryWeekday: new FormControl('', (control) => Validators.required(control)),
    earliestDeliveryTime: new FormControl('', (control) => Validators.required(control)),
    latestDeliveryTime: new FormControl('', (control) => Validators.required(control)),
    orderByDay: new FormControl('', (control) => Validators.required(control)),
    orderByTime: new FormControl('', (control) => Validators.required(control)),
    maxCapacity: new FormControl(''),
  });

  public deliveryWeekdays: string[];

  public updatePermission: Permission[] = [Permission.UpdateShippingMethod];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dataService: DataService,
    private readonly localStorageService: LocalStorageService,
    private readonly notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.shippingMethodId$ = this.activatedRoute.params.pipe(map((params) => (params as Params)?.id));
    this.isNew$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value as DeliveryDate),
      map((deliveryDate: DeliveryDate) => !deliveryDate?.id)
    );

    const currentLanguage: string = this.localStorageService.get('uiLanguageCode') ?? getDefaultUiLanguage();
    this.deliveryWeekdays = Info.weekdays('long', { locale: currentLanguage });

    this.setFormValues();

    this.deliveryDateForm.valueChanges.pipe(untilDestroyed(this)).subscribe((changes: DeliveryDate) => {
      // Find correct index of weekdays and add 1 since this represents the ISO Form (Monday = 1, Sunday = 7)
      changes.deliveryWeekday = this.deliveryWeekdays.findIndex((day) => day === `${changes.deliveryWeekday}`) + 1;
      changes.orderByDay = this.deliveryWeekdays.findIndex((day) => day === `${changes.orderByDay}`) + 1;

      this.formControl.patchValue({ ...this.formControl.value, ...changes });
    });
  }

  private setFormValues(): void {
    if (!this.formControl?.value) {
      return;
    }
    this.deliveryDateForm.patchValue({
      ...this.formControl.value,
      deliveryWeekday: this.deliveryWeekdays?.[(this.formControl.value as DeliveryDate).deliveryWeekday - 1],
      orderByDay: this.deliveryWeekdays?.[(this.formControl.value as DeliveryDate).orderByDay - 1],
    });
  }

  public saveDeliveryDate(): void {
    if (this.deliveryDateForm.invalid) {
      return;
    }

    // Delete orderByTimeDate since it cannot be updated
    delete (this.formControl.value as DeliveryDate).orderByTimeDate;
    delete (this.formControl.value as DeliveryDate).hasCapacity;

    combineLatest([this.isNew$, this.shippingMethodId$])
      .pipe(
        take(1),
        switchMap(([isNew, shippingMethodId]) => {
          if (isNew) {
            return this.dataService.mutate<CreateDeliveryDate.Mutation, CreateDeliveryDate.Variables>(
              CREATE_DELIVERY_DATE,
              {
                input: { ...(this.formControl.value as CreateDeliveryDateInput), shippingMethodId },
              }
            );
          } else {
            return this.dataService.mutate<UpdateDeliveryDate.Mutation, UpdateDeliveryDate.Variables>(
              UPDATE_DELIVERY_DATE,
              {
                input: this.formControl.value as UpdateDeliveryDateInput,
              }
            );
          }
        }),
        withLatestFrom(this.isNew$)
      )
      .subscribe(
        ([response, isNew]) => {
          if (isNew) {
            const { createDeliveryDate: result } = response as CreateDeliveryDate.Mutation;
            this.formControl.patchValue(result);

            this.notificationService.success('common.notify-create-success', {
              entity: _('shipping-method-plugin.deliveryDate.1'),
            });
          } else {
            this.notificationService.success('common.notify-update-success', {
              entity: _('shipping-method-plugin.deliveryDate.1'),
            });
          }
          this.deliveryDateForm.markAsPristine();
          this.formControl.markAsPristine();

          this.changeDetector.markForCheck();
        },
        () => {
          this.isNew$.pipe(take(1)).subscribe((isNew) => {
            this.notificationService.error(isNew ? 'common.notify-create-error' : 'common.notify-update-error', {
              entity: _('shipping-method-plugin.deliveryDate.1'),
            });
          });
        }
      );
  }
}
