import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@vendure/admin-ui/core';

import { FeatureAssetComponent } from './feature-asset.component';

@NgModule({
  declarations: [FeatureAssetComponent],
  imports: [CommonModule, SharedModule],
  exports: [FeatureAssetComponent],
})
export class FeatureAssetModule {}
