import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const AUTOCOMPLETE_ADDRESS: DocumentNode = gql`
  query AutoCompleteAddress($input: String!) {
    autoComplete(input: $input) {
      isInArea
      address {
        street
        streetNumber
        postalCode
        city
        province
        country
        countryCode
        name
        latitude
        longitude
        placeId
      }
    }
  }
`;

export const CREATE_ADDRESS: DocumentNode = gql`
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      id
    }
  }
`;

export const UPDATE_ADDRESS: DocumentNode = gql`
  mutation UpdateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      id
    }
  }
`;
