import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CanDeactivateDetailGuard } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PAGE_URLS } from '@navUrls';
import { KsBreadcrumb, KsRoute } from '@shared/models/KsRoutes';
import { GetRecipe } from '@shared/types/generated-ui-types';
import { RecipeDetailComponent } from './components/recipe-detail/recipe-detail.component';
import { RecipeListComponent } from './components/recipe-list/recipe-list.component';
import { RecipeDetailResolver } from './recipe-details.resolver';

const routes: KsRoute[] = [
  {
    path: 'recipes',
    pathMatch: 'full',
    component: RecipeListComponent,
    data: {
      breadcrumb: [
        {
          label: _('recipePlugin.navItem'),
          link: PAGE_URLS.recipes.getUrlSegments(),
        },
      ],
    },
  },
  {
    path: 'recipes/:id',
    component: RecipeDetailComponent,
    resolve: { entity: RecipeDetailResolver },
    canDeactivate: [CanDeactivateDetailGuard],
    data: { breadcrumb: recipeDetailBreadcrumb },
  },
];

/**
 * Returns the correct breadcrumb depending on url
 * @param resolved Resolve object
 * @param resolved.entity Current recipe as observable
 */
export function recipeDetailBreadcrumb(resolved: { entity: Observable<GetRecipe.Recipe> }): Observable<KsBreadcrumb[]> {
  return resolved.entity.pipe(
    map((entity) => [
      {
        label: _('recipePlugin.navItem'),
        link: PAGE_URLS.recipes.getUrlSegments(),
      },
      {
        label: entity.name,
        link: [],
      },
    ])
  );
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RecipeRoutingModule {}
