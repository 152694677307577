<div *ngIf="displayCheckbox">
  <div *vdrIfPermissions="['UpdateOrder']">
    <clr-checkbox-wrapper [class.disabled]="readonly">
      <input type="checkbox" clrCheckbox [formControl]="formControl" [attr.disabled]="readonly" />
    </clr-checkbox-wrapper>
  </div>
</div>

<div *ngIf="!displayCheckbox">
  {{ 'orderExtensionPlugin.notPaidWithInvoice' | translate }}
</div>
