import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DataService } from '@vendure/admin-ui/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';

import { OrdersByDate } from '@shared/types/generated-ui-types';
import { GET_ORDERS_BY_DATE } from '../widgets.graphql';

@UntilDestroy()
@Component({
  selector: 'app-todays-orders-widget',
  styleUrls: ['./todays-orders-widget.component.scss'],
  templateUrl: './todays-orders-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodaysOrdersWidgetComponent implements OnInit {
  public todaysOrders$: Observable<OrdersByDate.Items[]>;

  public today: DateTime = DateTime.now();
  public tomorrow: DateTime = DateTime.now().plus({ days: 1 });

  public selection$: BehaviorSubject<{ timeFrame: string; date: DateTime }> = new BehaviorSubject<{
    timeFrame: string;
    date: DateTime;
  }>({
    timeFrame: 'today',
    date: DateTime.now(),
  });

  constructor(private readonly dataService: DataService) {}

  public ngOnInit(): void {
    this.selection$.subscribe((selection) => {
      this.todaysOrders$ = this.dataService
        .query<OrdersByDate.Query, OrdersByDate.Variables>(GET_ORDERS_BY_DATE, {
          input: { fromDate: selection.date.toString(), toDate: selection.date.toString() },
        })
        .mapStream((data) => data.ordersByDate.items);
    });
  }
}
