import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CanDeactivateDetailGuard } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PAGE_URLS } from '@navUrls';
import { KsBreadcrumb, KsRoute } from '@shared/models/KsRoutes';
import { GetSpecial } from '@shared/types/generated-ui-types';
import { SpecialDetailComponent } from './components/special-detail/special-detail.component';
import { SpecialListComponent } from './components/special-list/special-list.component';
import { SpecialDetailResolver } from './special-details.resolver';

const routes: KsRoute[] = [
  {
    path: 'specials',
    pathMatch: 'full',
    component: SpecialListComponent,
    data: {
      breadcrumb: [
        {
          label: _('specialPlugin.navItem'),
          link: PAGE_URLS.specials.getUrlSegments(),
        },
      ],
    },
  },
  {
    path: 'specials/:id',
    component: SpecialDetailComponent,
    resolve: { entity: SpecialDetailResolver },
    canDeactivate: [CanDeactivateDetailGuard],
    data: { breadcrumb: specialDetailBreadcrumb },
  },
];

/**
 * Returns the correct breadcrumb depending on url
 * @param resolved Resolve object
 * @param resolved.entity Current special as observable
 */
export function specialDetailBreadcrumb(resolved: {
  entity: Observable<GetSpecial.Special>;
}): Observable<KsBreadcrumb[]> {
  return resolved.entity.pipe(
    map((entity) => [
      {
        label: _('specialPlugin.navItem'),
        link: PAGE_URLS.specials.getUrlSegments(),
      },
      {
        label: entity.title ?? entity.id,
        link: [],
      },
    ])
  );
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SpecialRoutingModule {}
