import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { BASE_SPECIAL, EXTENDED_SPECIAL } from '@shared/graphql-fragments';

export const CREATE_SPECIAL: DocumentNode = gql`
  mutation CreateSpecial($input: CreateSpecialInput!) {
    createSpecial(input: $input) {
      id
    }
  }
`;

export const GET_SPECIALS: DocumentNode = gql`
  ${BASE_SPECIAL}
  query GetSpecials($options: SpecialListOptions) {
    specials(options: $options) {
      items {
        ...BaseSpecial
      }
      totalItems
    }
  }
`;

export const GET_SPECIAL: DocumentNode = gql`
  ${EXTENDED_SPECIAL}
  query GetSpecial($id: ID!) {
    special(id: $id) {
      ...ExtendedSpecial
    }
  }
`;

export const UPDATE_SPECIAL: DocumentNode = gql`
  ${EXTENDED_SPECIAL}
  mutation UpdateSpecial($input: UpdateSpecialInput!) {
    updateSpecial(input: $input) {
      ...ExtendedSpecial
    }
  }
`;

export const ASSIGN_SPECIAL_TO_CHANNEL: DocumentNode = gql`
  ${EXTENDED_SPECIAL}
  mutation AssignSpecialToChannel($input: AssignSpecialToChannelInput!) {
    assignSpecialToChannel(input: $input) {
      ...ExtendedSpecial
    }
  }
`;

export const REMOVE_SPECIAL_FROM_CHANNEL: DocumentNode = gql`
  ${EXTENDED_SPECIAL}
  mutation RemoveSpecialFromChannel($input: RemoveSpecialFromChannelInput!) {
    removeSpecialFromChannel(input: $input) {
      ...ExtendedSpecial
    }
  }
`;

export const DELETE_SPECIAL: DocumentNode = gql`
  mutation DeleteSpecial($id: ID!) {
    deleteSpecial(id: $id) {
      result
    }
  }
`;
