import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomDetailComponent, DataService } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';

import { PermissionService } from '@services';
import {
  GetInvoiceOfOrderQuery,
  GetInvoiceOfOrderQueryVariables,
  Invoice,
  Order,
  Permission,
} from '@shared/types/generated-ui-types';
import { GET_INVOICE_OF_ORDER } from '../order-extension.graphql';

@Component({
  selector: 'app-order-invoice-download',
  templateUrl: './order-invoice-download.component.html',
})
export class OrderInvoiceDownloadComponent implements CustomDetailComponent, OnInit {
  // These two properties are provided by Vendure and will vary
  // depending on the particular detail page you are embedding this
  // component into.
  public entity$: Observable<Order>;
  public detailForm: FormGroup;

  public invoice$: Observable<Invoice | undefined>;

  public readonly downloadPermissions: Permission[] = [Permission.AllowInvoicesPermission];

  constructor(private readonly dataService: DataService, private readonly permissionService: PermissionService) {}

  public ngOnInit(): void {
    this.permissionService.permissionsHaveChanged$.subscribe(() => {
      if (this.permissionService.checkPermissions(this.downloadPermissions)) {
        this.entity$.subscribe((order) => {
          this.invoice$ = this.dataService
            .query<GetInvoiceOfOrderQuery, GetInvoiceOfOrderQueryVariables>(GET_INVOICE_OF_ORDER, { orderId: order.id })
            .mapSingle((invoice) => invoice.invoiceOfOrder as Invoice);
        });
      }
    });
  }
}
