<div class="card">
  <div class="card-header">
    <div class="title">{{ 'giftCouponPlugin.redeemGiftCouponCode' | translate }}</div>
  </div>
  <div class="card-block">
    <form [formGroup]="giftCouponForm" (ngSubmit)="redeemCode()">
      <section class="form-block">
        <vdr-form-field for="code" [label]="'giftCouponPlugin.giftCouponCode.label' | translate: { amount: 1 }">
          <input id="code" type="number" formControlName="code" />
        </vdr-form-field>
      </section>

      <section class="form-block">
        <vdr-form-field for="redeemComment" [label]="'giftCouponPlugin.comment' | translate">
          <input id="redeemComment" type="text" formControlName="comment" />
        </vdr-form-field>
      </section>

      <button class="btn btn-primary">
        {{ 'giftCouponPlugin.redeemGiftCoupon.label' | translate }}
      </button>
    </form>
  </div>
</div>
