import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { BASE_ASSET } from './assets.fragment';
import { BASE_CHANNEL } from './channel.fragment';
import { BASE_PRODUCT_VARIANT } from './productVariants.fragment';

export const BASE_SPECIAL: DocumentNode = gql`
  fragment BaseSpecial on Special {
    id
    status
    title
    category
    description
    sortOrder
    internalLink
    externalLink
  }
`;

export const EXTENDED_SPECIAL: DocumentNode = gql`
  ${BASE_SPECIAL}
  ${BASE_CHANNEL}
  ${BASE_ASSET}
  ${BASE_PRODUCT_VARIANT}
  fragment ExtendedSpecial on Special {
    ...BaseSpecial
    channels {
      ...BaseChannel
    }
    featuredAsset {
      ...BaseAsset
    }
    productVariants {
      ...BaseProductVariant
    }
  }
`;
