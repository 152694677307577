import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService, NotificationService } from '@vendure/admin-ui/core';

import {
  DeliveryDate,
  UpdateDeliveryDateMutation,
  UpdateDeliveryDateMutationVariables,
} from '@shared/types/generated-ui-types';
import { UPDATE_DELIVERY_DATE } from '../../../shipping-method-extension/shipping-method-extension.graphql';

interface MatrixConfig {
  days: number[];
  hours: (string | number)[];
  capacityData: string[][];
}

@Component({
  selector: 'app-capacity-matrix',
  styleUrls: ['capacity-matrix.component.scss'],
  templateUrl: './capacity-matrix.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CapacityMatrixComponent implements OnChanges {
  @Input()
  public deliveryDates: DeliveryDate[] = [];

  @Output()
  public updateSucceeded: EventEmitter<void> = new EventEmitter<void>();

  public matrixData: MatrixConfig = {
    days: Array.from({ length: 6 }, (unused, i) => i + 1),
    hours: [0, ...Array.from({ length: 12 }, (unused, i) => `${i + 8 < 10 ? '0' : ''}${i + 8}:00 - ${i + 9}:00`)],
    capacityData: [],
  };

  public form: FormGroup = new FormGroup({});

  public isLoading: boolean = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dataService: DataService,
    private readonly notificationService: NotificationService
  ) {}

  public ngOnChanges(): void {
    this.setMatrix();
  }

  public setMatrix(): void {
    if (!this.deliveryDates || this.deliveryDates.length === 0) {
      return;
    }

    for (const hour of this.matrixData.hours) {
      if (!hour) {
        continue;
      }

      const dayArray: string[] = [];
      for (const day of this.matrixData.days) {
        const deliveryDatesOfDay: DeliveryDate[] = this.deliveryDates.filter(
          (deliveryDate) => deliveryDate.deliveryWeekday === day
        );

        const earliestTime: string = `${String(hour).split(':')[0]}:00`;
        const deliveryDateOfHour: DeliveryDate | undefined = deliveryDatesOfDay.find(
          (date) => date.earliestDeliveryTime === earliestTime
        );

        const key: string = `${deliveryDateOfHour?.id ?? '-'}`;

        dayArray.push(key);

        const deliveryDateControl: FormControl = new FormControl(
          { value: deliveryDateOfHour ? deliveryDateOfHour.maxCapacity : '-', disabled: !deliveryDateOfHour },
          [
            (control) => {
              if (!deliveryDateOfHour) return null;
              return Validators.required(control), Validators.min(0);
            },
          ]
        );

        this.form.addControl(key, deliveryDateControl);
      }
      this.matrixData.capacityData.push(dayArray);
    }

    this.changeDetectorRef.markForCheck();
  }

  public async save(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;

    const dirtyKeys: string[] = [];

    Object.keys(this.form.controls).forEach((key) => {
      const currentControl: AbstractControl = this.form.controls[key];

      if (currentControl.dirty) {
        dirtyKeys.push(key);
      }
    });

    for (const key of dirtyKeys) {
      const deliveryDate: DeliveryDate | undefined = this.deliveryDates.find((date) => date.id === key);

      if (!deliveryDate) {
        continue;
      }

      const response: UpdateDeliveryDateMutation = await this.dataService
        .mutate<UpdateDeliveryDateMutation, UpdateDeliveryDateMutationVariables>(UPDATE_DELIVERY_DATE, {
          input: {
            id: deliveryDate.id,
            maxCapacity: Number(this.form.controls[key].value),
          },
        })
        .toPromise();

      if (!response.updateDeliveryDate.id) {
        this.notificationService.error('common.notify-update-error', {
          entity: 'capacityPlugin.navItem',
        });
      }
    }

    this.updateSucceeded.emit();
    this.notificationService.success('common.notify-update-success', {
      entity: 'capacityPlugin.navItem',
    });
    this.isLoading = false;
    this.form.markAsTouched();
    this.form.markAsPristine();
    this.changeDetectorRef.markForCheck();
  }
}
