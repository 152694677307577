import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Dialog } from '@vendure/admin-ui/core';

@Component({
  selector: 'app-display-gift-coupon-code',
  templateUrl: './display-gift-coupon-code.component.html',
  styleUrls: ['./display-gift-coupon-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayGiftCouponCodeComponent implements OnInit, Dialog<void> {
  public giftCouponCode: string;

  public resolveWith: () => void;

  public clipboardPermissionGranted: boolean = false;
  public copyingStatus: 'success' | 'error' | null = null;
  public codeCopied: boolean = false;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  public async ngOnInit(): Promise<void> {
    const result: PermissionStatus = await navigator.permissions.query({ name: 'clipboard-write' });
    this.clipboardPermissionGranted = result.state === 'granted' || result.state === 'prompt';
  }

  public async copyGiftCouponCode(): Promise<void> {
    if (!this.clipboardPermissionGranted || this.copyingStatus === 'success') {
      return;
    }

    try {
      await navigator.clipboard.writeText(this.giftCouponCode);
      this.copyingStatus = 'success';
      this.codeCopied = true;

      window.setTimeout(() => {
        this.copyingStatus = null;
        this.changeDetectorRef.markForCheck();
      }, 3000);
    } catch {
      this.copyingStatus = 'error';
    }
    this.changeDetectorRef.markForCheck();
  }

  public confirm(): void {
    this.resolveWith();
  }
}
