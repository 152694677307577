import { Injectable } from '@angular/core';
import { DataService } from '@vendure/admin-ui/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

import { Channel } from '@shared/types/generated-ui-types';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  public currentChannel$: Observable<Channel | null>;

  constructor(private readonly dataService: DataService) {
    this.currentChannel$ = combineLatest([
      this.dataService.client.userStatus().mapStream(({ userStatus }) => userStatus.activeChannelId as string),
      this.dataService.settings.getChannels().mapStream((data) => (data.channels || []) as unknown as Channel[]),
    ]).pipe(
      map(([activeChannelId, channels]) => {
        return channels.find((c) => c.id === activeChannelId) ?? null;
      }),
      startWith(null),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}
