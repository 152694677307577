<!-- ROUTE PLANNING -->
<app-csv-export
  dialogHeader="exportCentralPlugin.orderRoutingCsv.heading"
  dialogInfo="exportCentralPlugin.orderRoutingCsv.infoModal"
  heading="exportCentralPlugin.orderRoutingCsv.heading"
  info="exportCentralPlugin.orderRoutingCsv.info"
  buttonLabel="exportCentralPlugin.orderRoutingCsv.button"
  errorMessage="exportCentralPlugin.orderRoutingCsv.error"
  downloadUrlPart="routePlanning"
  fileName="kleinstark_routePlanning"
  [displaySelection]="true"
  [selectionOptions]="customerGroups"
  selectedOptionName="customerGroup"
></app-csv-export>

<!-- PAYMENT EXPORT -->
<app-csv-export
  dialogHeader="exportCentralPlugin.payment.heading"
  dialogInfo="exportCentralPlugin.payment.infoModal"
  heading="exportCentralPlugin.payment.heading"
  info="exportCentralPlugin.payment.info"
  buttonLabel="exportCentralPlugin.payment.button"
  errorMessage="exportCentralPlugin.payment.error"
  downloadUrlPart="payment"
  fileName="kleinstark_payment"
></app-csv-export>

<!-- DATEV EXPORT -->
<app-csv-export
  dialogHeader="exportCentralPlugin.datev.heading"
  dialogInfo="exportCentralPlugin.datev.infoModal"
  heading="exportCentralPlugin.datev.heading"
  info="exportCentralPlugin.datev.info"
  buttonLabel="exportCentralPlugin.datev.button"
  errorMessage="exportCentralPlugin.datev.error"
  downloadUrlPart="datev"
  fileName="kleinstark_datev"
></app-csv-export>

<!-- DATEV STRIPE EXPORT -->
<app-csv-export
  dialogHeader="exportCentralPlugin.stripeDatev.heading"
  dialogInfo="exportCentralPlugin.stripeDatev.infoModal"
  heading="exportCentralPlugin.stripeDatev.heading"
  info="exportCentralPlugin.stripeDatev.info"
  buttonLabel="exportCentralPlugin.stripeDatev.button"
  errorMessage="exportCentralPlugin.stripeDatev.error"
  downloadUrlPart="stripeDatev"
  fileName="kleinstark_stripeDatev"
></app-csv-export>

<!-- CUSTOMER NUMBER EXPORT -->
<app-csv-export
  heading="exportCentralPlugin.customerNumber.heading"
  info="exportCentralPlugin.customerNumber.info"
  buttonLabel="exportCentralPlugin.customerNumber.button"
  errorMessage="exportCentralPlugin.customerNumber.error"
  downloadUrlPart="customerNumber"
  fileName="kleinstark_customerNumber"
  [displayDialog]="false"
></app-csv-export>

<!-- CUSTOMER NUMBER EXPORT -->
<app-csv-export
  heading="exportCentralPlugin.giftCoupon.heading"
  info="exportCentralPlugin.giftCoupon.info"
  buttonLabel="exportCentralPlugin.giftCoupon.button"
  errorMessage="giftCoupon.giftCoupon.error"
  downloadUrlPart="giftCoupon"
  fileName="kleinstark_giftCoupon"
  [displayDialog]="false"
></app-csv-export>

<!-- WALLET EXPENSES EXPORT -->
<app-csv-export
  dialogHeader="exportCentralPlugin.walletExpenses.heading"
  dialogInfo="exportCentralPlugin.walletExpenses.infoModal"
  heading="exportCentralPlugin.walletExpenses.heading"
  info="exportCentralPlugin.walletExpenses.info"
  buttonLabel="exportCentralPlugin.walletExpenses.button"
  errorMessage="giftCoupon.walletExpenses.error"
  downloadUrlPart="walletExpenses"
  fileName="kleinstark_wallet_expenses"
  [displaySelection]="true"
  [selectionOptions]="walletActivities"
  selectedOptionName="activity"
></app-csv-export>

<!-- CUSTOMER WALLET  EXPORT -->
<app-csv-export
  heading="exportCentralPlugin.customerWallets.heading"
  info="exportCentralPlugin.customerWallets.info"
  buttonLabel="exportCentralPlugin.customerWallets.button"
  errorMessage="giftCoupon.customerWallets.error"
  downloadUrlPart="customerWallets"
  fileName="kleinstark_customer_wallets"
  [displayDialog]="false"
></app-csv-export>
