<div class="mb4">
  <h1 class="h1">{{ 'capacityPlugin.navItem' | translate }}</h1>
</div>

<clr-tabs>
  <clr-tab *ngFor="let shippingMethod of shippingMethods$ | async">
    <button clrTabLink [id]="shippingMethod.id">{{ shippingMethod.name }}</button>
    <clr-tab-content [id]="shippingMethod.id" *clrIfActive="shippingMethod.id === firstShippingMethod.id">
      <app-capacity-matrix
        [deliveryDates]="shippingMethod.customFields.deliveryDates"
        (updateSucceeded)="loadData()"
      ></app-capacity-matrix>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>
