import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomFieldConfigType, CustomFieldControl } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ChannelService } from '@services';
import { CurrencyCode } from '@shared/types/generated-ui-types';

interface ParentValues {
  priceWithTax: number;
  customFields: {
    originalPrice: number;
  };
}

@UntilDestroy()
@Component({
  selector: 'app-product-variant-discount',
  templateUrl: './product-variant-discount.component.html',
  styleUrls: ['./product-variant-discount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductVariantDiscountComponent implements CustomFieldControl, OnInit {
  public readonly: boolean;
  // Retrieving configs from backend
  public config: CustomFieldConfigType;
  public formControl: FormControl;

  public readonly reductionOptions: number[] = [10, 15, 20, 25, 30];

  private price: number;
  public currencyCode$: Observable<CurrencyCode | undefined>;

  public discount: number;

  private mainForm: FormGroup;

  constructor(private readonly channelService: ChannelService, private readonly changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.mainForm = this.formControl.parent?.parent as FormGroup;

    this.currencyCode$ = this.channelService.currentChannel$.pipe(map((channel) => channel?.currencyCode));

    // Subscribe to the parent form changes and initial data to display the current discount
    this.mainForm?.valueChanges
      .pipe(startWith(this.mainForm?.value as ParentValues), untilDestroyed(this))
      .subscribe((changes: ParentValues) => {
        this.setCurrentDiscount(changes);
      });

    // Get price with text of parents parent which is the main form and includes all information of the product variant
    // This price is needed if discount is set initially using the quick action
    this.price = Number((this.mainForm?.value as ParentValues)?.priceWithTax);
  }

  public setDiscount(percentage: number): void {
    const priceToReduce: number = Number(this.formControl.value || this.price);

    const reduction: number = (priceToReduce * percentage) / 100;

    this.mainForm.controls.priceWithTax.setValue(+(priceToReduce - reduction).toFixed(0));
    this.mainForm?.markAsDirty();

    // Set new value if priceReduction is given and otherwise remove value by setting it to null
    if (percentage) {
      this.formControl.setValue(priceToReduce);
    } else {
      this.price = priceToReduce;
      this.formControl.setValue(null);
    }
  }

  private setCurrentDiscount(values: ParentValues): void {
    const currentPrice: number = values.priceWithTax;
    const originalPrice: number = values.customFields.originalPrice;
    if (!currentPrice || !originalPrice) {
      this.discount = 0;
    } else {
      this.discount = Number((1 - currentPrice / originalPrice).toFixed(2)) * 100;
    }

    this.changeDetectorRef.markForCheck();
  }
}
