import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const CREATE_DELIVERY_DATE: DocumentNode = gql`
  mutation CreateDeliveryDate($input: CreateDeliveryDateInput!) {
    createDeliveryDate(input: $input) {
      __typename
      id
      deliveryWeekday
      earliestDeliveryTime
      latestDeliveryTime
      orderByDay
      orderByTime
      maxCapacity
    }
  }
`;

export const UPDATE_DELIVERY_DATE: DocumentNode = gql`
  mutation UpdateDeliveryDate($input: UpdateDeliveryDateInput!) {
    updateDeliveryDate(input: $input) {
      id
    }
  }
`;
