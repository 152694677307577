import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DataService, ModalService, NotificationService } from '@vendure/admin-ui/core';

import { ActivateGiftCoupons } from '@shared/types/generated-ui-types';
import { ACTIVATE_GIFT_COUPONS } from '../../gift-coupon.graphql';

interface IFormValues {
  multipleCodes: boolean;
  codesFrom: string;
  codesTo: string;
  comment: string;
}

@Component({
  selector: 'app-activate-gift-coupon',
  templateUrl: './activate-gift-coupon.component.html',
  styleUrls: ['./activate-gift-coupon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivateGiftCouponComponent {
  public giftCouponForm: FormGroup = new FormGroup(
    {
      multipleCodes: new FormControl(false),
      codesFrom: new FormControl(''),
      codesTo: new FormControl(''),
      comment: new FormControl('', (control) => Validators.required(control)),
    },
    (control) => ActivateGiftCouponComponent.giftCouponCodeValidator(control)
  );

  constructor(
    private readonly dataService: DataService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly notificationService: NotificationService,
    private readonly modalService: ModalService
  ) {}

  public async activateCodes(): Promise<void> {
    if (this.giftCouponForm.invalid) {
      this.giftCouponForm.markAllAsTouched();
      return;
    }

    const { comment, codesTo, codesFrom, multipleCodes }: IFormValues = this.giftCouponForm.value as IFormValues;

    const confirmation: boolean =
      (await this.modalService
        .dialog({
          title: _('giftCouponPlugin.activateGiftCoupon.label'),
          translationVars: {
            amount: this.getNumberOfCoupons(),
            codesFrom,
            codesTo,
          },
          body: _('giftCouponPlugin.activateGiftCoupon.question'),
          buttons: [
            { type: 'secondary', label: _('common.cancel') },
            { type: 'primary', label: _('giftCouponPlugin.activate'), returnValue: true },
          ],
        })
        .toPromise()) ?? false;

    if (!confirmation) {
      return;
    }

    this.dataService
      .mutate<ActivateGiftCoupons.Mutation, ActivateGiftCoupons.Variables>(ACTIVATE_GIFT_COUPONS, {
        input: {
          comment,
          fromNumber: +codesFrom,
          toNumber: multipleCodes ? +codesTo : +codesFrom,
        },
      })
      .subscribe(
        () => {
          this.notificationService.success('giftCouponPlugin.activateGiftCoupon.success', {
            amount: this.getNumberOfCoupons(),
          });
          this.giftCouponForm.reset();
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.notificationService.error('giftCouponPlugin.error.activationFailed');
        }
      );
  }

  public getNumberOfCoupons(): number | string {
    const { codesTo, codesFrom, multipleCodes }: IFormValues = this.giftCouponForm.value as IFormValues;

    if (!multipleCodes) {
      return 1;
    }

    if (this.giftCouponForm.invalid) {
      return '';
    }

    return +codesTo - +codesFrom + 1;
  }

  private static giftCouponCodeValidator(control: AbstractControl): ValidationErrors | null {
    const values: IFormValues = control.value as IFormValues;
    const codesFrom: number = +values.codesFrom;
    const codesTo: number = +values.codesTo;

    if (!values?.multipleCodes) {
      if (isNaN(codesFrom) || codesFrom < 1) {
        return {
          min: {
            min: 1,
            current: codesFrom,
          },
        };
      }
      return null;
    }

    if (isNaN(codesFrom) || codesFrom < 1 || isNaN(codesTo) || codesTo < 1) {
      return {
        min: {
          min: 1,
          currentFrom: codesFrom,
          currentTo: codesTo,
        },
      };
    }
    if (codesTo <= codesFrom) {
      return {
        smallerThanFrom: {
          from: codesFrom,
          to: codesTo,
        },
      };
    }
    return null;
  }
}
