import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DataService, FormInputComponent, InputComponentConfig } from '@vendure/admin-ui/core';

import {
  DeliveryArea,
  GetAvailableDeliveryAreasQuery,
  GetDeliveryAreaQuery,
  GetDeliveryAreaQueryVariables,
} from '@shared/types/generated-ui-types';
import { GET_AVAILABLE_DELIVERY_AREAS, GET_DELIVERY_AREA } from '../../../channel-extension/delivery-area.graphql';

@UntilDestroy()
@Component({
  selector: 'app-shipping-delivery-area-selection',
  templateUrl: './shipping-delivery-area-selection.component.html',
})
export class ShippingDeliveryAreaSelectionCardComponent implements FormInputComponent, OnInit {
  public readonly: boolean;
  public formControl: FormControl;
  public config: InputComponentConfig;

  public deliveryAreas: DeliveryArea[] = [];

  constructor(private readonly dataService: DataService) {}

  public ngOnInit(): void {
    if (this.formControl.value) {
      this.dataService
        .query<GetDeliveryAreaQuery, GetDeliveryAreaQueryVariables>(GET_DELIVERY_AREA, {
          id: String(this.formControl.value),
        })
        .mapSingle((data) => data.deliveryArea as DeliveryArea)
        .subscribe((area) => {
          this.deliveryAreas.push(area);
          // Component will not detect a change. Instead you need to do:
          this.deliveryAreas = [...this.deliveryAreas];
        });
    }

    this.dataService
      .query<GetAvailableDeliveryAreasQuery>(GET_AVAILABLE_DELIVERY_AREAS)
      .mapSingle((data) => data.availableDeliveryAreas.items as DeliveryArea[])
      .subscribe((areas) => {
        this.deliveryAreas.push(...areas);
        // Component will not detect a change. Instead you need to do:
        this.deliveryAreas = [...this.deliveryAreas];
      });
  }
}
