import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { KsRoute } from '@shared/models/KsRoutes';
import { ProductImporterComponent } from './components/product-importer/product-importer.component';

const routes: KsRoute[] = [
  {
    path: 'productImporter',
    pathMatch: 'full',
    component: ProductImporterComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductImporterRoutingModule {}
