import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { BASE_ASSET } from './assets.fragment';
import { BASE_CHANNEL } from './channel.fragment';
import { BASE_PRODUCT_VARIANT } from './productVariants.fragment';

export const BASE_RECIPE: DocumentNode = gql`
  fragment BaseRecipe on Recipe {
    id
    name
    description
    ingredients
    cookTime
    cookLevel
    status
  }
`;

export const EXTENDED_RECIPE: DocumentNode = gql`
  ${BASE_RECIPE}
  ${BASE_ASSET}
  ${BASE_CHANNEL}
  ${BASE_PRODUCT_VARIANT}
  fragment ExtendedRecipe on Recipe {
    ...BaseRecipe
    channels {
      ...BaseChannel
    }
    featuredAsset {
      ...BaseAsset
    }
    assets {
      ...BaseAsset
    }
    productVariants {
      ...BaseProductVariant
    }
  }
`;
