import { NgModule } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NavMenuItem, SharedModule } from '@vendure/admin-ui/core';

import { PAGE_URLS } from '@navUrls';
import { DirectivesModule } from '@shared/directives/directives.module';
import { Permission } from '@shared/types/generated-ui-types';
import { ActivateGiftCouponComponent } from './components/activate-gift-coupon/activate-gift-coupon.component';
import { CreateGiftCouponComponent } from './components/create-gift-coupon/create-gift-coupon.component';
import { DisplayGiftCouponCodeComponent } from './components/display-gift-coupon-code/display-gift-coupon-code.component';
import { RedeemGiftCouponComponent } from './components/redeem-gift-coupon/redeem-gift-coupon.component';
import { GiftCouponComponent } from './gift-coupon.component';
import { GiftCouponRoutingModule } from './gift-coupon.routing';

export const GIFT_COUPON_NAV_ITEM: NavMenuItem = {
  id: 'gift-coupons',
  label: _('giftCouponPlugin.navItem'),
  routerLink: PAGE_URLS.giftCoupons.getUrlSegments(),
  icon: 'credit-card',
  requiresPermission: Permission.ReadGiftCoupon,
};

@NgModule({
  imports: [DirectivesModule, GiftCouponRoutingModule, SharedModule],
  declarations: [
    CreateGiftCouponComponent,
    DisplayGiftCouponCodeComponent,
    GiftCouponComponent,
    ActivateGiftCouponComponent,
    RedeemGiftCouponComponent,
  ],
})
export class GiftCouponModule {}
