import { Injectable } from '@angular/core';
import { DataService, QueryResult } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';

import { DeleteProducer, GetProducer, GetProducerQuery, GetProducers } from '@shared/types/generated-ui-types';
import { DELETE_PRODUCER, GET_PRODUCER, GET_PRODUCERS } from './producer.graphql';

@Injectable({
  providedIn: 'root',
})
export class ProducerService {
  constructor(private readonly dataService: DataService) {}

  public getProducers(options?: { take?: number; skip?: number }): QueryResult<GetProducers.Query> {
    return this.dataService
      .query<GetProducers.Query>(GET_PRODUCERS, { take: 10, skip: 0, ...options })
      .refetchOnChannelChange();
  }

  public getProducerById(id: string): Observable<GetProducerQuery['producer']> {
    return this.dataService
      .query<GetProducer.Query, GetProducer.Variables>(GET_PRODUCER, { id })
      .mapStream((data) => data.producer);
  }

  public deleteProducer(id: string): Observable<DeleteProducer.Mutation> {
    return this.dataService.mutate<DeleteProducer.Mutation, DeleteProducer.Variables>(DELETE_PRODUCER, {
      id,
    });
  }
}
