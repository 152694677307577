import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@vendure/admin-ui/core';

import { ProductSearchInputModule } from '../product-search-input/product-search-input.module';
import { ProductVariantSelectComponent } from './product-variant-select.component';

@NgModule({
  declarations: [ProductVariantSelectComponent],
  imports: [CommonModule, SharedModule, ProductSearchInputModule],
  exports: [ProductVariantSelectComponent],
})
export class ProductVariantSelectModule {}
