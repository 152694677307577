import { NgModule } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CatalogModule } from '@vendure/admin-ui/catalog';
import { NavMenuItem, SharedModule } from '@vendure/admin-ui/core';

import { PAGE_URLS } from '@navUrls';
import { KsSharedModule } from '@shared/shared.module';
import { RecipeDetailComponent } from './components/recipe-detail/recipe-detail.component';
import { RecipeListComponent } from './components/recipe-list/recipe-list.component';
import { RecipeRoutingModule } from './recipe.routing';
import { RecipeDetailResolver } from './recipe-details.resolver';

export const RECIPE_NAV_ITEM: NavMenuItem = {
  id: 'recipe',
  label: _('recipePlugin.navItem'),
  routerLink: PAGE_URLS.recipes.getUrlSegments(),
  icon: 'book',
};

@NgModule({
  imports: [CatalogModule, SharedModule, RecipeRoutingModule, KsSharedModule],
  declarations: [RecipeListComponent, RecipeDetailComponent],
  providers: [RecipeDetailResolver],
})
export class RecipeModule {}
