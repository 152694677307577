import { NgModule } from '@angular/core';

import { AddressDisplayModule } from '@shared/components/address-display/address-display.module';
import { AddressSelectModule } from './components/address-select/address-select.module';
import { ChannelSelectModule } from './components/channel-select/channel-select.module';
import { CustomerWalletTopUptModule } from './components/customer-wallet-top-up/customer-wallet-top-up.module';
import { FeatureAssetModule } from './components/feature-asset/feature-asset.module';
import { ProductSearchInputModule } from './components/product-search-input/product-search-input.module';
import { ProductVariantSelectModule } from './components/product-variant-select/product-variant-select.module';
import { TimeFrameModalContentModule } from './components/time-frame-modal-content/time-frame-modal-content.module';
import { DirectivesModule } from './directives/directives.module';

/**
 * List of modules that should be provided
 */
const SHARED_MODULES = [
  AddressDisplayModule,
  AddressSelectModule,
  ChannelSelectModule,
  CustomerWalletTopUptModule,
  DirectivesModule,
  FeatureAssetModule,
  ProductSearchInputModule,
  ProductVariantSelectModule,
  TimeFrameModalContentModule,
];

@NgModule({
  imports: [
    ...SHARED_MODULES,
    // Please do not add any modules here, but use the arrays above.
  ],
  exports: [
    ...SHARED_MODULES,
    // Please do not add any modules here, but use the array above.
  ],
})
export class KsSharedModule {}
