import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchProducts } from '@vendure/admin-ui/core';

import { Logger } from '@services';
import { Permission } from '@shared/types/generated-ui-types';
import { MinimalistProductVariant } from '@shared/types/minimalist-product-variant';

@Component({
  selector: 'app-product-variant-select',
  templateUrl: './product-variant-select.component.html',
  styleUrls: ['./product-variant-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductVariantSelectComponent {
  @Input()
  set selectedProductVariants(selectedProductVariants: MinimalistProductVariant[]) {
    try {
      // Get a deep copy instead of shallow copy
      this._selectedProductVariants = JSON.parse(JSON.stringify(selectedProductVariants)) as MinimalistProductVariant[];
    } catch {
      this._selectedProductVariants = [];
    }
    this.updateSelectedProductVariantIds();
  }
  get selectedProductVariants(): MinimalistProductVariant[] {
    return this._selectedProductVariants;
  }
  @Output()
  public selectedProductVariantsChange: EventEmitter<MinimalistProductVariant[]> = new EventEmitter<
    MinimalistProductVariant[]
  >();
  private _selectedProductVariants: MinimalistProductVariant[] = [];

  public selectedProductVariantIds: string[] = [];

  public updateRecipePermissions: Permission[] = [Permission.UpdateRecipe];

  public addProductVariant(product: SearchProducts.Items): void {
    this.selectedProductVariants = [
      ...this.selectedProductVariants,
      {
        id: product.productVariantId,
        name: product.productVariantName || product.productName,
        preview: product.productAsset?.preview ?? '',
      },
    ];

    this.selectedProductVariantsChange.emit(this.selectedProductVariants);
    this.updateSelectedProductVariantIds();
  }

  public removeProductVariant(productVariantId: string): void {
    const index: number = this.selectedProductVariants.findIndex(
      (productVariant) => productVariant.id === productVariantId
    );

    if (index === -1) {
      Logger.error(`No product variant with id '${productVariantId}' found.`);
      return;
    }

    // Remove product variant from array
    this.selectedProductVariants.splice(index, 1);

    this.selectedProductVariantsChange.emit(this.selectedProductVariants);
    this.updateSelectedProductVariantIds();
  }

  private updateSelectedProductVariantIds(): void {
    this.selectedProductVariantIds = this.selectedProductVariants.map((variant) => variant.id);
  }
}
