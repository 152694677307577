import { NgModule } from '@angular/core';
import { addNavMenuItem, SharedModule } from '@vendure/admin-ui/core';

import { CAPACITY_NAV_ITEM, CapacityModule } from './capacity/capacity.module';
import { ChannelExtensionModule } from './channel-extension/channel-extension.module';
import { CollectionExtensionModule } from './collection/collection-extension.module';
import { EXPORT_CENTRAL_NAV_ITEM, ExportCentralModule } from './export-central/export-central.module';
import { GIFT_COUPON_NAV_ITEM, GiftCouponModule } from './gift-coupon/gift-coupon.module';
import { INVOICE_NAV_ITEM, InvoicesModule } from './invoice/invoice.module';
import { OrderExtensionModule } from './order-extension/order-extension.module';
import { PRODUCER_NAV_ITEM, ProducerModule } from './producer/producer.module';
import { ProductExtensionModule } from './product-extension/product-extension.module';
import { ProductImporterModule } from './product-importer/product-importer.module';
import { RECIPE_NAV_ITEM, RecipeModule } from './recipe/recipe.module';
import { ShippingMethodExtensionModule } from './shipping-method-extension/shipping-method-extension.module';
import { SPECIAL_NAV_ITEM, SpecialModule } from './special/special.module';
import { WalletModule } from './wallet/wallet.module';
import { WidgetsModule } from './widgets/widgets.module';

/**
 * Module to add all plugins to the existing navigation:
 *
 * Add all modules that should be listed in the navigation ro the providers
 *
 * @example
 * If you want to add a navItem to an existing section:
 *
 *  addNavMenuItem({
 *       id: 'examples',
 *       label: 'Example',
 *       routerLink: ['/extensions/examples'],
 *       icon: 'star',
 *       before: 'anyNavItem', // optional
 *       },
 *       'catalog' // sectionId)
 *
 * @example
 * If you want to add a complete new section:
 *
 *  addNavMenuSection({
 *       id: 'examples',
 *       label: 'Example',
 *       items: [
 *          { id: 'examples',
 *            label: 'Examples',
 *            routerLink: ['/extensions/examples'],
 *            icon: 'star',
 *          },
 *          ... ],
 *       'settings')
 */
@NgModule({
  imports: [
    CapacityModule,
    ChannelExtensionModule,
    CollectionExtensionModule,
    ExportCentralModule,
    GiftCouponModule,
    InvoicesModule,
    OrderExtensionModule,
    ProducerModule,
    ProductExtensionModule,
    ProductImporterModule,
    RecipeModule,
    SharedModule,
    ShippingMethodExtensionModule,
    SpecialModule,
    WalletModule,
    WidgetsModule,
  ],
  providers: [
    addNavMenuItem(RECIPE_NAV_ITEM, 'catalog'),
    addNavMenuItem(PRODUCER_NAV_ITEM, 'catalog'),
    addNavMenuItem(SPECIAL_NAV_ITEM, 'marketing'),
    addNavMenuItem(INVOICE_NAV_ITEM, 'sales'),
    addNavMenuItem(GIFT_COUPON_NAV_ITEM, 'sales'),
    addNavMenuItem(EXPORT_CENTRAL_NAV_ITEM, 'sales'),
    addNavMenuItem(CAPACITY_NAV_ITEM, 'settings', 'payment-methods'),
  ],
})
export class PluginsModule {}
