import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { BASE_ASSET } from './assets.fragment';

export const BASE_PRODUCT_VARIANT: DocumentNode = gql`
  ${BASE_ASSET}
  fragment BaseProductVariant on ProductVariant {
    id
    name
    featuredAsset {
      ...BaseAsset
    }
    product {
      name
      featuredAsset {
        ...BaseAsset
      }
    }
  }
`;
