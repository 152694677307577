import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@vendure/admin-ui/core';

import { ProductSearchInputComponent } from './product-search-input.component';

@NgModule({
  declarations: [ProductSearchInputComponent],
  imports: [CommonModule, SharedModule],
  exports: [ProductSearchInputComponent],
})
export class ProductSearchInputModule {}
