import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const GET_SHIPPING_METHODS: DocumentNode = gql`
  query ShippingMethods {
    shippingMethods {
      items {
        id
        name
        customFields {
          deliveryDates {
            id
            deliveryWeekday
            earliestDeliveryTime
            latestDeliveryTime
            maxCapacity
          }
        }
      }
    }
  }
`;
