import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AUTH_REDIRECT_PARAM, DataService } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';

import { Authenticate } from '@shared/types/generated-ui-types';
import { LOGIN_WITH_GOOGLE } from './login.graphql';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private readonly dataService: DataService, private readonly router: Router) {}

  public navigateAfterLogin(reloadPage: boolean = false): void {
    const redirect: string | undefined = LoginService.getRedirectRoute();

    const url: string = redirect ?? '/';
    if (reloadPage) {
      // Using the native browser method to navigate -> Angular app is reloaded after navigation
      window.location.href = url;
    } else {
      void this.router.navigateByUrl(url);
    }
  }

  public loginWithGoogle(idToken: string): Observable<Authenticate.Mutation> {
    return this.dataService.mutate<Authenticate.Mutation>(LOGIN_WITH_GOOGLE, { token: idToken });
  }

  /**
   * Attempts to read a redirect param from the current url and parse it into a
   * route from which the user was redirected after a 401 error.
   */
  private static getRedirectRoute(): string | undefined {
    let redirectTo: string | undefined;
    const re: RegExp = new RegExp(`${AUTH_REDIRECT_PARAM}=(.*)`);
    try {
      const redirectToParam: RegExpMatchArray | null = window.location.search.match(re);
      if (redirectToParam && redirectToParam.length > 1) {
        redirectTo = window.atob(decodeURIComponent(redirectToParam[1]));
      }
    } catch (e) {}
    return redirectTo;
  }
}
