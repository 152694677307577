<div class="product-order-score">
  <div class="product-order-score__value">{{ formControl.value }}</div>
  <input
    *vdrIfPermissions="['UpdateProduct']"
    class="product-order-score__input"
    type="range"
    [min]="config.min"
    [max]="config.max"
    [formControl]="formControl"
  />
</div>
