import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { BaseListComponent, ModalService, NotificationService } from '@vendure/admin-ui/core';
import { EMPTY } from 'rxjs';
import { debounceTime, delay, switchMap, takeUntil } from 'rxjs/operators';

import { PAGE_URLS, PRODUCER_URLS } from '@navUrls';
import { Status } from '@shared/types/general.types';
import { GetProducers, Permission, SortOrder } from '@shared/types/generated-ui-types';
import { ProducerService } from '../../producer.service';
import { HasDetailsPage } from '../../producer.types';

interface IFormValues {
  searchTerm: string;
  filters: string[];
}

@Component({
  selector: 'app-producer-list',
  styleUrls: ['producer-list.component.scss'],
  templateUrl: './producer-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProducerListComponent
  extends BaseListComponent<GetProducers.Query, GetProducers.Items, GetProducers.Variables>
  implements OnInit
{
  public filters: {
    filterCategory: 'status' | 'hasDetailsPage';
    value: string;
    label: string;
  }[] = [
    { filterCategory: 'status', value: Status.ACTIVE, label: 'common.enabled' },
    { filterCategory: 'status', value: Status.INACTIVE, label: 'common.disabled' },
    {
      filterCategory: 'hasDetailsPage',
      value: HasDetailsPage.TRUE,
      label: 'producerPlugin.hasDetailsPage.true',
    },
    {
      filterCategory: 'hasDetailsPage',
      value: HasDetailsPage.FALSE,
      label: 'producerPlugin.hasDetailsPage.false',
    },
  ];

  public filterFormGroup: FormGroup = new FormGroup({
    searchTerm: new FormControl(''),
    filters: new FormControl([]),
  });

  public createProducerPermissions: Permission[] = [Permission.CreateProducer];
  public editProducerPermissions: Permission[] = [Permission.UpdateProducer];
  public deleteProducerPermissions: Permission[] = [Permission.DeleteProducer];

  // REFERENCES
  public PAGE_URLS: typeof PAGE_URLS = PAGE_URLS;
  public PRODUCER_URLS: typeof PRODUCER_URLS = PRODUCER_URLS;

  constructor(
    protected override route: ActivatedRoute,
    protected override router: Router,
    private readonly producerService: ProducerService,
    private readonly notificationService: NotificationService,
    private readonly modalService: ModalService
  ) {
    super(router, route);

    super.setQueryFn(
      (...[take, skip]: number[]) => {
        return this.producerService.getProducers({ take, skip });
      },
      (data) => data.producers,
      (skip, take) => {
        const formValues: IFormValues = this.filterFormGroup.value as IFormValues;

        const statusFilters: string[] = formValues?.filters.filter((filter) =>
          [Status.ACTIVE, Status.INACTIVE].includes(filter as Status)
        );
        const hasDetailsPageFilters: boolean[] = formValues?.filters
          .filter((filter) => [HasDetailsPage.TRUE, HasDetailsPage.FALSE].includes(filter as HasDetailsPage))
          .map((filter) => filter === HasDetailsPage.TRUE);

        return {
          options: {
            skip,
            take,
            sort: {
              createdAt: SortOrder.Desc,
            },
            filter: {
              ...(statusFilters?.length > 0
                ? {
                    status: {
                      in: statusFilters,
                    },
                  }
                : {}),
              // Filter `hasDetailsPage` is added only if only one option is selected,
              // otherwise (if no option or both options are selected) all producers should be loaded
              ...(hasDetailsPageFilters?.length === 1
                ? {
                    hasDetailsPage: {
                      eq: hasDetailsPageFilters[0],
                    },
                  }
                : {}),
              ...(formValues?.searchTerm
                ? {
                    name: {
                      contains: formValues?.searchTerm || null,
                    },
                  }
                : {}),
            },
          },
        };
      }
    );
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.filterFormGroup.valueChanges.pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe(() => this.refresh());
  }

  public delete(id: string): void {
    this.modalService
      .dialog({
        title: _('producerPlugin.confirmDelete'),
        buttons: [
          { type: 'secondary', label: _('common.cancel') },
          { type: 'danger', label: _('common.delete'), returnValue: true },
        ],
      })
      .pipe(
        switchMap((response) => (response ? this.producerService.deleteProducer(id) : EMPTY)),
        // Short delay allowing the producer to be removed from the search index before refreshing.
        delay(500)
      )
      .subscribe(
        () => {
          this.notificationService.success(_('common.notify-delete-success'), {
            entity: _('producerPlugin.producer.1'),
          });
          this.refresh();
        },
        () => {
          this.notificationService.error(_('common.notify-delete-error'), {
            entity: _('producerPlugin.producer.1'),
          });
        }
      );
  }
}
