import { PAGE_URLS } from './pages.urls';
import { RouteParams, RouteUrl } from './routeUrl';

const baseUrl = PAGE_URLS.recipes.getUrlSegments();
const detailsUrl = ':id';

interface DetailsParams extends RouteParams {
  id: string;
}

export const RECIPE_URLS = {
  create: new RouteUrl([...baseUrl, 'create']),
  details: new RouteUrl<DetailsParams>([...baseUrl, detailsUrl]),
};
