import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const UPSERT_CONFIG_MUTATION: DocumentNode = gql`
  mutation upsertInvoiceConfig($input: InvoiceConfigInput!) {
    upsertInvoiceConfig(input: $input) {
      id
      enabled
      templateString
    }
  }
`;

export const GET_INVOICE_CONFIG: DocumentNode = gql`
  query invoiceConfig {
    invoiceConfig {
      id
      enabled
      templateString
    }
  }
`;

export const GET_ALL_INVOICES: DocumentNode = gql`
  query allInvoices($options: InvoiceListOptions) {
    allInvoices(options: $options) {
      items {
        id
        createdAt
        orderCode
        orderId
        customerEmail
        invoiceNumber
        downloadUrl
      }
      totalItems
    }
  }
`;
