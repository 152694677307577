<div class="card">
  <div class="card-header">
    <div class="title">{{ 'giftCouponPlugin.activateGiftCouponCode' | translate }}</div>
  </div>
  <div class="card-block">
    <form
      *varRef="giftCouponForm.value.multipleCodes as multipleCodes"
      [formGroup]="giftCouponForm"
      (ngSubmit)="activateCodes()"
    >
      <section class="form-block">
        <vdr-form-field for="multipleCodes" [label]="'giftCouponPlugin.activateMultipleCodes' | translate">
          <clr-toggle-wrapper>
            <input type="checkbox" clrToggle name="multipleCodes" formControlName="multipleCodes" />
          </clr-toggle-wrapper>
        </vdr-form-field>
      </section>

      <section class="form-block">
        <vdr-form-field
          for="codesFrom"
          [label]="'giftCouponPlugin.giftCouponCode.label' | translate: { amount: multipleCodes ? 2 : 1 }"
        >
          <div class="gift-coupon-input-container">
            <div class="gift-coupon-inputs">
              <span *ngIf="multipleCodes" class="mr2">{{ 'giftCouponPlugin.giftCouponCode.from' | translate }}</span>
              <input id="codesFrom" type="number" formControlName="codesFrom" />
              <ng-container *ngIf="multipleCodes">
                <span class="mx2">{{ 'giftCouponPlugin.giftCouponCode.to' | translate }}</span>
                <input id="codesTo" type="number" formControlName="codesTo" />
              </ng-container>
            </div>
            <div
              *ngIf="
                giftCouponForm.errors &&
                (giftCouponForm.controls.codesFrom.touched || giftCouponForm.controls.codesTo.touched)
              "
              class="gift-coupon-input-errors"
            >
              {{
                (multipleCodes ? 'giftCouponPlugin.error.multipleCodes' : 'giftCouponPlugin.error.singleCode')
                  | translate
              }}
            </div>
          </div>
        </vdr-form-field>
      </section>

      <section class="form-block">
        <vdr-form-field for="activationComment" [label]="'giftCouponPlugin.comment' | translate">
          <input id="activationComment" type="text" formControlName="comment" />
        </vdr-form-field>
      </section>

      <button class="btn btn-primary">
        {{ 'giftCouponPlugin.activateGiftCoupon.label' | translate: { amount: getNumberOfCoupons() } }}
      </button>
    </form>
  </div>
</div>
