import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomFieldConfigType, CustomFieldControl } from '@vendure/admin-ui/core';

import { IntCustomFieldConfig } from '@shared/types/generated-ui-types';

@Component({
  selector: 'app-product-order-score',
  templateUrl: './product-order-score.component.html',
  styleUrls: ['./product-order-score.component.scss'],
})
export class ProductOrderScoreComponent implements CustomFieldControl {
  public readonly: boolean;
  // Retrieving configs from backend
  public config: CustomFieldConfigType & IntCustomFieldConfig;
  public formControl: FormControl;
}
