import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CanDeactivateDetailGuard } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PAGE_URLS } from '@navUrls';
import { KsBreadcrumb, KsRoute } from '@shared/models/KsRoutes';
import { GetProducer } from '@shared/types/generated-ui-types';
import { ProducerDetailComponent } from './components/producer-detail/producer-detail.component';
import { ProducerListComponent } from './components/producer-list/producer-list.component';
import { ProducerDetailResolver } from './producer-details.resolver';

const routes: KsRoute[] = [
  {
    path: 'producers',
    pathMatch: 'full',
    component: ProducerListComponent,
    data: {
      breadcrumb: [
        {
          label: _('producerPlugin.navItem'),
          link: PAGE_URLS.producers.getUrlSegments(),
        },
      ],
    },
  },
  {
    path: 'producers/:id',
    component: ProducerDetailComponent,
    resolve: { entity: ProducerDetailResolver },
    canDeactivate: [CanDeactivateDetailGuard],
    data: { breadcrumb: producerDetailBreadcrumb },
  },
];

/**
 * Returns the correct breadcrumb depending on url
 * @param resolved Resolve object
 * @param resolved.entity Current producer as observable
 */
export function producerDetailBreadcrumb(resolved: {
  entity: Observable<GetProducer.Producer>;
}): Observable<KsBreadcrumb[]> {
  return resolved.entity.pipe(
    map((entity) => [
      {
        label: _('producerPlugin.navItem'),
        link: PAGE_URLS.producers.getUrlSegments(),
      },
      {
        label: entity.name,
        link: [],
      },
    ])
  );
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProducerRoutingModule {}
