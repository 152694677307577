import { NgModule } from '@angular/core';
import {
  registerCustomDetailComponent,
  registerFormInputComponent,
  registerHistoryEntryComponent,
  SharedModule,
} from '@vendure/admin-ui/core';

import { DirectivesModule } from '@shared/directives/directives.module';
import { HistoryEntryType } from '@shared/types/generated-ui-types';
import { OrderInactiveHistoryEntryComponent } from './order-inactive-history-entry/order-inactive-history-entry.component';
import { OrderInvoiceDownloadComponent } from './order-invoice-download/order-invoice-download.component';
import { OrderInvoicePaidComponent } from './order-invoice-paid/order-invoice-paid.component';

@NgModule({
  imports: [SharedModule, DirectivesModule],
  declarations: [OrderInactiveHistoryEntryComponent, OrderInvoicePaidComponent, OrderInvoiceDownloadComponent],
  providers: [
    registerFormInputComponent('app-order-invoice-paid', OrderInvoicePaidComponent),
    registerCustomDetailComponent({ component: OrderInvoiceDownloadComponent, locationId: 'order-detail' }),
    registerHistoryEntryComponent({
      type: HistoryEntryType.OrderMarkManuallyInactive,
      component: OrderInactiveHistoryEntryComponent,
    }),
  ],
})
export class OrderExtensionModule {}
