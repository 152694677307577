import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService, FormInputComponent, InputComponentConfig } from '@vendure/admin-ui/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { FacetValueSeletorItem } from '../facet-value-selector/facet-value-selector.component';

@Component({
  selector: 'app-collection-facet-filter',
  templateUrl: './collection-facet-filter.component.html',
})
export class CollectionFacetFilterComponent implements FormInputComponent, OnInit {
  public readonly: boolean;
  // Retrieving configs from backend
  public config: InputComponentConfig;
  public formControl: FormControl;
  public readonly isListInput: boolean = true;

  public facets$: Observable<any[]>;

  public searchTerm$: Subject<string> = new Subject<string>();

  constructor(private readonly dataService: DataService) {}

  public ngOnInit(): void {
    this.facets$ = this.dataService.facet
      .getAllFacets()
      .mapSingle((data) => data.facets.items)
      .pipe(shareReplay(1));
  }

  public valueTransformFn: (values: FacetValueSeletorItem[]) => string = (values: FacetValueSeletorItem[]) => {
    return JSON.stringify(values.map((s) => s.id));
  };
}
