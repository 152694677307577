import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataService, LocaleCurrencyPipe, ModalService, NotificationService } from '@vendure/admin-ui/core';
import { map, switchMap } from 'rxjs/operators';

import { ChannelService } from '@services';
import { CreateGiftCoupon, CurrencyCode } from '@shared/types/generated-ui-types';
import { CREATE_GIFT_COUPON } from '../../gift-coupon.graphql';
import { DisplayGiftCouponCodeComponent } from '../display-gift-coupon-code/display-gift-coupon-code.component';

interface IFormValues {
  initialValue: number;
  comment: string;
}

@UntilDestroy()
@Component({
  selector: 'app-create-gift-coupon',
  templateUrl: './create-gift-coupon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LocaleCurrencyPipe],
})
export class CreateGiftCouponComponent implements OnInit {
  public giftCouponForm: FormGroup = new FormGroup({
    initialValue: new FormControl(0, [(control) => Validators.required(control), Validators.min(100)]),
    comment: new FormControl('', (control) => Validators.required(control)),
  });

  public currencyCode: CurrencyCode | undefined;

  constructor(
    private readonly dataService: DataService,
    private readonly channelService: ChannelService,
    private readonly modalService: ModalService,
    private readonly localeCurrencyPipe: LocaleCurrencyPipe,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.channelService.currentChannel$.pipe(untilDestroyed(this)).subscribe((channel) => {
      this.currencyCode = channel?.currencyCode;
      this.changeDetectorRef.markForCheck();
    });
  }

  public async createGiftCoupon(): Promise<void> {
    if (this.giftCouponForm.invalid) {
      this.giftCouponForm.markAllAsTouched();
      return;
    }

    const formValue: IFormValues = this.giftCouponForm.value as IFormValues;

    const confirmation: boolean =
      (await this.modalService
        .dialog({
          title: _('giftCouponPlugin.createGiftCoupon'),
          translationVars: {
            amount: this.localeCurrencyPipe.transform(formValue.initialValue, this.currencyCode) as string,
          },
          body: _('giftCouponPlugin.createGiftCouponQuestion'),
          buttons: [
            { type: 'secondary', label: _('common.cancel') },
            { type: 'primary', label: _('common.create'), returnValue: true },
          ],
        })
        .toPromise()) ?? false;

    if (!confirmation) {
      return;
    }

    this.dataService
      .mutate<CreateGiftCoupon.Mutation, CreateGiftCoupon.Variables>(CREATE_GIFT_COUPON, {
        input: {
          initialValue: +formValue.initialValue,
          comment: formValue.comment,
        },
      })
      .pipe(
        map((response) => response.createGiftCoupon.code),
        switchMap((code) =>
          this.modalService.fromComponent(DisplayGiftCouponCodeComponent, {
            size: 'md',
            locals: {
              giftCouponCode: code as string,
            },
          })
        )
      )
      .subscribe(
        () => {
          this.giftCouponForm.reset({ initialValue: 0, comment: '' });
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.notificationService.error('giftCouponPlugin.error.creationFailed');
        }
      );
  }

  /**
   * Returns the minimum value for the gift coupon, which is set by a form validator
   */
  public getMinimumValue(): number {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return Number(this.giftCouponForm.controls.initialValue.errors?.min?.min || 0) / 100; // Divide by 100 to convert the value from Cents to Euros
  }
}
