import { Component, OnInit } from '@angular/core';
import { DataService } from '@vendure/admin-ui/core';
import { CustomerGroup } from '@vendure/core';

import { WalletActivity } from '@shared/types/wallet';
import { CsvExportSelectionType } from './components/csv-export/csv-export.component';

@Component({
  selector: 'app-export-central',
  templateUrl: './export-central.component.html',
})
export class ExportCentralComponent implements OnInit {
  public customerGroups: CustomerGroup[] = [];

  public walletActivities: CsvExportSelectionType[] = [
    { id: WalletActivity.DEPOSIT_REFUND, name: 'Pfandrückgabe' },
    { id: WalletActivity.ORDER_GOODWILL, name: 'Kulanz/ Bestellfehler' },
    { id: WalletActivity.SUPPORT_CREDIT, name: 'Sonstige Support Gutschrift' },
    { id: WalletActivity.SUPPORT_DEBIT, name: 'Support Abbuchungen' },
  ];

  constructor(private readonly dataService: DataService) {}

  public ngOnInit(): void {
    this.customerGroups = [];
    this.dataService.customer
      .getCustomerGroupList()
      .mapSingle((res) => res.customerGroups.items)
      .subscribe((groups) => (this.customerGroups = groups as CustomerGroup[]));
  }
}
