import { NgModule } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NavMenuItem, SharedModule } from '@vendure/admin-ui/core';

import { PAGE_URLS } from '@navUrls';
import { DirectivesModule } from '@shared/directives/directives.module';
import { CsvExportComponent } from './components/csv-export/csv-export.component';
import { ExportCentralComponent } from './export-central.component';
import { ExportCentralRoutingModule } from './export-central.routing';

export const EXPORT_CENTRAL_NAV_ITEM: NavMenuItem = {
  id: 'export-central',
  label: _('exportCentralPlugin.navItem'),
  routerLink: PAGE_URLS.exportCentral.getUrlSegments(),
  icon: 'download',
  requiresPermission: 'AllowCSVExport',
};

@NgModule({
  imports: [DirectivesModule, ExportCentralRoutingModule, SharedModule],
  declarations: [ExportCentralComponent, CsvExportComponent],
})
export class ExportCentralModule {}
