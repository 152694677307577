<ng-select
  [items]="foundProducts$ | async"
  [typeahead]="searchTerm$"
  [placeholder]="'catalog.search-product-name-or-code' | translate"
  [typeToSearchText]="'productSearchInput.typeToSearch' | translate"
  [loading]="areProductsLoading"
  [loadingText]="'productSearchInput.loading' | translate"
  [notFoundText]="'productSearchInput.noProductVariantsFound' | translate"
  (change)="onSelectChange($event)"
>
  <ng-template ng-option-tmp let-item="item">
    <div class="select-item">
      <div class="select-item__image-container">
        <img
          *ngIf="item.productAsset?.preview"
          class="select-item__image"
          [src]="item.productAsset.preview"
          [alt]="item.productVariantName || item.productName"
        />
      </div>
      <div>
        {{ item.productVariantName || item.productName }}
        <span class="select-item__sku">({{ 'productSearchInput.articleNumber' | translate }}: {{ item.sku }})</span>
      </div>
    </div>
  </ng-template>
</ng-select>
