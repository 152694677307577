import { Component } from '@angular/core';
import { Dialog, getServerLocation, NotificationService } from '@vendure/admin-ui/core';

import { RestAPIService } from '@services';

interface ProductImporterResult {
  alreadyExistingProducts: { name: string }[];
  productsWithMissingAssets: { name: string }[];
  productsWithUnknownError: { name: string }[];
}

@Component({
  selector: 'app-product-importer-modal',
  templateUrl: './product-importer-modal.component.html',
  styleUrls: ['./product-importer-modal.component.scss'],
})
export class ProductImporterModalComponent implements Dialog<void> {
  public resolveWith: () => void;

  public isLoading: boolean;
  public isCompleted: boolean;

  public fileToUpload: File;

  public result: ProductImporterResult | undefined;

  private readonly serverPath: string;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly restAPIService: RestAPIService
  ) {
    this.serverPath = getServerLocation();
  }

  public handleFileInput(event?: Event | null): void {
    // eslint-disable-next-line @typescript-eslint/typedef
    const file = (event?.target as unknown as { files: FileList })?.files?.[0];

    if (file) {
      this.fileToUpload = file;
    }
  }

  public async startImport(): Promise<void> {
    this.isLoading = true;
    this.result = undefined;

    const data: FormData = new FormData();
    data.set('file', this.fileToUpload);

    const res: Response = await fetch(`${this.serverPath}/productImport/upload`, {
      credentials: 'include',
      headers: this.restAPIService.getHeaders(),
      method: 'POST',
      body: data,
    });

    if (!res.ok) {
      this.isLoading = false;
      this.result = undefined;

      this.notificationService.error('common.notify-create-error', { entity: 'breadcrumb.products' });
      return;
    } else {
      this.isCompleted = true;
      this.isLoading = false;

      this.result = (await res.json()) as ProductImporterResult;
    }
  }

  public cancel(): void {
    this.resolveWith();
  }

  public confirm(): void {
    this.resolveWith();
  }
}
