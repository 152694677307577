import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AssetChange } from '@vendure/admin-ui/catalog';
import { BaseDetailComponent, DataService, NotificationService, ServerConfigService } from '@vendure/admin-ui/core';
import { DEFAULT_CHANNEL_CODE } from '@vendure/common/lib/shared-constants';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, mapTo, takeUntil } from 'rxjs/operators';

import { RECIPE_URLS } from '@navUrls';
import { PermissionService } from '@services';
import { Status } from '@shared/types/general.types';
import {
  Asset,
  AssignRecipeToChannel,
  Channel,
  CreateRecipe,
  CreateRecipeInput,
  InputMaybe,
  Permission,
  Recipe,
  RemoveRecipeFromChannel,
  UpdateRecipe,
  UpdateRecipeInput,
} from '@shared/types/generated-ui-types';
import { MinimalistProductVariant } from '@shared/types/minimalist-product-variant';
import { trimRichTextEditorContent } from '@shared/utils';
import {
  ASSIGN_RECIPE_TO_CHANNEL,
  CREATE_RECIPE,
  REMOVE_RECIPE_FROM_CHANNEL,
  UPDATE_RECIPE,
} from '../../recipe.graphql';
import { CookLevel } from '../../recipe.types';

interface IFormValues {
  name: string;
  description: string;
  ingredients?: string;
  cookTime?: number;
  cookLevel?: string;
  enabled: boolean;
}

@Component({
  selector: 'app-recipe-detail',
  templateUrl: './recipe-detail.component.html',
  styleUrls: ['./recipe-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RecipeDetailComponent extends BaseDetailComponent<Recipe> implements OnInit, OnDestroy {
  public selectedChannels$: Observable<Channel[] | undefined | null>;
  public assets$: Observable<Asset[] | undefined | null>;
  public featuredAsset$: Observable<Asset>;
  public assetChanges: Partial<AssetChange> = {};

  public linkedProductVariants$: Observable<MinimalistProductVariant[] | undefined>;
  public productVariantChanges: MinimalistProductVariant[] | null = null;

  public readonly detailForm: FormGroup = new FormGroup({
    enabled: new FormControl(false),
    name: new FormControl('', (control) => Validators.required(control)),
    description: new FormControl('', (control) => Validators.required(control)),
    ingredients: new FormControl(''),
    cookTime: new FormControl('', Validators.min(1)),
    cookLevel: new FormControl(''),
  });

  public readonly createRecipePermissions: Permission[] = [Permission.CreateRecipe];
  public readonly updateRecipePermissions: Permission[] = [Permission.UpdateRecipe];

  public readonly DEFAULT_CHANNEL_CODE: typeof DEFAULT_CHANNEL_CODE = DEFAULT_CHANNEL_CODE;

  public readonly cookLevels: CookLevel[] = [CookLevel.LOW, CookLevel.MID, CookLevel.HIGH];

  constructor(
    protected override route: ActivatedRoute,
    protected override router: Router,
    protected override serverConfigService: ServerConfigService,
    protected override dataService: DataService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly notificationService: NotificationService,
    private readonly permissionService: PermissionService
  ) {
    super(route, router, serverConfigService, dataService);
  }

  public ngOnInit(): void {
    this.init();

    this.selectedChannels$ = this.entity$?.pipe(map((recipe) => recipe.channels));

    this.assets$ = this.entity$?.pipe(map((recipe) => recipe.assets));

    this.featuredAsset$ = this.entity$?.pipe(map((recipe) => recipe.featuredAsset as Asset));

    this.linkedProductVariants$ = this.entity$?.pipe(
      map(
        (recipe) =>
          recipe.productVariants?.map((productVariant) => ({
            preview: productVariant.featuredAsset?.preview ?? productVariant.product?.featuredAsset?.preview,
            id: productVariant.id,
            name: productVariant.name || productVariant.product?.name,
          })) as MinimalistProductVariant[]
      )
    );

    combineLatest([this.isNew$, this.permissionService.permissionsHaveChanged$])
      .pipe(
        map(([isNew]) => {
          const permissions: Permission[] = isNew ? this.createRecipePermissions : this.updateRecipePermissions;
          return [isNew, this.permissionService.checkPermissions(permissions)];
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(([isNew, hasPermission]) => {
        const enabledFormField: AbstractControl = this.detailForm.controls.enabled;
        const cookLevelFormField: AbstractControl = this.detailForm.controls.cookLevel;

        if (!hasPermission) {
          cookLevelFormField.disable();
        } else {
          cookLevelFormField.enable();
        }

        if (isNew || !hasPermission) {
          enabledFormField.disable();
        } else {
          enabledFormField.enable();
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy();
  }

  public assignToChannel(channelId: string): void {
    if (!channelId) {
      return;
    }

    this.dataService
      .mutate<AssignRecipeToChannel.Mutation, AssignRecipeToChannel.Variables>(ASSIGN_RECIPE_TO_CHANNEL, {
        input: { recipeId: this.id, channelId },
      })
      .subscribe(
        () => {
          this.notificationService.success('common.notify-update-success', {
            entity: _('recipePlugin.recipe.1'),
          });
          this.detailForm.markAsPristine();
          this.changeDetector.markForCheck();
        },
        () => {
          this.notificationService.error('common.notify-update-error', {
            entity: _('recipePlugin.recipe.1'),
          });
        }
      );
  }

  public removeFromChannel(channelId: string): void {
    if (!channelId) {
      return;
    }

    this.dataService
      .mutate<RemoveRecipeFromChannel.Mutation, RemoveRecipeFromChannel.Variables>(REMOVE_RECIPE_FROM_CHANNEL, {
        input: { recipeId: this.id, channelId },
      })
      .subscribe(
        () => {
          this.notificationService.success('common.notify-update-success', {
            entity: _('recipePlugin.recipe.1'),
          });
          this.detailForm.markAsPristine();
          this.changeDetector.markForCheck();
        },
        () => {
          this.notificationService.error('common.notify-update-error', {
            entity: _('recipePlugin.recipe.1'),
          });
        }
      );
  }

  public create(): void {
    if (!this.detailForm?.dirty) {
      return;
    }

    const formValue: IFormValues = this.detailForm.value as IFormValues;
    const recipe: CreateRecipeInput = {
      name: formValue.name,
      description: trimRichTextEditorContent(formValue.description),
      ingredients: trimRichTextEditorContent(formValue.ingredients),
      cookLevel: formValue.cookLevel ?? null,
      cookTime: formValue.cookTime ?? null,
    };

    if (this.assetsChanged()) {
      recipe.assetIds = this.assetChanges.assets?.map((a) => a.id);
      recipe.featuredAssetId = this.assetChanges.featuredAsset?.id as string;
    }

    if (this.productVariantsChanged()) {
      recipe.productVariantIds = this.productVariantChanges?.map((variant) => variant.id);
    }

    this.dataService
      .mutate<CreateRecipe.Mutation, CreateRecipe.Variables>(CREATE_RECIPE, {
        input: recipe,
      })
      .subscribe(
        (data) => {
          this.notificationService.success('common.notify-create-success', {
            entity: _('recipePlugin.recipe.1'),
          });
          this.assetChanges = {};
          this.productVariantChanges = null;
          this.detailForm.markAsPristine();
          void this.router.navigate(
            RECIPE_URLS.details.getUrlSegments({
              params: { id: data.createRecipe.id },
            })
          );
          this.changeDetector.markForCheck();
        },
        () => {
          this.notificationService.error('common.notify-create-error', {
            entity: _('recipePlugin.recipe.1'),
          });
        }
      );
  }

  public save(): void {
    this.saveChanges()
      .pipe(filter((result) => result))
      .subscribe(
        () => {
          this.assetChanges = {};
          this.productVariantChanges = null;
          this.detailForm.markAsPristine();
          this.changeDetector.markForCheck();
          this.notificationService.success('common.notify-update-success', {
            entity: _('recipePlugin.recipe.1'),
          });
        },
        () => {
          this.notificationService.error('common.notify-update-error', {
            entity: _('recipePlugin.recipe.1'),
          });
        }
      );
  }

  public override canDeactivate(): boolean {
    return (
      super.canDeactivate() &&
      (!this.assetChanges.assets || this.assetChanges?.assets?.length === 0) &&
      !this.assetChanges.featuredAsset &&
      !this.productVariantsChanged()
    );
  }

  public assetsChanged(): boolean {
    return !!Object.values(this.assetChanges).length;
  }

  public productVariantsChanged(): boolean {
    return this.productVariantChanges !== null;
  }

  private saveChanges(): Observable<boolean> {
    const input: UpdateRecipeInput = { id: this.id };
    if (this.detailForm.dirty) {
      const formValue: IFormValues = this.detailForm.value as IFormValues;
      input.status = formValue.enabled ? Status.ACTIVE : Status.INACTIVE;
      input.name = formValue.name;
      input.description = trimRichTextEditorContent(formValue.description);
      input.ingredients = trimRichTextEditorContent(formValue.ingredients);
      input.cookLevel = formValue.cookLevel ?? null;
      input.cookTime = formValue.cookTime ?? null;
    }

    if (this.assetsChanged()) {
      input.assetIds = this.assetChanges.assets?.map((a) => a.id) as InputMaybe<string[]>;
      input.featuredAssetId = this.assetChanges.featuredAsset?.id as InputMaybe<string>;
    }

    if (this.productVariantsChanged()) {
      input.productVariantIds = this.productVariantChanges?.map((variant) => variant.id);
    }

    if (Object.values(input).length > 1) {
      return this.dataService
        .mutate<UpdateRecipe.Mutation, UpdateRecipe.Variables>(UPDATE_RECIPE, {
          input,
        })
        .pipe(mapTo(true));
    } else {
      return of(false);
    }
  }

  protected setFormValues(entity: Recipe): void {
    this.detailForm.patchValue({
      enabled: entity.status === Status.ACTIVE,
      name: entity.name,
      description: entity.description,
      ingredients: entity.ingredients ?? null,
      cookTime: entity.cookTime ?? null,
      cookLevel: entity.cookLevel ?? null,
    });
  }

  public handleSelectedProductVariantChanged(productVariants: MinimalistProductVariant[]): void {
    this.productVariantChanges = productVariants || null;
  }
}
