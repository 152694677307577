import { Component } from '@angular/core';
import { ADMIN_UI_VERSION, getAppConfig } from '@vendure/admin-ui/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public version: string = ADMIN_UI_VERSION;
  public brand: string | undefined = getAppConfig().brand;
}
