<div *varRef="(isNew$ | async) || (updatePermission | hasPermission) as hasEditPermissions" class="card mt2 mb4">
  <div class="card-block">
    <app-address-display *ngIf="address" [address]="address"></app-address-display>
  </div>

  <div class="card-block">
    <button *ngIf="address && !editAddress && hasEditPermissions" class="btn btn-primary" (click)="editAddress = true">
      {{ 'common.edit' | translate }}
    </button>

    <app-address-select
      *ngIf="(!address || editAddress) && hasEditPermissions"
      class="mt2"
      [allowCanceling]="!!address"
      [allowSubmit]="true"
      (addressChanged)="handleAddressChanged($event)"
      (onSave)="saveAddress()"
      (cancel)="editAddress = false"
    ></app-address-select>
  </div>
</div>
