import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@vendure/admin-ui/core';

import { PAGE_URLS } from '@navUrls';
import { ProductImporterModalComponent } from '../product-importer-modal/product-importer-modal.component';

@Component({
  selector: 'app-product-importer',
  template: '<div></div>',
})
export class ProductImporterComponent implements OnInit {
  constructor(private readonly router: Router, private readonly modalService: ModalService) {}

  public ngOnInit(): void {
    this.modalService
      .fromComponent(ProductImporterModalComponent, {
        size: 'lg',
      })
      .subscribe(() => {
        void this.router.navigate(PAGE_URLS.products.getUrlSegments());
      });
  }
}
