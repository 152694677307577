import { NgModule } from '@angular/core';
import { registerFormInputComponent, SharedModule } from '@vendure/admin-ui/core';

import { CollectionFacetFilterComponent } from './components/collection-facet-filter/collection-facet-filter.component';
import { CustomFacetValueSelectorComponent } from './components/facet-value-selector/facet-value-selector.component';

@NgModule({
  imports: [SharedModule],
  declarations: [CollectionFacetFilterComponent, CustomFacetValueSelectorComponent],
  providers: [registerFormInputComponent('app-collection-facet-filter', CollectionFacetFilterComponent)],
})
export class CollectionExtensionModule {}
