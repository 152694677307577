import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AddressDisplayComponent } from '@shared/components/address-display/address-display.component';

@NgModule({
  declarations: [AddressDisplayComponent],
  imports: [CommonModule, TranslateModule],
  exports: [AddressDisplayComponent],
})
export class AddressDisplayModule {}
