import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { BaseListComponent, DataService, ModalService, NotificationService } from '@vendure/admin-ui/core';
import { EMPTY } from 'rxjs';
import { debounceTime, delay, switchMap, takeUntil } from 'rxjs/operators';

import { PAGE_URLS, RECIPE_URLS } from '@navUrls';
import { Status } from '@shared/types/general.types';
import { DeleteRecipe, GetRecipes, Permission, SortOrder } from '@shared/types/generated-ui-types';
import { DELETE_RECIPE, GET_RECIPES } from '../../recipe.graphql';

interface IFormValues {
  searchTerm: string;
  status: string[];
}

@Component({
  selector: 'app-recipe-list',
  styleUrls: ['recipe-list.component.scss'],
  templateUrl: './recipe-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipeListComponent
  extends BaseListComponent<GetRecipes.Query, GetRecipes.Items, GetRecipes.Variables>
  implements OnInit
{
  public statuses: { value: string; label: string }[] = [
    { value: Status.ACTIVE, label: 'common.enabled' },
    { value: Status.INACTIVE, label: 'common.disabled' },
  ];
  public filterFormGroup: FormGroup = new FormGroup({
    searchTerm: new FormControl(''),
    status: new FormControl([]),
  });

  public createRecipePermissions: Permission[] = [Permission.CreateRecipe];
  public editRecipePermissions: Permission[] = [Permission.UpdateRecipe];
  public deleteRecipePermissions: Permission[] = [Permission.DeleteRecipe];

  // REFERENCES
  public PAGE_URLS: typeof PAGE_URLS = PAGE_URLS;
  public RECIPE_URLS: typeof RECIPE_URLS = RECIPE_URLS;

  constructor(
    protected override route: ActivatedRoute,
    protected override router: Router,
    private readonly dataService: DataService,
    private readonly notificationService: NotificationService,
    private readonly modalService: ModalService
  ) {
    super(router, route);

    super.setQueryFn(
      (...[take, skip]: number[]) => {
        return this.dataService
          .query<GetRecipes.Query>(GET_RECIPES, { take: take ?? 10, skip: skip ?? 0 })
          .refetchOnChannelChange();
      },
      (data) => data.recipes,
      (skip, take) => {
        const formValues: IFormValues = this.filterFormGroup.value as IFormValues;

        return {
          options: {
            skip,
            take,
            sort: {
              createdAt: SortOrder.Desc,
            },
            filter: {
              ...(formValues?.status?.length > 0
                ? {
                    status: {
                      in: formValues?.status,
                    },
                  }
                : {}),
              ...(formValues?.searchTerm
                ? {
                    name: {
                      contains: formValues?.searchTerm || null,
                    },
                  }
                : {}),
            },
          },
        };
      }
    );
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.filterFormGroup.valueChanges.pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe(() => this.refresh());
  }

  public delete(id: string): void {
    this.modalService
      .dialog({
        title: _('recipePlugin.confirmDelete'),
        buttons: [
          { type: 'secondary', label: _('common.cancel') },
          { type: 'danger', label: _('common.delete'), returnValue: true },
        ],
      })
      .pipe(
        switchMap((response) =>
          response
            ? this.dataService.mutate<DeleteRecipe.Mutation, DeleteRecipe.Variables>(DELETE_RECIPE, {
                id,
              })
            : EMPTY
        ),
        // Short delay allowing the recipe to be removed from the search index before refreshing.
        delay(500)
      )
      .subscribe(
        () => {
          this.notificationService.success(_('common.notify-delete-success'), {
            entity: _('recipePlugin.recipe.1'),
          });
          this.refresh();
        },
        () => {
          this.notificationService.error(_('common.notify-delete-error'), {
            entity: _('recipePlugin.recipe.1'),
          });
        }
      );
  }
}
