import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const GET_WALLET: DocumentNode = gql`
  query wallet($customerId: ID!) {
    walletByCustomerId(customerId: $customerId) {
      id
      customerId
      payoutTotal
      nonPayoutTotal
    }
  }
`;

export const TOP_UP_WALLET: DocumentNode = gql`
  mutation topUpWallet($input: TopUpWalletInput!) {
    topUpWallet(input: $input) {
      id
      customerId
      payoutTotal
      nonPayoutTotal
    }
  }
`;
