import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { BASE_ADDRESS } from './address.fragment';
import { BASE_ASSET } from './assets.fragment';
import { BASE_CHANNEL } from './channel.fragment';

export const BASE_PRODUCER: DocumentNode = gql`
  ${BASE_CHANNEL}
  fragment BaseProducer on Producer {
    id
    name
    description
    status
    hasDetailsPage
    channels {
      ...BaseChannel
    }
  }
`;

export const EXTENDED_PRODUCER: DocumentNode = gql`
  ${BASE_PRODUCER}
  ${BASE_ASSET}
  ${BASE_ADDRESS}
  fragment ExtendedProducer on Producer {
    ...BaseProducer
    featuredAsset {
      ...BaseAsset
    }
    assets {
      ...BaseAsset
    }
    address {
      ...BaseAddress
    }
  }
`;
