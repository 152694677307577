import { NgModule } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { addActionBarItem, SharedModule } from '@vendure/admin-ui/core';

import { PAGE_URLS } from '@navUrls';
import { Permission } from '@shared/types/generated-ui-types';
import { ProductImporterComponent } from './components/product-importer/product-importer.component';
import { ProductImporterModalComponent } from './components/product-importer-modal/product-importer-modal.component';
import { ProductImporterRoutingModule } from './product-importer.routing';

@NgModule({
  imports: [SharedModule, ProductImporterRoutingModule],
  declarations: [ProductImporterComponent, ProductImporterModalComponent],
  providers: [
    addActionBarItem({
      id: 'product-importer',
      label: _('productImporterPlugin.importProducts'),
      locationId: 'product-list',
      buttonStyle: 'outline',
      routerLink: () => {
        return [`/${PAGE_URLS.productImporter.getJoinedUrl()}`];
      },
      requiresPermission: Permission.AllowProductImport,
    }),
  ],
})
export class ProductImporterModule {}
