import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Dialog } from '@vendure/admin-ui/core';

import { CsvExportSelectionType } from '../../../plugins/export-central/components/csv-export/csv-export.component';

export interface TimeFrame {
  from: string;
  to: string;
  selectedOption?: string;
}

@Component({
  selector: 'app-time-frame-modal-content',
  templateUrl: './time-frame-modal-content.component.html',
})
export class TimeFrameModalContentComponent<T extends CsvExportSelectionType> implements Dialog<TimeFrame> {
  public dialogTitle: string | undefined;
  public dialogInfo: string | undefined;
  public selectionOptions: T[] | undefined;

  public resolveWith: (result?: TimeFrame) => void;

  public timeFrameForm: FormGroup = new FormGroup({
    from: new FormControl('', [(control) => Validators.required(control)]),
    to: new FormControl('', (control) => Validators.required(control)),
    selectedOption: new FormControl(''),
  });

  public export(): void {
    const from: string = (this.timeFrameForm.value as TimeFrame).from;
    const to: string = (this.timeFrameForm.value as TimeFrame).to;
    const selectedOption: string | undefined = (this.timeFrameForm.value as TimeFrame).selectedOption;

    this.resolveWith({ from, to, selectedOption });
  }

  public cancel(): void {
    this.resolveWith();
  }
}
