<div *vdrIfPermissions="[Permission.ReadWallet]" class="pb4">
  <h3>{{ 'walletPlugin.wallet' | translate }}</h3>
  <div class="card mb4">
    <div class="card-header flex space-between align-center">
      <div style="width: 100%">
        {{ 'walletPlugin.currentValue' | translate }}:
        {{ (wallet?.nonPayoutTotal || 0) + (wallet?.payoutTotal || 0) | localeCurrency: currencyCode }} ({{
          'walletPlugin.payoutPossible' | translate
        }}: {{ wallet?.payoutTotal | localeCurrency: currencyCode }}, {{ 'walletPlugin.noPayoutPossible' | translate }}:
        {{ wallet?.nonPayoutTotal | localeCurrency: currencyCode }})
      </div>
      <button class="btn btn-primary" style="flex-shrink: 0" (click)="openDetails()">
        {{ 'common.details' | translate }}
      </button>
    </div>
    <div class="card-block flex space-between container">
      <app-customer-wallet-top-up
        *vdrIfPermissions="[Permission.TopUpWallet]"
        style="width: 100%; margin-right: 20px; border-right: 1px solid var(--clr-card-border-color)"
        [wallet]="wallet"
        [currencyCode]="currencyCode"
        (onSuccess)="setWallet($event)"
      ></app-customer-wallet-top-up>
      <app-customer-wallet-deduct
        *vdrIfPermissions="[Permission.DeductWallet]"
        style="width: 100%"
        [wallet]="wallet"
        [currencyCode]="currencyCode"
        (onSuccess)="setWallet($event)"
      ></app-customer-wallet-deduct>
    </div>
  </div>
</div>
