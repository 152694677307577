import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DataService, ModalService, NotificationService } from '@vendure/admin-ui/core';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DeleteDeliveryArea, DeliveryArea, Permission, UpdateDeliveryArea } from '@shared/types/generated-ui-types';
import { DELETE_DELIVERY_AREA, UPDATE_DELIVERY_AREA } from '../../delivery-area.graphql';

@Component({
  selector: 'app-delivery-area-item',
  styleUrls: ['./delivery-area-item.component.scss'],
  templateUrl: './delivery-area-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryAreaItemComponent {
  @Input()
  public deliveryArea: DeliveryArea;

  @Output()
  public dataHasChanged: EventEmitter<void> = new EventEmitter<void>();

  public formControl: FormControl = new FormControl('', [
    (control) => Validators.required(control),
    Validators.pattern(/^[0-9]{5}$/),
  ]);

  public readonly updatePermissions: Permission[] = [Permission.UpdateChannel];

  constructor(
    private readonly dataService: DataService,
    private readonly modalService: ModalService,
    private readonly notificationService: NotificationService
  ) {}

  public addPostalCode(): void {
    if (this.formControl.invalid) {
      return;
    }

    const updatedPostalCodes: string[] = [String(this.formControl.value)];

    if (this.deliveryArea.postalCodes) {
      updatedPostalCodes.push(...this.deliveryArea.postalCodes);
    }

    this.dataService
      .mutate<UpdateDeliveryArea.Mutation, UpdateDeliveryArea.Variables>(UPDATE_DELIVERY_AREA, {
        input: {
          id: this.deliveryArea.id,
          postalCodes: updatedPostalCodes,
        },
      })
      .subscribe(
        () => {
          this.notificationService.success(_('common.notify-update-success'), {
            entity: _('deliveryAreaPlugin.deliveryArea.1'),
          });
          this.formControl.patchValue('');
          this.formControl.markAsUntouched();

          this.dataHasChanged.emit();
        },
        () => {
          this.notificationService.error(_('common.notify-update-error'), {
            entity: _('deliveryAreaPlugin.deliveryArea.1'),
          });
        }
      );
  }

  public removePostalCode(code: string): void {
    const updatedPostalCodes: string[] | undefined = this.deliveryArea.postalCodes?.filter(
      (postalCode) => postalCode !== code
    );
    this.dataService
      .mutate<UpdateDeliveryArea.Mutation, UpdateDeliveryArea.Variables>(UPDATE_DELIVERY_AREA, {
        input: {
          id: this.deliveryArea.id,
          postalCodes: updatedPostalCodes,
        },
      })
      .subscribe(
        () => {
          this.notificationService.success(_('common.notify-update-success'), {
            entity: _('deliveryAreaPlugin.deliveryArea.1'),
          });
          this.formControl.patchValue('');
          this.formControl.markAsUntouched();

          this.dataHasChanged.emit();
        },
        () => {
          this.notificationService.error(_('common.notify-update-error'), {
            entity: _('deliveryAreaPlugin.deliveryArea.1'),
          });
        }
      );
  }

  public deleteDeliveryArea(): void {
    this.modalService
      .dialog({
        title: _('deliveryAreaPlugin.deliveryArea.confirmDelete'),
        buttons: [
          { type: 'secondary', label: _('common.cancel') },
          { type: 'danger', label: _('common.delete'), returnValue: true },
        ],
      })
      .pipe(
        switchMap((response) =>
          response
            ? this.dataService.mutate<DeleteDeliveryArea.Mutation, DeleteDeliveryArea.Variables>(DELETE_DELIVERY_AREA, {
                id: this.deliveryArea.id,
              })
            : EMPTY
        )
      )
      .subscribe(
        () => {
          this.notificationService.success(_('common.notify-delete-success'), {
            entity: _('deliveryAreaPlugin.deliveryArea.1'),
          });
          this.dataHasChanged.emit();
        },
        () => {
          this.notificationService.error(_('common.notify-delete-error'), {
            entity: _('deliveryAreaPlugin.deliveryArea.1'),
          });
        }
      );
  }
}
